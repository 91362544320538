import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

import Card from '../../../card'
import TotalPerformanceStats from './partials/stats'
import TotalPerformanceChart from './partials/chart'

const TotalPerformance = ({ stat, user }) => {
  const [view, setView] = useState('daily')
  const [data, setData] = useState({
    daily: [],
    monthly: [],
    yearly: [],
    totalReturn: 0,
    totalTrades: 0,
    profitFactor: 0,
    avgReturnPerTrade: 0,
  })

  useEffect(() => {
    if (!stat.isLoading && stat.dashboard && stat.dashboard.stats) {
      const state = stat.dashboard
      setData({
        daily: state.dailyReturn || [],
        monthly: state.monthlyReturn || [],
        yearly: state.yearlyReturn || [],
        totalReturn: state.stats.totalReturn,
        totalTrades: state.marketsStat.totalTrades,
        profitFactor: state.stats.profitFactor,
        avgReturnPerTrade: state.stats.avgReturnPerTrade,
      })
    }
  }, [stat])

  return (
    <Card wrapperClass="p-0" bodyClass="p-0">
      <Spin spinning={stat.isLoading}>
        <div className="mx-0 row align-items-md-center">
          <div className="col-12 col-md-8 order-2 order-md-1">
            <TotalPerformanceChart data={data} view={view} />
          </div>
          <div className="col-12 col-md-4 order-1 order-md-2">
            <TotalPerformanceStats
              data={data}
              user={user}
              view={view}
              setView={setView}
            />
          </div>
        </div>
      </Spin>
    </Card>
  )
}

export default TotalPerformance
