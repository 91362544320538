import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 } from 'uuid'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../../redux/trade/actions'
import { Breadcrumb, Button, Card, message, notification, PageHeader } from 'antd'
import moment from 'moment'
import ym from 'react-yandex-metrika'
import { SaveOutlined } from '@ant-design/icons'
import axios from 'axios'

import { history } from '../../../index'
import MainCard from '../../../components/ui-2/trades/form/MainCard'
import AdvancedCard from '../../../components/ui-2/trades/form/AdvancedCard'
import DefaultLoader from '../../../components/system/Loaders/default'
import TradeExecutions from '../../../components/ui-2/trades/form/TradeExecutions'
import { API_URL } from '../../../config/app'
import axiosError from '../../../helpers/axios-error'

const ManualTradesForm = () => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
  }
  const { slug } = useParams()
  const { data: tradeRequiredData, isListLoading } = useSelector((state) => state.trade)
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pageTitle, setPageTitle] = useState('Loading...')
  const [trade, setTrade] = useState({
    regEx: /^-?\d*\.?(?:\d{1,6})?$/,
    data: {
      market: 0,
    },
    executions: [],
    images: [],
  })

  useEffect(() => {
    dispatch({
      type: actions.GET_DATA,
    })
    if (slug === 'create') {
      setPageLoading(false)
      setPageTitle('New Trade')
      setTrade({
        ...trade,
        executions: [
          {
            key: v4(),
            action: 1,
            execution_date: moment(),
            qty: 0,
            execution_price: null,
            execution_fees: 0,
            profit_loss: 0,
          },
        ],
      })
    } else {
      setPageLoading(true)
      axios
        .get(`${API_URL}/api/v1/trades/single/${slug}`, config)
        .then((res) => {
          const item = res.data.trade
          const newTradeData = {
            data: {
              market: item.market,
              account: item.get_account.id,
              trading_system: item.get_trading_system.id,
              instrument: item.get_instrument.name,
              timeframe: item.get_time_frame.id,
              take_profit: item.take_profit,
              stop_loss: item.stop_loss,
              highest_price: item.highest_price,
              lowest_price: item.lowest_price,
              description: item.description,
            },
            executions: _.map(item.get_executions, (exec) => {
              return {
                key: `execution_${exec.id}_trade_${item.id}`,
                action: exec.action,
                execution_date: moment(exec.execution_date),
                qty: parseFloat(exec.qty),
                execution_price: parseFloat(exec.execution_price),
                execution_fees: parseFloat(exec.execution_fees),
                profit_loss: parseFloat(exec.profit_loss),
              }
            }),
            images: _.map(item.get_images, (image) => {
              return image.path
            }),
          }
          setPageTitle(`Update Trade (${item.get_instrument.name})`)
          setTrade(newTradeData)
          setPageLoading(false)
        })
        .catch((e) => {
          axiosError(e)
        })
    }
  }, [slug])

  useEffect(() => {
    console.log(trade)
  }, [trade])

  const updateTradeData = (field, value) => {
    const newTradeData = _.set(trade, field, value)
    setTrade({ ...newTradeData })
  }

  const addExecution = () => {
    setTrade({
      ...trade,
      executions: [
        ...trade.executions,
        {
          key: v4(),
          action: 0,
          execution_date: moment(),
          qty: 0,
          execution_price: null,
          execution_fees: 0,
          profit_loss: 0,
        },
      ],
    })
  }

  const deleteExecution = (key) => {
    const execs = []
    trade.executions.forEach((exec) => {
      if (exec.key !== key) {
        execs.push(exec)
      }
    })
    setTrade({ ...trade, executions: execs })
  }

  const handleSubmit = () => {
    setLoading(true)
    const data = trade
    data.executions.forEach((exec, index) => {
      data.executions[index].execution_date = moment(exec.execution_date).format(
        'YYYY-MM-DD HH:mm',
      )
    })
    let url = `${API_URL}/api/v2/trades`
    let method = 'post'
    if (slug !== 'create') {
      url = `${API_URL}/api/v2/trades/${slug}`
      method = 'patch'
    }
    axios({ method, url, data, headers: config.headers })
      .then((res) => {
        let text = 'Trade successfully updated'
        if (slug === 'create') {
          ym('reachGoal', 'userCreateTrade')
          text = 'Trade successfully created'
        }
        notification.success({
          message: 'Success',
          description: text,
        })
        history.push('/trades')
      })
      .catch((e) => {
        axiosError(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <Helmet title={pageTitle} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/trades">Trades</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={pageTitle}
          backIcon={false}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => handleSubmit()}
              loading={loading}
              size="large"
            >
              <SaveOutlined /> Save Trade
            </Button>,
          ]}
        />
      </div>
      {pageLoading || isListLoading ? (
        <div className="animate__animated animate__faster animate__slideInUp">
          <DefaultLoader />
        </div>
      ) : (
        <div className="animate__animated animate__faster animate__slideInUp">
          <Card className="tr-trade-form mb-4">
            <MainCard
              trade={trade}
              updateData={updateTradeData}
              data={tradeRequiredData}
            />
          </Card>
          <Card className="tr-trade-form mb-4">
            <TradeExecutions
              trade={trade}
              updateData={updateTradeData}
              addExecution={addExecution}
              deleteExecution={deleteExecution}
            />
          </Card>
          <Card className="tr-trade-form mb-4">
            <AdvancedCard trade={trade} updateData={updateTradeData} />
          </Card>
        </div>
      )}
    </>
  )
}

export default ManualTradesForm
