import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { ColorType, createChart } from 'lightweight-charts'

import colors from '../../../../../config/apexcharts/colors'

import styles from './general-performance.module.scss'

const options = [
  { name: 'Cumulative P/L, USD', val: 'totalReturnUsd' },
  { name: 'Cumulative P/L, %', val: 'totalReturnPercents' },
  { name: 'Daily P/L, USD', val: 'dailyReturnUsd' },
  { name: 'Daily P/L, %', val: 'dailyReturnPercents' },
]

const GeneralPerformanceChart = ({ data, user }) => {
  const chartContainerRef = useRef()
  const [chartType, setChartType] = useState('totalReturnUsd')

  useEffect(() => {
    let chart = null
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth })
    }
    const myPriceFormatter = (p) => p.toFixed(2)
    const chartData = []
    if (data && data.dailyChart && chartContainerRef && chartContainerRef.current) {
      _.forEach(data.dailyChart, (i, index) => {
        const item = {
          time: index,
          value: 0,
        }
        if (chartType === 'totalReturnUsd') {
          item.value = i.result.totalReturnMoney
        }
        if (chartType === 'totalReturnPercents') {
          item.value = i.result.totalReturnPercents
        }
        if (chartType === 'dailyReturnUsd') {
          item.value = i.result.dailyReturnMoney
        }
        if (chartType === 'dailyReturnPercents') {
          item.value = i.result.dailyReturnPercents
        }
        chartData.push(item)
      })
      chart = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: colors.sidebarBg },
          textColor: colors.fontColor,
        },
        grid: {
          vertLines: { color: colors.borderColor },
          horzLines: { color: colors.borderColor },
        },
        width: chartContainerRef.current.clientWidth,
        height:
          chartContainerRef.current.clientWidth <= 740
            ? 350
            : parseInt((5 * chartContainerRef.current.clientWidth) / 16, 10).toString(),
        timeScale: {
          timeVisible: true,
        },
        handleScale: false,
        handleScroll: false,
        localization: {
          priceFormatter: myPriceFormatter,
        },
      })
      chart.timeScale().fitContent()

      // Setting the border color for the vertical axis
      chart.priceScale('right').applyOptions({
        borderColor: colors.borderColor,
      })

      // Setting the border color for the horizontal axis
      chart.timeScale().applyOptions({
        borderColor: colors.borderColor,
      })
      const areaChart = chart.addBaselineSeries({
        baseValue: { type: 'price', price: 0 },
        topLineColor: colors.successColor,
        topFillColor1: colors.successColor,
        topFillColor2: colors.successColorLight,
        bottomLineColor: colors.dangerColor,
        bottomFillColor1: colors.dangerColorLight,
        bottomFillColor2: colors.dangerColor,
        lineWidth: 2,
      })
      areaChart.setData(chartData)

      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (chart) {
        window.removeEventListener('resize', handleResize)

        chart.remove()
      }
    }
  }, [chartType])

  return (
    <div className={styles.generalPerformance}>
      <div className={styles.controls}>
        <ul>
          {options.map((v) => (
            <li key={v.val}>
              <a
                href="/"
                data-active={chartType === v.val ? 'yes' : 'no'}
                onClick={(e) => {
                  e.preventDefault()
                  setChartType(v.val)
                }}
              >
                {v.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div ref={chartContainerRef} />
    </div>
  )
}

export default GeneralPerformanceChart
