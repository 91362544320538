import React from 'react'
import { Drawer, Button, Form, Input, message } from 'antd'

import actions from '../../../../redux/account/actions'

const EditAccountDrawer = ({ account, drawerVisible, onDrawerClose, dispatch }) => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    // Message
    message.loading({ content: 'Please, wait...', key: 'update_account' })
    // Close rawer
    onDrawerClose()
    // Dispatch
    dispatch({
      type: actions.UPDATE,
      payload: { id: account.id, name: values.name },
    })
  }

  const onFinishFailed = () => {
    message.error('Error')
  }

  return (
    <Drawer
      title="Update account"
      onClose={onDrawerClose}
      destroyOnClose
      visible={drawerVisible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onDrawerClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" form="addNewAccount" htmlType="submit">
            Update
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="mb-4"
        id="addNewAccount"
      >
        <Form.Item
          name="name"
          label="Account name"
          initialValue={account.name}
          hasFeedback
          rules={[{ required: true, message: 'Please input new account name' }]}
        >
          <Input size="large" placeholder="New account name" />
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default EditAccountDrawer
