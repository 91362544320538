import React, { useState } from 'react'
import { Card, Button, Popconfirm, message } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import actions from '../../../redux/advanced-stats/actions'

import CreateAdvancedStatValueModal from './forms/CreateAdvancedStatValueModal'
import EditAdvancedStatValueModal from './forms/EditAdvancedStatValueModal'

const AdvancedStatsValuesList = ({ dispatch, statId, values }) => {
  const [createStatValueModalVisibility, handleCreateStatModalVisibility] = useState(false)
  const [editStatValueModalVisibility, handleEditStatModalVisibility] = useState(false)
  const [valueToEdit, handleValueToEdit] = useState({})

  const handleCreateModalVisibility = (condition) => {
    handleCreateStatModalVisibility(condition)
  }

  const handleEditModalVisibility = (condition) => {
    handleEditStatModalVisibility(condition)
  }

  const deleteStatValueConfirm = (valueId) => {
    message.loading({ content: 'Please, wait...', key: 'delete_value' })
    dispatch({
      type: actions.VALUE_DELETE,
      payload: {
        statId,
        valueId,
      },
    })
  }

  const selectValueToEdit = (value) => {
    handleValueToEdit(value)
    handleEditModalVisibility(true)
  }

  if (!values) {
    return (
      <div className="col-12 col-md-9">
        <Card>
          <p className="mb-0">Please, select advanced stat from left menu</p>
        </Card>
      </div>
    )
  }
  return (
    <>
      <div className="col-12 col-md-9 mb-5">
        <div className="advanced-stat__values">
          <Card
            extra={
              <Button type="primary" onClick={() => handleCreateStatModalVisibility(true)}>
                <PlusOutlined /> Add new value
              </Button>
            }
          >
            <ul className="advanced-stat__values-list">
              {values.map((item) => {
                return (
                  <li key={item.id} className="advanced-stat__values-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="advanced-stat__item_name">{item.value}</div>
                      <div className="advanced-stat__item_controls">
                        <Button
                          type="link"
                          className="success-color mr-2 border-0"
                          onClick={() => selectValueToEdit(item)}
                        >
                          <EditOutlined />
                        </Button>
                        <Popconfirm
                          title="Are you really want to delete this value? All related items will be deleted!"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          okType="danger"
                          okText="Delete"
                          onConfirm={() => deleteStatValueConfirm(item.id)}
                          placement="topRight"
                        >
                          <Button type="link" className="danger-color border-0">
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Card>
        </div>
      </div>
      <CreateAdvancedStatValueModal
        dispatch={dispatch}
        statId={statId}
        createStatValueModalVisibility={createStatValueModalVisibility}
        handleModal={handleCreateModalVisibility}
      />
      <EditAdvancedStatValueModal
        dispatch={dispatch}
        statId={statId}
        value={valueToEdit}
        editStatValueModalVisibility={editStatValueModalVisibility}
        handleEditModal={handleEditModalVisibility}
      />
    </>
  )
}

export default AdvancedStatsValuesList
