import React, { useState } from 'react'
import { Select } from 'antd'

import MaeVsTradeOutcome from './MaeVsTradeOutcome'
import MfeVsTradeOutcome from './MfeVsTradeOutcome'
import MaeVsMfeVsWinVsLoss from './MaeVsMfeVsWinVsLoss'
import UnlockAllFeatures from '../../../system/Subscription/UnlockAllFeatures'

// eslint-disable-next-line no-unused-vars
const MaeMfeReportComponent = ({ data, user }) => {
  const [type, handleType] = useState('profit_loss_percents')

  return (
    <div className="row">
      <div className="col-12 col-lg-9">
        <MaeVsTradeOutcome type={type} data={data} user={user} />
        <MfeVsTradeOutcome type={type} data={data} user={user} />
        {user.is_premium === 1 ? (
          <MaeVsMfeVsWinVsLoss type={type} data={data} user={user} />
        ) : (
          <UnlockAllFeatures title="MAE vs MFE - Winners vs Losers widget" compact />
        )}
      </div>
      <div className="col-12 col-lg-3">
        <p>
          <strong>Maximum Adverse Excursion (MAE)</strong> marks the lowest price during a
          long trade and the highest price during a short trade. This helps you to
          identify what the maximum loss was during a trade. This is also known as maximum
          drawdown of the position.
        </p>
        <p>
          <strong>Maximum Favorable Excursion (MFE)</strong> marks the highest price
          during a long trade and the lowest price during a short trade. This shows you
          what the highest profit was during a trade.
        </p>
        <hr />
        <div className="mt-0">
          <p className="mb-1">
            <small>
              <strong>View Type:</strong>
            </small>
          </p>
          <Select
            defaultValue={type}
            onChange={(value) => handleType(value)}
            className="w-100"
          >
            <Select.Option value="profit_loss">
              Show in {user.get_currency.short_name}
            </Select.Option>
            <Select.Option value="profit_loss_percents">Show in %</Select.Option>
          </Select>
        </div>
      </div>
    </div>
  )
}

export default MaeMfeReportComponent
