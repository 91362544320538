/* eslint import/no-cycle:0 */
import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, notification } from 'antd'

import actions from '../../../redux/trade/actions'
import getAPITokenFromLocalStorage from '../../../helpers/getAPITokenFromLocalStorage'
import checkIfUserHaveSubscription from '../../../helpers/premiumUserCheck'

import { API_URL } from '../../../config/app'

import ImportTradesForm from '../../../components/ui/settings/import-form'
import ImportValidationTable from '../../../components/ui/settings/import-form/ValidationResult'
import ImportTradesTips from '../../../components/ui/settings/ImportTradesTips'
import DefaultLoader from '../../../components/system/Loaders/default'
import Card from '../../../components/ui-2/card'
import ym from 'react-yandex-metrika'
import { history } from '../../../index'

const mapStateToProps = ({ trade, user }) => ({ trade, user })

class ImportTradesSettingsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validateBtnLoading: false,
      platform: 'mt4',
      file: '',
      account: '',
      tradingSystem: '',
      unitsType: 'units',
      validator: [],
      timeframe: '',
      market: 'Forex',
      timezone: 'Europe/London',
    }
  }

  componentDidMount() {
    const { dispatch, user } = this.props
    checkIfUserHaveSubscription(user.user)
    dispatch({
      type: actions.GET_DATA,
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  updateState = (target, value) => {
    this.setState({ [target]: value })
    if (target === 'platform' && ['binanceUsdM'].includes(value)) {
      this.setState({ ['market']: 'Crypto' })
    }
  }

  submitForm = (event) => {
    event.preventDefault()
    // console.log(this.state)
    const {
      platform,
      file,
      account,
      tradingSystem,
      unitsType,
      market,
      timeframe,
      timezone,
    } = this.state
    if (
      platform === '' ||
      file === '' ||
      account === '' ||
      tradingSystem === '' ||
      market === '' ||
      timeframe === ''
    ) {
      notification.warning({
        description: 'Some fields are missed. Check the form and try again.',
        message: 'Missed fields',
      })
    } else {
      this.setState({ validateBtnLoading: true })
      const formData = new FormData()
      formData.append('platform', platform)
      formData.append('file', file)
      formData.append('account', account)
      formData.append('tradingSystem', tradingSystem)
      formData.append('market', market)
      formData.append('timeframe', timeframe)
      formData.append('unitsType', unitsType)
      formData.append('timezone', timezone)

      if (['mt4', 'mt5', 'ctrader'].includes(platform)) {
        axios
          .post(
            `${API_URL}/api/v1/import-trades/validate`,
            formData,
            getAPITokenFromLocalStorage(true),
          )
          .then((res) => {
            // console.log(res.data)
            this.setState({ validateBtnLoading: false })
            if (!res.data.success) {
              notification.warn({
                description: res.data.msg,
                message: 'Import error',
              })
            }
            if (!res.data.isFatalError) {
              this.setState({ validator: res.data })
            }
          })
          .catch(() => {
            notification.error({
              description:
                'Something goes wrong. Please reload the page and try again later.',
              message: 'Import failed',
            })
            this.setState({ validateBtnLoading: false })
          })
      } else {
        axios
          .post(
            `${API_URL}/api/v2/import-trades`,
            formData,
            getAPITokenFromLocalStorage(true),
          )
          .then((res) => {
            // console.log(res.data)
            this.setState({ validateBtnLoading: false })
            notification.success({
              description:
                'It usually takes a couple of minutes to process a file, however if you are uploading large files it can take over 30 minutes. You can close this page, once the file is processed, the trades will appear on the Trades page.',
              message: 'Trades Import Started',
            })
            ym('reachGoal', 'userImportTrades')
            ym('reachGoal', 'binanceUsdMImport')
            history.push('/settings/my-imports')
          })
          .catch(() => {
            notification.error({
              description:
                'Something goes wrong. Please reload the page and try again later.',
              message: 'Import failed',
            })
            this.setState({ validateBtnLoading: false })
          })
      }
    }
  }

  render() {
    const pageTitle = 'Import Trades'
    const { trade, user } = this.props
    const { validateBtnLoading, validator, platform } = this.state

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          <div className="row mt-3 mb-5">
            {validator.trades && (
              <ImportValidationTable data={this.state} user={user.user} />
            )}
            <div className="col-12 col-md-6">
              <Card>
                {trade.isListLoading ? (
                  <DefaultLoader />
                ) : (
                  <ImportTradesForm
                    data={trade.data.accounts ? trade.data : false}
                    validateBtnLoading={validateBtnLoading}
                    updateState={this.updateState}
                    submitForm={this.submitForm}
                    platform={platform}
                  />
                )}
              </Card>
            </div>
            <div className="col-12 col-md-6">
              <ImportTradesTips platform={platform} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(ImportTradesSettingsPage))
