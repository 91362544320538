import React, { useState } from 'react'
import { Modal, message } from 'antd'

import actions from '../../../../redux/advanced-stats/actions'

const EditAdvancedStatValueModal = ({ dispatch, statId, value, editStatValueModalVisibility, handleEditModal }) => {
  const [statValueName, handleStatValueName] = useState('')

  const handleUpdateStat = () => {
    let newStatValueName = statValueName
    if (newStatValueName.length === 0) {
      newStatValueName = value.value
    }
    // Dispatch
    dispatch({
      type: actions.VALUE_UPDATE,
      payload: {
        statId,
        valueId: value.id,
        value: newStatValueName,
      },
    })
    // Show loader
    message.loading({ content: 'Please, wait...', key: 'update_stat_value' })
    // Hide modal
    handleEditModal(false)
  }

  const handleStatValueNameInput = (event) => {
    handleStatValueName(event.target.value)
  }

  const disableFormSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <Modal
      title="Edit advanced stat value"
      visible={editStatValueModalVisibility}
      onOk={handleUpdateStat}
      okText="Save"
      onCancel={() => handleEditModal(false)}
      destroyOnClose
    >
      <div className="row">
        <div className="col-12">
          <form onSubmit={disableFormSubmit}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="advancedStatValueName">Stat value name</label>
            <input
              type="text"
              name="advancedStatValueName"
              className="ant-input"
              required
              placeholder="Input new advanced stat value name here"
              onChange={handleStatValueNameInput}
              defaultValue={value.value}
            />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default EditAdvancedStatValueModal
