/* eslint jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { Select } from 'antd'
import { v4 } from 'uuid'

const AdvancedStats = ({ state, updateState = (f) => f }) => {
  const handleAdvancedStatsChange = (index, value) => {
    const newAdvancedStatsArr = state.advancedStats
    newAdvancedStatsArr[index].selected = value
    updateState('advancedStats', newAdvancedStatsArr)
  }
  return (
    <div className="row">
      {state.advancedStats[0] &&
        state.advancedStats.map((item, i) => {
          return (
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3" key={v4()}>
              <label htmlFor={`as_${item.id}`}>{item.name}</label>
              <Select
                className="w-100"
                id={`as_${item.id}`}
                key={item.id}
                value={item.selected && item.selected}
                onChange={(value) => handleAdvancedStatsChange(i, value)}
              >
                {item.values[0] &&
                  item.values.map((value) => {
                    return (
                      <Select.Option value={value.id} key={value.id}>
                        {value.value}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
          )
        })}
    </div>
  )
}

export default AdvancedStats
