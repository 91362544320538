import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import NotEnoughData from '../../components/ui/messages/NotEnoughData'
import BalancesWidget from '../../components/ui-2/widgets/dashboard/balances'
import TotalReturn from '../../components/ui-2/widgets/dashboard/total-return'
import TotalPerformance from '../../components/ui-2/widgets/dashboard/total-performance'
import InstrumentsWidget from '../../components/ui-2/widgets/dashboard/instruments'
import MarketsWidget from '../../components/ui-2/widgets/dashboard/markets-widget'
import PeriodsResultsWidget from '../../components/ui-2/widgets/dashboard/periods-results'
import StatsWithProgressWidget from '../../components/ui-2/widgets/dashboard/stats-with-progress'
import GeneralStats from '../../components/ui-2/widgets/dashboard/general-stats'
import FullwidthSubscribeWidget from '../../components/ui-2/widgets/subscriptions/subscribe-ads/fullwidth'

import actions from '../../redux/stat/actions'
import createUrlParamsStringFromFilters from '../../helpers/createUrlParamsFromFilters'

const mapStateToProps = ({ stat, user, filter, trade }) => ({ stat, user, filter, trade })

const Dashboard = ({ stat, user, filter, trade }) => {
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (stat.dashboard) {
  //     console.log(stat.dashboard)
  //   }
  // }, [stat])

  useEffect(() => {
    dispatch({
      type: actions.DASHBOARD,
      payload: {
        query: createUrlParamsStringFromFilters(filter),
      },
    })
    // eslint-disable-next-line
  }, [filter])

  if (
    (stat &&
      stat.dashboard &&
      stat.dashboard.error &&
      stat.dashboard.error === 'no-content') ||
    stat.dashboard.success === false
  ) {
    return <NotEnoughData />
  }

  return (
    <>
      <Helmet title="Dashboard" />
      <div className="animate__animated animate__fadeIn mb-4">
        <div className="row">
          {user.user.is_premium !== 1 && (
            <div className="col-12 order-1">
              <FullwidthSubscribeWidget />
            </div>
          )}
          <div className="col-12 order-2">
            <div className="match-height row">
              <div className="col-12 col-md-6 col-xl-4">
                <TotalReturn stat={stat} user={user.user} />
              </div>
              <div className="col-12 col-md-6 col-xl-8">
                <BalancesWidget stat={stat} user={user.user} />
              </div>
            </div>
          </div>
          <div className="col-12 order-4 order-xl-3">
            <div className="match-height row">
              <div className="col-12 col-xl-8">
                <TotalPerformance stat={stat} user={user.user} />
              </div>
              <div className="col-12 col-md col-xl-4">
                <InstrumentsWidget stat={stat} user={user.user} />
              </div>
              <div className="d-flex d-xl-none col-12 col-12 col-md-6 col-xl-4 order-2 order-xl-1">
                <MarketsWidget stat={stat} />
              </div>
            </div>
          </div>
          <div className="col-12 order-3 order-xl-4">
            <div className="match-height row">
              <div className="d-none d-xl-flex col-12 col-xl-4 order-2 order-xl-1">
                <MarketsWidget stat={stat} />
              </div>
              <div className="col-12 col-xl-8 order-1 order-xl-2">
                <PeriodsResultsWidget user={user.user} filter={filter} />
              </div>
            </div>
          </div>
          {stat && !stat.isLoading && stat.dashboard && stat.dashboard.stats ? (
            <div className="col-12 order-5">
              <div className="animate__animated animate__fadeIn">
                <GeneralStats stat={stat.dashboard} user={user.user} />
              </div>
            </div>
          ) : null}
          <div className="col-12 order-6">
            <div className="match-height row">
              <div className="col-12 col-md-6 col-xl-4">
                <StatsWithProgressWidget
                  title="Hourly Results"
                  user={user.user}
                  data={
                    stat && stat.dashboard && stat.dashboard.hourlyStat
                      ? stat.dashboard.hourlyStat
                      : {}
                  }
                  isLoading={stat.isLoading}
                />
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <StatsWithProgressWidget
                  title="Weekday Results"
                  user={user.user}
                  data={
                    stat && stat.dashboard && stat.dashboard.weeklyStat
                      ? stat.dashboard.weeklyStat
                      : {}
                  }
                  isLoading={stat.isLoading}
                />
              </div>
              <div className="col-12 col-md-12 col-xl-4">
                <StatsWithProgressWidget
                  title="Monthly Results"
                  user={user.user}
                  data={
                    stat && stat.dashboard && stat.dashboard.monthlyStat
                      ? stat.dashboard.monthlyStat
                      : {}
                  }
                  isLoading={stat.isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(Dashboard))
