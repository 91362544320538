import React, { useState } from 'react'
import ym from 'react-yandex-metrika'
import axios from 'axios'
import { v4 } from 'uuid'
import { Button, notification } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'

// eslint-disable-next-line import/no-cycle
import { history } from '../../../../index'

import { API_URL } from '../../../../config/app'

const ImportValidationTable = ({ data, user }) => {
  const [loading, handleLoading] = useState(false)
  // console.log(user)
  const importTrades = () => {
    if (data.validator.success) {
      handleLoading(true)
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
      }
      const formData = new FormData()
      formData.append('platform', data.platform)
      formData.append('account', data.account)
      formData.append('tradingSystem', data.tradingSystem)
      formData.append('market', data.market)
      formData.append('timeframe', data.timeframe)
      formData.append('unitsType', data.unitsType)
      formData.append('trades', JSON.stringify(data.validator.trades))
      axios
        .post(`${API_URL}/api/v1/import-trades/save`, formData, config)
        .then((res) => {
          // console.log(res.data)
          if (res.data.success) {
            notification.success({
              description: `${res.data.total} trades was successfully imported`,
              message: 'Success',
            })
            ym('reachGoal', 'userImportTrades')
            history.push('/settings/my-imports')
          }
          handleLoading(false)
        })
        .catch(() => {
          notification.error({
            description: 'Something goes wrong. Please reload the page and try again later.',
            message: 'Import failed',
          })
          handleLoading(false)
        })
    }
  }

  return (
    <div className="col-12 mb-4">
      <div className="card card__stat">
        <div className="card-header">
          <h5 className="card-title">
            Trades{' '}
            <small>
              (Total: {data.validator.trades.length} Errors: {data.validator.errors})
            </small>
          </h5>
        </div>
        <div className="card-content">
          <div className="card-body pos-relative">
            <PerfectScrollbar className="select__ps select__ps--height">
              <div className="limit-height-500">
                <table className="table tr-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Status</th>
                      <th>Instrument</th>
                      <th>Option</th>
                      <th>Open Date</th>
                      <th>Exit Date</th>
                      <th>Volume</th>
                      <th>Open Price</th>
                      <th>Take Profit</th>
                      <th>Stop Loss</th>
                      <th>Exit Price</th>
                      <th>Profit Loss</th>
                      <th>Fees</th>
                      <th>Market</th>
                      <th>Time Frame</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.validator.trades.length > 0 &&
                      data.validator.trades.map((item, index) => {
                        return (
                          <tr key={v4()}>
                            <td>{index + 1}</td>
                            <td>
                              {item.isTradeCorrect ? (
                                <span className="success-color">Valid</span>
                              ) : (
                                <span className="danger-color">Error</span>
                              )}
                            </td>
                            <td>{item.instrument}</td>
                            <td>{item.option}</td>
                            <td>{item.open_date}</td>
                            <td>{item.exit_date}</td>
                            <td>{item.qty}</td>
                            <td>{item.open_price}</td>
                            <td>{item.take_profit}</td>
                            <td>{item.stop_loss}</td>
                            <td>{item.exit_price}</td>
                            <td>
                              {item.profit_loss} {user.get_currency && user.get_currency.short_name}
                            </td>
                            <td>{item.fees}</td>
                            <td>{item.market.name}</td>
                            <td>{item.timeframe.name}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </PerfectScrollbar>
            {data.validator.success && (
              <div className="mt-4">
                <Button type="primary" onClick={() => importTrades()} loading={loading}>
                  Import trades
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportValidationTable
