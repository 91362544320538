import React from 'react'
import { Bar } from 'react-chartjs-2'

import colors from '../../../../config/apexcharts/colors'
import longNumberToShort from '../../../../helpers/longNumberToShort'

const MixedBarChart = ({ data, user, heightClass = 'm-h-300' }) => {
  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              return `${longNumberToShort(value, '0.0a')} ${user.get_currency.short_name}`
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const chartData = {
    labels: [],
    datasets: [
      {
        label: 'Result',
        type: 'line',
        data: [],
        fill: false,
        borderWidth: 2,
        borderColor: colors.warningColor,
        backgroundColor: colors.warningColor,
        pointBorderColor: colors.warningColor,
        pointBackgroundColor: colors.warningColor,
        pointHoverBackgroundColor: colors.warningColor,
        pointHoverBorderColor: colors.warningColor,
      },
      {
        type: 'bar',
        label: 'Win',
        data: [],
        fill: false,
        backgroundColor: colors.successColor,
        borderColor: colors.successColor,
        maxBarThickness: 45,
      },
      {
        type: 'bar',
        label: 'Loss',
        data: [],
        fill: false,
        backgroundColor: colors.dangerColor,
        borderColor: colors.dangerColor,
        maxBarThickness: 45,
      },
    ],
  }

  const renderChart = () => {
    Object.keys(data).forEach((index) => {
      if (data[index].earnMoney !== 0 || data[index].lossMoney !== 0) {
        // Set chart labels
        chartData.labels.push(data[index].name)
        // Add win/loss values
        chartData.datasets[1].data.push(data[index].earnMoney)
        chartData.datasets[2].data.push(data[index].lossMoney)
        // Add result values
        chartData.datasets[0].data.push(parseFloat(data[index].earnMoney + data[index].lossMoney).toFixed(2))
      }
    })
    return <Bar data={chartData} options={options} />
  }

  return (
    <div className="card card__stat m-b-30">
      <div className="card-content card-content__no-title">
        <div className={`card-body ${heightClass}`}>{renderChart()}</div>
      </div>
    </div>
  )
}

export default MixedBarChart
