import actions from './actions'

const initialState = {
  stats: [],
  stat: {},
  value: {},
  isListLoading: true,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET:
      return initialState
    default:
      return state
  }
}
