import React, { useState } from 'react'
import { Popconfirm, Button, message } from 'antd'
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import actions from '../../../redux/instrument/actions'

import EditInstrumentModal from './modals/EditInstrumentModal'

// eslint-disable-next-line no-unused-vars
const InstrumentsListItem = ({ dispatch, instrument }) => {
  const [modalVisible, handleModalVisibility] = useState(false)

  const deleteInstrument = () => {
    message.loading({ content: 'Please, wait...', key: 'delete_instrument' })
    dispatch({
      type: actions.DELETE,
      payload: {
        id: instrument.id,
      },
    })
  }

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
      <div className="jira-card">
        <div className="jira-card__body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="jira-card__title">{instrument.name}</div>
              <p className="mb-0 muted-color fw-300">
                <small>Trades: {instrument.get_trades_count}</small>
              </p>
            </div>
            <div>
              <Button type="link" className="btn-link success-color mr-2" onClick={() => handleModalVisibility(true)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="This action will remove all trades. Are you sure?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                placement="bottomRight"
                okText="Yes, delete"
                cancelText="Cancel"
                okType="danger"
                onConfirm={deleteInstrument}
              >
                <Button type="link" className="btn-link danger-color">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
      <EditInstrumentModal
        dispatch={dispatch}
        instrument={instrument}
        modalVisible={modalVisible}
        handleModalVisibility={handleModalVisibility}
      />
    </div>
  )
}

export default InstrumentsListItem
