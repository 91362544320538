import React from 'react'
import ReactDOM from 'react-dom'
import ym from 'react-yandex-metrika'
// import { hotjar } from 'react-hotjar'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import createRootReducer from './redux/reducers'
// eslint-disable-next-line import/no-cycle
import sagas from './redux/sagas'
// eslint-disable-next-line import/no-cycle
import Router from './router'

import { ThemeProvider } from './context/ThemeProvider'

import * as serviceWorker from './serviceWorker'

import './scss/App.scss'
import './styles/App.scss'

// Middleware
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}
const store = createStore(
  createRootReducer(history),
  compose(applyMiddleware(...middlewares)),
)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <ThemeProvider>
    <Provider store={store}>
      <Router history={history} />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
// Initialize HotJar
// hotjar.initialize(3291451, 6)
// Yandex Metrika history listener
history.listen((location) => {
  ym('hit', location.pathname)
  // if (hotjar.initialized()) {
  //   hotjar.stateChange(location.pathname)
  // }
})
// Export state and history
export { store, history }
