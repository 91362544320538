const actions = {
  SET_STATE: 'trading-system/SET_STATE',
  LIST: 'trading-system/LIST',
  SINGLE: 'trading-system/SINGLE',
  SAVE: 'trading-system/SAVE',
  UPDATE: 'trading-system/UPDATE',
  DELETE: 'trading-system/DELETE',

  RESET: 'trading-system/RESET',
}

export default actions
