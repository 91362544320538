import React from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import SetNewPasswordOnReset from '../../../components/system/Auth/new-password'

class NewPasswordAfterReset extends React.Component {
  render() {
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    const resetToken = query.get('token')
    return (
      <>
        <Helmet title="Password Reset" />
        <SetNewPasswordOnReset resetToken={resetToken} />
      </>
    )
  }
}

export default withRouter(NewPasswordAfterReset)
