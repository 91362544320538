import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Calendar as AntdCalendar, message } from 'antd'

import Card from '../../ui-2/card'

import { API_URL } from '../../../config/app'
import getAPITokenFromLocalStorage from '../../../helpers/getAPITokenFromLocalStorage'
// eslint-disable-next-line import/no-cycle
import checkIfUserHaveSubscription from '../../../helpers/premiumUserCheck'
import longNumberToShort from '../../../helpers/longNumberToShort'

const Calendar = ({ user }) => {
  const [calendarData, handleCalendarData] = useState([])

  useEffect(() => {
    if (checkIfUserHaveSubscription(user)) {
      showLoader()
      apiRequest(moment().format('DD-MM-YYYY'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiRequest = (date) => {
    axios
      .get(
        `${API_URL}/api/v1/tools/calendar?date=${date}`,
        getAPITokenFromLocalStorage(true),
      )
      .then((res) => {
        if (res.data && res.data.success) {
          handleCalendarData(res.data.data)
          message.destroy()
        }
      })
      .catch(() => {
        message.error({
          content: 'Something goes wrong. Try again later.',
          key: 'get_calendar_data',
        })
      })
  }

  const showLoader = () => {
    message.loading({ content: 'Loading...', key: 'get_calendar_data', duration: 30 })
  }

  // eslint-disable-next-line no-unused-vars
  const dateCellRender = (value) => {
    const date = moment(value).format('DD-MM-YYYY')
    if (calendarData[date]) {
      return (
        <div className="tr-calendar__cell">
          <ul>
            <li>Opened: {calendarData[date].stats.opened}</li>
            <li>Closed: {calendarData[date].stats.closed}</li>
            <li>
              Return:{' '}
              {`${longNumberToShort(calendarData[date].stats.return.money)} ${
                user.get_currency.short_name
              } (${calendarData[date].stats.return.percents}%)`}
            </li>
            <li>Win rate: {calendarData[date].stats.trades.winRate}%</li>
          </ul>
        </div>
      )
    }
    return null
  }

  const onPanelChange = (value) => {
    showLoader()
    apiRequest(moment(value).format('DD-MM-YYYY'))
  }

  return (
    <Card>
      <AntdCalendar
        className="tradiry-calendar"
        dateCellRender={dateCellRender}
        onPanelChange={onPanelChange}
      />
    </Card>
  )
}

export default Calendar
