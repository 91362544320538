import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, PageHeader } from 'antd'

import DefaultLoader from '../../../components/system/Loaders/default'
import ReportsOverviewLayout from '../../../components/ui-2/reports/overview'

import actions from '../../../redux/stat/actions'
import createUrlParamsStringFromFilters from '../../../helpers/createUrlParamsFromFilters'

const ReportingOverviewPage = () => {
  const dispatch = useDispatch()
  const filter = useSelector((state) => state.filter)
  const stat = useSelector((state) => state.stat)
  const user = useSelector((state) => state.user)
  const { dashboard, reportsOverview, isLoading } = stat

  useEffect(() => {
    dispatch({
      type: actions.REPORTS_OVERVIEW,
      payload: {
        query: createUrlParamsStringFromFilters(filter),
      },
    })
    dispatch({
      type: actions.DASHBOARD,
      payload: {
        query: createUrlParamsStringFromFilters(filter),
      },
    })
  }, [filter])

  return (
    <div className="animate__animated animate__fadeIn">
      <Helmet title="Overview" />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title="Overview" backIcon={false} />
      {!stat || !stat.reportsOverview || isLoading ? (
        <DefaultLoader />
      ) : (
        <ReportsOverviewLayout
          data={reportsOverview}
          user={user.user}
          dashboard={dashboard}
        />
      )}
    </div>
  )
}

export default ReportingOverviewPage
