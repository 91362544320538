import React from 'react'

import AdvancedStatReportChart from './AdvancedStatReportChart'

const AdvancedStatsReport = ({ data, user }) => {
  return (
    <div className="row">
      {data.map((stat) => {
        return (
          <div className="col-12 col-md-6" key={stat.stat_id}>
            <AdvancedStatReportChart title={stat.stat_name} data={stat.values} user={user} />
          </div>
        )
      })}
    </div>
  )
}

export default AdvancedStatsReport
