import React from 'react'

import AdvancedStats from './advanced/AdvancedStats'
import UploadTradeImages from './advanced/UploadImages'
import TradeDescription from './advanced/TradeDescription'

const TradeAdvancedForm = ({ state, updateState = (f) => f }) => {
  return (
    <div className="row">
      <div className="col-12 mb-4">
        <h5 className="trade-form__subtitle">Advanced Stats</h5>
        <AdvancedStats state={state} updateState={updateState} />
      </div>
      <div className="col-12 mb-4">
        <h5 className="trade-form__subtitle">Upload Images</h5>
        <UploadTradeImages state={state} updateState={updateState} />
      </div>
      <div className="col-12 mb-4">
        <h5 className="trade-form__subtitle">Trade Additional Information</h5>
        <TradeDescription state={state} updateState={updateState} />
      </div>
    </div>
  )
}

export default TradeAdvancedForm
