import React, { useState } from 'react'
import { Modal, message } from 'antd'

import actions from '../../../../redux/advanced-stats/actions'

const CreateAdvancedStatValueModal = ({ dispatch, statId, createStatValueModalVisibility, handleModal }) => {
  const [statValueName, handleStatValueName] = useState('')

  const handleCreateStat = () => {
    message.loading({ content: 'Please, wait...', key: 'create_stat_value' })

    if (statValueName.length === 0) {
      message.error({ content: 'Error. Stat value name fields is required', key: 'create_stat_value' })
    } else {
      // Dispatch
      dispatch({
        type: actions.VALUE_SAVE,
        payload: {
          statId,
          value: statValueName,
        },
      })
      // Reset value name
      handleStatValueName('')
      // Hide modal
      handleModal(false)
    }
  }

  const handleStatValueNameInput = (event) => {
    handleStatValueName(event.target.value)
  }

  const disableFormSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <Modal
      title="Create new advanced stat value"
      visible={createStatValueModalVisibility}
      onOk={handleCreateStat}
      okText="Save"
      onCancel={() => handleModal(false)}
      destroyOnClose
    >
      <div className="row">
        <div className="col-12">
          <form onSubmit={disableFormSubmit}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="advancedStatValueName">Stat value name</label>
            <input
              type="text"
              name="advancedStatValueName"
              className="ant-input"
              required
              placeholder="Input new advanced stat value name here"
              onChange={handleStatValueNameInput}
            />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default CreateAdvancedStatValueModal
