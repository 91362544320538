import React from 'react'
import { Bubble } from 'react-chartjs-2'

import Card from '../../../ui-2/card'

import colors from '../../../../config/apexcharts/colors'
import longNumberToShort from '../../../../helpers/longNumberToShort'

const MaeVsTradeOutcome = ({ type, data, user }) => {
  const chartData = {
    labels: ['MAE vs Trade Outcome'],
    datasets: [],
  }

  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      callbacks: {
        // eslint-disable-next-line no-unused-vars
        label(tooltipItem, tooltipData) {
          if (type === 'profit_loss') {
            return `MAE: ${longNumberToShort(tooltipItem.yLabel, '0.00a')} ${
              user.get_currency.short_name
            } Trade Outcome: ${longNumberToShort(tooltipItem.xLabel, '0.00a')} ${
              user.get_currency.short_name
            }`
          }
          return `MAE: ${longNumberToShort(
            tooltipItem.yLabel,
            '0.00a',
          )}% Trade Outcome: ${longNumberToShort(tooltipItem.xLabel, '0.00a')}%`
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: `MAE (${
              type === 'profit_loss_percents' ? '%' : user.get_currency.short_name
            })`,
          },
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              return `${longNumberToShort(value, '0.0a')}`
            },
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: `Trade Outcome (${
              type === 'profit_loss_percents' ? '%' : user.get_currency.short_name
            })`,
          },
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              return `${longNumberToShort(value, '0.0a')}`
            },
          },
        },
      ],
    },
  }

  const renderChart = () => {
    data.forEach((value) => {
      let maeVal = value.mae
      if (type === 'profit_loss_percents') {
        maeVal = value.mae_percents
      }
      chartData.datasets.push({
        label: value.id,
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.primaryColor,
        borderColor: colors.primaryColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors.primaryColor,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors.primaryColor,
        pointHoverBorderColor: colors.primaryHover,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [{ x: value[type], y: maeVal, r: 3 }],
      })
    })
    return <Bubble data={chartData} options={options} />
  }

  return (
    <Card title="MAE vs Trade Outcome" wrapperClass="m-b-30">
      <div>{renderChart()}</div>
    </Card>
  )
}

export default MaeVsTradeOutcome
