import React, { useContext } from 'react'
import Chart from 'react-apexcharts'

import { ThemeContext } from '../../../../../context/ThemeProvider'

const TotalReturnRadialChart = ({ winRate = 0 }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <div>
      <Chart
        series={[winRate, 100 - winRate]}
        type="donut"
        options={{
          labels: ['Win', 'Loss'],
          colors: ['#007be8', '#007BE819'],
          chart: {
            type: 'donut',
            animations: {
              enabled: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                show: true,
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
                    fontSize: '12px',
                    fontWeight: 400,
                    offsetY: 20,
                    color: theme === 'light' ? '#b5b5c3' : '#676d7d',
                  },
                  value: {
                    show: true,
                    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
                    fontSize: '20px',
                    fontWeight: 600,
                    offsetY: -15,
                    color: theme === 'light' ? '#2e2e3a' : '#ffffff',
                    formatter: function (val) {
                      return `${parseFloat(val).toFixed(0)}%`
                    },
                  },
                  total: {
                    show: true,
                    label: 'Win Rate',
                    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
                    fontSize: '10px',
                    fontWeight: 400,
                    color: theme === 'light' ? '#b5b5c3' : '#676d7d',
                    formatter: (w) => `${parseFloat(w.config.series[0]).toFixed(0)}%`,
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  )
}

export default TotalReturnRadialChart
