import React from 'react'
import classNames from 'classnames'
import { Button, Popconfirm, message } from 'antd'
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import actions from '../../../redux/advanced-stats/actions'

const AdvancedStatsMenu = ({
  dispatch,
  stats,
  active,
  selectAdvancedStat,
  setAdvancedStatTargetToEdit,
}) => {
  const deleteAdvancedStatConfirm = (slug) => {
    message.loading({ content: 'Please, wait...', key: 'delete_stat' })
    dispatch({
      type: actions.DELETE,
      payload: {
        slug,
      },
    })
  }

  return (
    <div className="col-12 col-md-3">
      <div className="as-menu__wrapper">
        {stats.map((item) => {
          let activeClass = ''
          if (active && active === item.slug) {
            activeClass = 'active'
          }
          return (
            <div className={classNames('as-menu__item', activeClass)} key={item.id}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
              <div
                className="item__name"
                onClick={(event) => {
                  selectAdvancedStat(event, item)
                }}
              >
                {item.name}
              </div>
              <div className="item__actions">
                <Button
                  type="link"
                  className="success-color mr-2 border-0"
                  onClick={() => setAdvancedStatTargetToEdit(item)}
                >
                  <EditOutlined />
                </Button>
                <Popconfirm
                  title="Are you really want to delete this advanced stat? All related items will be deleted!"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  okType="danger"
                  okText="Delete"
                  onConfirm={() => deleteAdvancedStatConfirm(item.slug)}
                >
                  <Button type="link" className="danger-color mr-2 border-0">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdvancedStatsMenu
