import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

/**
 * Get initial data for create/edit trade
 */
export function* GET_DATA() {
  try {
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/data/common/trade-form-data',
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { data: req.data.data, isListLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Get initial data for create/edit trade
 */
export function* GET_TRADE_LIST_DATA() {
  try {
    yield put({ type: actions.SET_STATE, payload: { isDataLoading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/data/common/trade-form-data',
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { data: req.data.data, isDataLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isDataLoading: true } })
  }
}

/**
 * Get trades list
 */
export function* LIST({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { trade: {}, isListLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/trades${payload.query}`,
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { trade: {}, trades: req.data.trades, isListLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { trade: {}, isListLoading: true } })
  }
}

/**
 * Get single trade
 */
export function* SINGLE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isSingleTradeLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/single/${payload.slug}`,
      'get',
      null,
      true,
    )
    if (payload.withData) {
      const getData = yield call(
        axiosRequest,
        '/api/v1/data/common/trade-form-data',
        'get',
        null,
        true,
      )
      yield put({ type: actions.SET_STATE, payload: { data: getData.data.data } })
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        trade: req.data.trade,
        notes: req.data.trade.get_notes,
        stats: req.data.stats,
        isSingleTradeLoading: false,
      },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isSingleTradeLoading: true } })
  }
}

/**
 * Save trade saga
 */
export function* SAVE({ payload }) {
  try {
    const req = yield call(axiosRequest, '/api/v1/trades/save', 'post', payload, true)
    if (req.data.success) {
      if (req.data.accountsCheck) {
        yield put({
          type: 'user/SET_STATE',
          payload: { accountsCheck: req.data.accountsCheck },
        })
      }
      message.success({ content: 'Saved', key: 'save_trade' })
      history.push('/trades')
    } else {
      message.destroy()
      notification.warning({
        message: 'Trades limit reached',
        description:
          req.data.message || 'Something goes wrong. Please, contact customers support.',
      })
    }
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'save_trade' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    if (e.response && e.response.status === 422) {
      notification.error({
        message: 'Error',
        description: 'Please, check your inputs and try again.',
      })
    } else if (e.response && e.response.status === 500) {
      notification.error({
        message: 'Error',
        description: 'Server error. Contact support',
      })
    } else {
      history.push('/')
    }
  }
}

/**
 * Update trade saga
 */
export function* UPDATE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/update/${payload.slug}`,
      'post',
      payload,
      true,
    )
    if (req.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: req.data.accountsCheck },
      })
    }
    message.success({ content: 'Saved', key: 'update_trade' })
    history.push('/trades')
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'update_trade' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    if (e.response && e.response.status === 422) {
      notification.error({
        message: 'Error',
        description: 'Please, check your inputs and try again.',
      })
    } else if (e.response && e.response.status === 500) {
      notification.error({
        message: 'Error',
        description: 'Server error. Contact support',
      })
    } else {
      history.push('/')
    }
  }
}

/**
 * Delete trade
 */
export function* DELETE({ payload }) {
  try {
    const deleteTrade = yield call(
      axiosRequest,
      `/api/v1/trades/delete/${payload.slug}`,
      'get',
      null,
      true,
    )
    if (deleteTrade.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: deleteTrade.data.accountsCheck },
      })
    }
    const req = yield call(
      axiosRequest,
      `/api/v1/trades${payload.query}`,
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { trade: {}, trades: req.data.trades, isListLoading: false },
    })
    message.success({ content: 'Deleted', key: 'delete_trade' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'delete_trade' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isSingleTradeLoading: true } })
  }
}

/**
 * Multiple trades delete
 */
export function* MULTIPLE_DELETE({ payload }) {
  try {
    const massDelete = yield call(
      axiosRequest,
      `/api/v1/trades/multiple-delete`,
      'post',
      { ids: payload.ids },
      true,
    )
    if (massDelete.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: massDelete.data.accountsCheck },
      })
    }
    const req = yield call(
      axiosRequest,
      `/api/v1/trades${payload.query}`,
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { trade: {}, trades: req.data.trades, isListLoading: false },
    })
    message.success({ content: 'Done', key: 'multiple_delete_trade' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'multiple_delete_trade' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isSingleTradeLoading: true } })
  }
}

/**
 * Save trade note
 */
export function* SAVE_NOTE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/notes/save`,
      'post',
      payload,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { notes: req.data.notes } })
    message.success({ content: 'Saved', key: 'save_trade_note' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'save_trade_note' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Update trade note
 */
export function* UPDATE_NOTE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/notes/${payload.noteId}`,
      'post',
      payload,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { notes: req.data.notes } })
    message.success({ content: 'Success', key: 'update_trade_note' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'update_trade_note' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Delete trade note
 */
export function* DELETE_NOTE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/notes/${payload.noteId}`,
      'delete',
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { notes: req.data.notes } })
    message.success({ content: 'Note was deleted', key: 'delete_trade_note' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'delete_trade_note' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Enable share trade
 */
export function* ENABLE_SHARE_TRADE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/share/enable/${payload.slug}`,
      'post',
      payload.formData,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { trade: req.data.trade } })
    message.success({ content: 'Success', key: 'enable_share_trade' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'enable_share_trade' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Disable share trade
 */
export function* DISABLE_SHARE_TRADE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/trades/share/disable/${payload.slug}`,
      'delete',
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { trade: req.data.trade } })
    message.success({ content: 'Success', key: 'disable_share_trade' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'disable_share_trade' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Reset single trade on drawer close
 */
export function* RESET_SINGLE_TRADE() {
  yield put({
    type: actions.SET_STATE,
    payload: { trade: {}, isSingleTradeLoading: true },
  })
}

/**
 * Reset trades state
 */
export function RESET() {
  put({ type: actions.RESET })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.GET_TRADE_LIST_DATA, GET_TRADE_LIST_DATA),
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.SINGLE, SINGLE),
    takeEvery(actions.SAVE, SAVE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SAVE_NOTE, SAVE_NOTE),
    takeEvery(actions.UPDATE_NOTE, UPDATE_NOTE),
    takeEvery(actions.DELETE_NOTE, DELETE_NOTE),
    takeEvery(actions.MULTIPLE_DELETE, MULTIPLE_DELETE),
    takeEvery(actions.ENABLE_SHARE_TRADE, ENABLE_SHARE_TRADE),
    takeEvery(actions.DISABLE_SHARE_TRADE, DISABLE_SHARE_TRADE),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.RESET_SINGLE_TRADE, RESET_SINGLE_TRADE),
  ])
}
