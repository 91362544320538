import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactQuill from 'react-quill'
import { Breadcrumb, Button, PageHeader, Form, Card, message, Input } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

import actions from '../../../redux/trading-system/actions'

import { QUILL_FORMATS, QUILL_MODULES } from '../../../config/quill-config'

const mapStateToProps = () => ({})

class CreateNewTradingSystemPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tsBody: '',
    }
  }

  onFormFinish = (values) => {
    message.loading({ content: 'Please, wait...', key: 'save_ts' })
    const { dispatch } = this.props
    const { tsBody } = this.state
    dispatch({
      type: actions.SAVE,
      payload: {
        name: values.name,
        body: tsBody,
      },
    })
  }

  // eslint-disable-next-line class-methods-use-this
  onFormFinishFail = () => {
    message.error({ content: 'Something foes wrong.', key: 'save_ts' })
  }

  handleReactQuill = (html) => {
    this.setState({ tsBody: html })
  }

  render() {
    const pageTitle = 'Create new trading system'
    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/trading-systems">Trading Systems</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button type="primary" htmlType="submit" key="1" form="addNewTS">
                <SaveOutlined />
                <span className="ml-2">Save</span>
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__slideInUp">
          <Card className="mb-4">
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this.onFormFinish}
              onFinishFailed={this.onFormFinishFail}
              className="mb-4"
              id="addNewTS"
            >
              <Form.Item
                name="name"
                label="Trading system name"
                hasFeedback
                rules={[{ required: true, message: 'Please input new trading system name' }]}
              >
                <Input size="large" placeholder="Input TS name" />
              </Form.Item>
              <Form.Item>
                <ReactQuill
                  theme="snow"
                  onChange={this.handleReactQuill}
                  modules={QUILL_MODULES}
                  formats={QUILL_FORMATS}
                  defaultValue="Trading system description..."
                />
              </Form.Item>
            </Form>
          </Card>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(CreateNewTradingSystemPage))
