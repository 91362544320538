/* eslint no-unused-vars:0 */
import React from 'react'

const HoldingTimeStats = ({ data, user }) => {
  return (
    <div className="pt-0 mb-5">
      <table className="table table__xs">
        <tbody>
          <tr>
            <td className="muted-color fw-300">Trades</td>
            <td>{data.totalTrades}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Winners</td>
            <td>{data.winTrades}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Losers</td>
            <td>{data.lossTrades}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Total Performance</td>
            <td>{parseFloat(data.totalPerformance).toFixed(2)}%</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Winrate</td>
            <td>{parseFloat(data.winRate).toFixed(0)}%</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Profit Factor</td>
            <td>{parseFloat(data.profitFactor).toFixed(2)}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Expectancy</td>
            <td>
              {parseFloat(data.expectancy).toFixed(2)} {user.get_currency && user.get_currency.short_name}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <strong>Average Holding Time</strong>
            </td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Avg. per Trade</td>
            <td>{`${data.avgHoldingTime.d}d ${data.avgHoldingTime.h}h ${data.avgHoldingTime.m}m ${data.avgHoldingTime.s}s`}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Winners</td>
            <td>{`${data.avgWinHoldingTime.d}d ${data.avgWinHoldingTime.h}h ${data.avgWinHoldingTime.m}m ${data.avgWinHoldingTime.s}s`}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Losers</td>
            <td>{`${data.avgLossHoldingTime.d}d ${data.avgLossHoldingTime.h}h ${data.avgLossHoldingTime.m}m ${data.avgLossHoldingTime.s}s`}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <strong>Total Holding Time</strong>
            </td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Total Holding Time</td>
            <td>{`${data.totalHoldingTime.d}d ${data.totalHoldingTime.h}h ${data.totalHoldingTime.m}m ${data.totalHoldingTime.s}s`}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Winners</td>
            <td>{`${data.winHoldingTime.d}d ${data.winHoldingTime.h}h ${data.winHoldingTime.m}m ${data.winHoldingTime.s}s`}</td>
          </tr>
          <tr>
            <td className="muted-color fw-300">Losers</td>
            <td>{`${data.lossHoldingTime.d}d ${data.lossHoldingTime.h}h ${data.lossHoldingTime.m}m ${data.lossHoldingTime.s}s`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default HoldingTimeStats
