import React from 'react'
import { Button, DatePicker, InputNumber, Select } from 'antd'
import moment from 'moment'

const TradeExecutions = ({ trade, updateData, addExecution, deleteExecution }) => {
  return (
    <div>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="trade-form__subtitle">Trade Executions</h5>
          <div className="ms-3">
            <Button className="btn-primary" size="small" onClick={() => addExecution()}>
              Add Execution
            </Button>
          </div>
        </div>
      </div>
      {trade.executions && trade.executions.length > 0
        ? trade.executions.map((exec, index) => (
            <div className="trade-form__section mb-4" key={exec.key}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="trade-form__subtitle">Execution #{index + 1}</h5>
                <div className="ms-3">
                  {index !== 0 && (
                    <Button danger size="small" onClick={() => deleteExecution(exec.key)}>
                      Remove
                    </Button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="tradeOption">Action</label>
                    <Select
                      size="large"
                      className="w-100"
                      value={exec.action.toString()}
                      onChange={(value) =>
                        updateData(`executions[${index}].action`, parseInt(value, 10))
                      }
                    >
                      <Select.Option value="0">Sell</Select.Option>
                      <Select.Option value="1">Buy</Select.Option>
                    </Select>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="entryDate">Execution Date</label>
                    <DatePicker
                      size="large"
                      value={moment(exec.execution_date)}
                      className="w-100"
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      onOk={(value) =>
                        updateData(`executions[${index}].execution_date`, value)
                      }
                      onChange={(date) =>
                        updateData(`executions[${index}].execution_date`, date)
                      }
                      showNow={false}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="tradeQty">Share / Contracts Quantity</label>
                    <InputNumber
                      size="large"
                      value={exec.qty}
                      className="w-100"
                      // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => updateData(`executions[${index}].qty`, value)}
                      min={0}
                      max={999999999999999}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="entryPrice">Execution Price</label>
                    <InputNumber
                      size="large"
                      defaultValue={exec.execution_price}
                      className="w-100"
                      onChange={(value) =>
                        updateData(
                          `executions[${index}].execution_price`,
                          parseFloat(value),
                        )
                      }
                      decimalSeparator="."
                      min={0}
                      max={999999999999999}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="profitLoss">Profit/Loss</label>
                    <InputNumber
                      size="large"
                      value={exec.profit_loss}
                      className="w-100"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) =>
                        updateData(`executions[${index}].profit_loss`, parseFloat(value))
                      }
                      min={-999999999999999}
                      max={999999999999999}
                      step={0.01}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="fees">Execution Fees (optional)</label>
                    <InputNumber
                      size="large"
                      value={exec.execution_fees}
                      className="w-100"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) =>
                        updateData(
                          `executions[${index}].execution_fees`,
                          parseFloat(value),
                        )
                      }
                      min={-999999999999999}
                      max={999999999999999}
                      step={0.01}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  )
}

export default TradeExecutions
