const actions = {
  SET_STATE: 'account/SET_STATE',
  LIST: 'account/LIST',
  SINGLE: 'account/SINGLE',
  SAVE: 'account/SAVE',
  UPDATE: 'account/UPDATE',
  DELETE: 'account/DELETE',

  TRANSACTION_SAVE: 'account/transaction/SAVE',
  TRANSACTION_UPDATE: 'account/transaction/UPDATE',
  TRANSACTION_DELETE: 'account/transaction/DELETE',

  RESET: 'account/RESET',
}

export default actions
