/* eslint jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { Select, Button } from 'antd'

import timezonesJson from '../../../../helpers/timezones'

const ImportTradesForm = ({
  data,
  validateBtnLoading,
  platform,
  updateState = (f) => f,
  submitForm = (f) => f,
}) => {
  if (data === false) {
    return null
  }

  return (
    <>
      <form onSubmit={submitForm}>
        <div className="form-group">
          <label htmlFor="platform">Platform</label>
          <Select
            id="platform"
            name="platform"
            className="w-100"
            onChange={(value) => updateState('platform', value)}
            defaultValue="mt4"
            size="large"
          >
            <Select.Option value="binanceUsdM">
              Binance USD@-M <sup>Beta</sup>
            </Select.Option>
            <Select.Option value="ctrader">cTrader</Select.Option>
            <Select.Option value="interactiveBrokers" disabled>
              Interactive Brokers (Soon)
            </Select.Option>
            <Select.Option value="mt4">MetaTrader 4</Select.Option>
            <Select.Option value="mt5">MetaTrader 5</Select.Option>
          </Select>
        </div>
        <div className="form-group">
          <label htmlFor="account">Account</label>
          <Select
            id="account"
            name="account"
            className="w-100"
            size="large"
            onChange={(value) => updateState('account', parseInt(value, 10))}
          >
            {data.accounts.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        <div className="form-group">
          <label htmlFor="tradingSystem">Trading System</label>
          <Select
            id="tradingSystem"
            name="tradingSystem"
            className="w-100"
            size="large"
            onChange={(value) => updateState('tradingSystem', parseInt(value, 10))}
          >
            {data.tradingSystems.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        {!['binanceUsdM'].includes(platform) && (
          <div className="form-group">
            <label htmlFor="tradeMarket">Market</label>
            <Select
              className="w-100"
              id="tradeMarket"
              name="tradeMarket"
              size="large"
              onChange={(value) => updateState('market', value)}
              defaultValue="Forex"
            >
              <Select.Option value="Forex">Forex</Select.Option>
              <Select.Option value="Stock">Stock</Select.Option>
              <Select.Option value="Crypto">Crypto</Select.Option>
              <Select.Option value="Futures">Futures</Select.Option>
              <Select.Option value="Options">Options</Select.Option>
            </Select>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="timeFrame">Time Frame</label>
          <Select
            id="timeFrame"
            name="timeFrame"
            className="w-100"
            size="large"
            onChange={(value) => updateState('timeframe', value)}
          >
            {data.timeframes.map((item) => {
              return (
                <Select.Option value={item.value} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        {['mt4', 'mt5', 'ctrader'].includes(platform) && (
          <div className="form-group">
            <label htmlFor="unitsType">Units/Lots</label>
            <Select
              id="unitsType"
              name="unitsType"
              className="w-100"
              defaultValue="units"
              size="large"
              onChange={(value) => updateState('unitsType', value)}
            >
              <Select.Option value="lots">Lots</Select.Option>
              <Select.Option value="units">Units</Select.Option>
            </Select>
          </div>
        )}
        {['mt4', 'mt5', 'ctrader'].includes(platform) && (
          <div className="form-group">
            <label htmlFor="unitsType">Select Time Zone</label>
            <Select
              showSearch
              id="timezone"
              name="timezone"
              className="w-100"
              defaultValue="Europe/London"
              size="large"
              onChange={(value) => updateState('timezone', value)}
            >
              {Object.keys(timezonesJson).map((tzKey) => (
                <Select.Option key={`${tzKey}${timezonesJson[tzKey]}`} value={tzKey}>
                  {timezonesJson[tzKey]}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <div className="form-group form-upload">
          <label htmlFor="file">Select file</label>
          <input
            type="file"
            name="file"
            id="file"
            required
            className="w-100"
            onChange={(event) => updateState('file', event.target.files[0])}
            accept=".csv,.html,.htm"
          />
        </div>
        <div className="form-group">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-100"
            loading={validateBtnLoading}
          >
            Import
          </Button>
        </div>
      </form>
    </>
  )
}

export default ImportTradesForm
