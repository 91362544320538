import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import actions from '../../../redux/tag/actions'

// eslint-disable-next-line import/no-cycle
import checkIfUserHaveSubscription from '../../../helpers/premiumUserCheck'

import DefaultLoader from '../../../components/system/Loaders/default'
import TagsList from '../../../components/ui/settings/tags/TagsList'

const mapStateToProps = ({ tag }) => ({
  tag,
})

class TagsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = { createModalVisible: false }
  }

  componentDidMount() {
    const { dispatch, user } = this.props
    if (checkIfUserHaveSubscription(user.user)) {
      dispatch({
        type: actions.LIST,
      })
    }
  }

  render() {
    const pageTitle = 'Tags'
    // eslint-disable-next-line no-unused-vars
    const { createModalVisible } = this.state
    const { tag, dispatch } = this.props
    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/dashboard/general">Settings</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button
                type="primary"
                key="1"
                onClick={() => this.setState({ createModalVisible: true })}
              >
                <PlusOutlined />
                <span className="ml-2">Add new tag</span>
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__fadeIn">
          {tag.pageLoading ? (
            <DefaultLoader />
          ) : (
            <TagsList tags={tag.tags} dispatch={dispatch} />
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(TagsPage))
