const actions = {
  SET_STATE: 'stat/SET_STATE',
  DASHBOARD: 'stat/DASHBOARD',
  REPORTS_OVERVIEW: 'stat/REPORTS_OVERVIEW',
  HOURLY_REPORT: 'stat/HOURLY_REPORT',
  WEEKDAY_REPORT: 'stat/WEEKDAY_REPORT',
  MONTHLY_REPORT: 'stat/MONTHLY_REPORT',
  HOLDING_TIME: 'stat/HOLDING_TIME',
  VOLUME_REPORT: 'stat/VOLUME_REPORT',
  ADVANCED_STAT_REPORT: 'stat/ADVANCED_STAT_REPORT',
  INSTRUMENTS_REPORT: 'stat/INSTRUMENTS_REPORT',
  MAE_MFE_REPORT: 'stat/MAE_MFE_REPORT',

  RESET: 'stat/RESET',
}

export default actions
