import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Breadcrumb, PageHeader } from 'antd'

import AnalysisNewsList from '../../../components/ui-2/analysis/news'
import StockOverviewWidget from '../../../components/ui-2/analysis/widgets/overview'
import SimpleStocksList from '../../../components/ui-2/analysis/lists/simple'

const StocksHomePage = () => {
  const pageTitle = 'Stock Analysis'
  return (
    <>
      <Helmet title={pageTitle} />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={pageTitle} />
      <StockOverviewWidget />
      <SimpleStocksList title="Explore the latest stock market updates" />
      <AnalysisNewsList
        title="Latest Stock News and Market Trends"
        subtitle="Stay Ahead of the Game"
        limit={24}
      />
    </>
  )
}

export default StocksHomePage
