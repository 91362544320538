import React, { useState } from 'react'
import ym from 'react-yandex-metrika'
import { Button, Drawer, Form, Input, Select, DatePicker, message } from 'antd'
import moment from 'moment'

import actions from '../../../../redux/account/actions'

const AddTransactionDrawer = ({ dispatch, accountId, addTransactionDrawer, onAddTransactionsDrawerClose }) => {
  const [form] = Form.useForm()
  const [transactionDate, setTransactionDate] = useState(moment())

  const onFinish = (values) => {
    // Message
    message.loading({ content: 'Please, wait...', key: 'save_transaction', duration: 90 })
    // Close rawer
    onAddTransactionsDrawerClose()
    // Clear form
    form.resetFields()
    setTransactionDate(moment())
    // Dispatch
    dispatch({
      type: actions.TRANSACTION_SAVE,
      payload: {
        account: accountId,
        type: values.type,
        value: values.value,
        date: moment(transactionDate).format('YYYY-MM-DD HH:mm'),
      },
    })
    ym('reachGoal', 'userAddTransaction')
  }

  const onFinishFailed = () => {
    message.error('Error')
  }

  const onDateTimeConfirm = (value) => {
    setTransactionDate(value)
  }

  return (
    <Drawer
      title="Add new transaction"
      onClose={onAddTransactionsDrawerClose}
      visible={addTransactionDrawer}
      destroyOnClose
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onAddTransactionsDrawerClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" form="addNewTransaction" htmlType="submit">
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="mb-4"
        id="addNewTransaction"
      >
        <Form.Item
          name="type"
          label="Type"
          hasFeedback
          rules={[{ required: true, message: 'Please select transaction type' }]}
          initialValue={1}
        >
          <Select>
            <Select.Option value={0}>Withdrawal</Select.Option>
            <Select.Option value={1}>Deposit</Select.Option>
            <Select.Option value={2}>Dividends</Select.Option>
            <Select.Option value={3}>Taxes</Select.Option>
            <Select.Option value={4}>Fees</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="value"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please, input transaction value',
            },
            {
              pattern: new RegExp('(?=.*\\d)^\\$?(([1-9]\\d{0,19}(,\\d{3})*)|0)?(\\.\\d{1,2})?$'),
              message: 'Please, input correct transaction value',
            },
          ]}
        >
          <Input size="middle" placeholder="Transaction value" />
        </Form.Item>
        <DatePicker
          className="fullwidth"
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onOk={onDateTimeConfirm}
          onChange={(date) => onDateTimeConfirm(date)}
          defaultValue={moment()}
          showToday={false}
        />
      </Form>
    </Drawer>
  )
}

export default AddTransactionDrawer
