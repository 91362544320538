/* eslint import/no-cycle:0 */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import IndexLayout from './layouts/IndexLayout'
import ScrollToTop from './components/layout/ScrollToTop'

// Import pages components
import Login from './pages/auth/login'
import Register from './pages/auth/register'
import ForgotPassword from './pages/auth/forgot-password'
import NewPasswordAfterReset from './pages/auth/new-password'
import AccountInit from './pages/auth/account-init'
import Dashboard from './pages/dashboard/index'
import ReportingOverviewPage from './pages/reporting/overview'
import HourlyReportPage from './pages/reporting/hourly'
import WeekdayReportPage from './pages/reporting/weekday'
import MonthlyReportPage from './pages/reporting/monthly'
import HoldingTimeReportPage from './pages/reporting/holding-time'
import AdvancedStatsReportPage from './pages/reporting/advanced-stats'
import TradeVolumeReportPage from './pages/reporting/volume'
import InstrumentsReportPage from './pages/reporting/instruments'
import MaeMfaReportPage from './pages/reporting/mae-mfa-report'
import CalendarPage from './pages/tools/calendar'
import AccountsList from './pages/accounts/list'
import SingleAccountPage from './pages/accounts/single'
import TradingSystemsList from './pages/trading-systems/list'
import SingleTradingSystemPage from './pages/trading-systems/single'
import CreateNewTradingSystemPage from './pages/trading-systems/create'
import EditTradingSystemPage from './pages/trading-systems/edit'
import AdvancedStatsMainPage from './pages/advanced-stats/list'
import TradesListPage from './pages/trades/list'
import EditTradePage from './pages/trades/edit'
import CreateTradePage from './pages/trades/create'
import GeneralSettingsPage from './pages/settings/general'
import PasswordSettingsPage from './pages/settings/passwords'
import InstrumentsList from './pages/settings/instruments'
import TagsPage from './pages/settings/tags'
import ImportTradesSettingsPage from './pages/settings/import'
import MyImportsPage from './pages/settings/my-imports'
import SubscriptionPage from './pages/settings/subscription'
import ComingSoon from './pages/analysis/ComingSoon'
import StocksHomePage from './pages/analysis/stocks/StocksHomePage'
import AllStockNews from './pages/analysis/stocks/AllStockNews'
import StockDetails from './pages/analysis/stocks/details'
import PageNotFound from './pages/errors/404'
import SimulatorPage from './pages/tools/simulator'
import ManualTradesForm from './pages/trades/form'
import ForexNews from './pages/makret-news/forex'

const routes = [
  // Auth routes
  {
    path: '/secured/login',
    Component: Login,
    exact: true,
  },
  {
    path: '/secured/register',
    Component: Register,
    exact: true,
  },
  {
    path: '/secured/forgot-password',
    Component: ForgotPassword,
    exact: true,
  },
  {
    path: '/secured/new-password',
    Component: NewPasswordAfterReset,
    exact: true,
  },
  {
    path: '/secured/account/init',
    Component: AccountInit,
    exact: true,
  },
  // Dashboard
  {
    path: '/dashboard',
    Component: Dashboard,
    exact: true,
  },
  // Reporting
  {
    path: '/reports/overview',
    Component: ReportingOverviewPage,
    exact: true,
  },
  {
    path: '/reports/hourly',
    Component: HourlyReportPage,
    exact: true,
  },
  {
    path: '/reports/weekday',
    Component: WeekdayReportPage,
    exact: true,
  },
  {
    path: '/reports/monthly',
    Component: MonthlyReportPage,
    exact: true,
  },
  {
    path: '/reports/holding-time',
    Component: HoldingTimeReportPage,
    exact: true,
  },
  {
    path: '/reports/volume',
    Component: TradeVolumeReportPage,
    exact: true,
  },
  {
    path: '/reports/advanced-stats',
    Component: AdvancedStatsReportPage,
    exact: true,
  },
  {
    path: '/reports/instruments',
    Component: InstrumentsReportPage,
    exact: true,
  },
  {
    path: '/reports/mae-mfe',
    Component: MaeMfaReportPage,
    exact: true,
  },
  // Tools
  {
    path: '/tools/calendar',
    Component: CalendarPage,
    exact: true,
  },
  {
    path: '/tools/simulator',
    Component: SimulatorPage,
    exact: true,
  },
  // Accounts
  {
    path: '/accounts',
    Component: AccountsList,
    exact: true,
  },
  {
    path: '/accounts/single/:slug',
    Component: SingleAccountPage,
    exact: true,
  },
  // Trading Systems
  {
    path: '/trading-systems',
    Component: TradingSystemsList,
    exact: true,
  },
  {
    path: '/trading-systems/single/:slug',
    Component: SingleTradingSystemPage,
    exact: true,
  },
  {
    path: '/create/trading-system',
    Component: CreateNewTradingSystemPage,
    exact: true,
  },
  {
    path: '/trading-systems/edit/:slug',
    Component: EditTradingSystemPage,
    exact: true,
  },
  // Advanced Stats
  {
    path: '/advanced-stats',
    Component: AdvancedStatsMainPage,
    exact: true,
  },
  // Trades
  {
    path: '/trades/form/:slug',
    Component: ManualTradesForm,
    exact: true,
  },
  {
    path: '/create/trade',
    Component: CreateTradePage,
    exact: true,
  },
  {
    path: '/trades',
    Component: TradesListPage,
    exact: true,
  },
  {
    path: '/trades/single/edit/:slug',
    Component: EditTradePage,
    exact: true,
  },
  // Settings
  {
    path: '/settings/general',
    Component: GeneralSettingsPage,
    exact: true,
  },
  {
    path: '/settings/password',
    Component: PasswordSettingsPage,
    exact: true,
  },
  {
    path: '/settings/instruments',
    Component: InstrumentsList,
    exact: true,
  },
  {
    path: '/settings/tags',
    Component: TagsPage,
    exact: true,
  },
  {
    path: '/settings/subscription',
    Component: SubscriptionPage,
    exact: true,
  },
  {
    path: '/settings/import-trades',
    Component: ImportTradesSettingsPage,
    exact: true,
  },
  {
    path: '/settings/my-imports',
    Component: MyImportsPage,
    exact: true,
  },
  // Analysis
  {
    path: '/analysis/stocks',
    Component: StocksHomePage,
    exact: true,
  },
  {
    path: '/analysis/stocks/symbol/:slug',
    Component: StockDetails,
    exact: true,
  },
  {
    path: '/analysis/stocks/all-news',
    Component: AllStockNews,
    exact: true,
  },
  {
    path: '/analysis/forex',
    Component: ComingSoon,
    exact: true,
  },
  {
    path: '/analysis/crypto',
    Component: ComingSoon,
    exact: true,
  },
  {
    path: '/market-news/forex',
    Component: ForexNews,
    exact: true,
  },
  // Errors pages
  {
    path: '/404',
    Component: PageNotFound,
    exact: true,
  },
]

const mapStateToProps = () => ({
  routerAnimation: 'app-animation slide-fadein-up',
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <ScrollToTop />
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                              <Component />
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </IndexLayout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
