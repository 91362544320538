import React from 'react'

import Card from '../../card'

import styles from './general-insights.module.scss'

const GeneralInsights = () => {
  return (
    <Card highlight>
      <div className={styles.generalInsights}>
        <div className={styles.title}>
          <p>Tradiry AI</p>
          <h3>Insights</h3>
        </div>
        <div className={styles.comingSoon}>
          <p className="mb-0">
            Exciting developments are on the horizon! We're thrilled to announce that
            Tradiry AI Insights will soon be joining our platform. Get ready to
            supercharge your trading experience with powerful insights driven by
            state-of-the-art AI technology. Stay tuned for updates as we gear up to
            empower you with invaluable knowledge and expertise to take your trading to
            new heights! 📊✨
          </p>
        </div>
      </div>
    </Card>
  )
}

export default GeneralInsights
