import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader } from 'antd'

import actions from '../../../redux/stat/actions'
import createUrlParamsStringFromFilters from '../../../helpers/createUrlParamsFromFilters'
import reloadPageWithUrlParams from '../../../helpers/reloadPageWithUrlParams'

import NotEnoughData from '../../../components/ui/messages/NotEnoughData'
import ReportsOverviewLoader from '../../../components/system/Loaders/reports-loaders/OverviewLoader'
import InstrumentsReportChart from '../../../components/ui/reports/instruments/InstrumentsReportChart'
import InstrumentsReportTable from '../../../components/ui/reports/instruments/InstrumentsReportTable'
import UnlockAllFeatures from '../../../components/system/Subscription/UnlockAllFeatures'

const mapStateToProps = ({ stat, filter, user }) => ({ stat, filter, user })

class InstrumentsReportPage extends React.Component {
  componentDidMount() {
    const { dispatch, filter } = this.props
    dispatch({
      type: actions.INSTRUMENTS_REPORT,
      payload: {
        query: createUrlParamsStringFromFilters(filter),
      },
    })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filter, dispatch } = this.props
    if (filter !== prevProps.filter) {
      reloadPageWithUrlParams(
        dispatch,
        actions.INSTRUMENTS_REPORT,
        createUrlParamsStringFromFilters(filter),
      )
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  render() {
    const pageTitle = 'Instruments Report'
    const { stat, user } = this.props

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/overview">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {!stat.instrumentsReport.data || stat.isLoading ? (
            <ReportsOverviewLoader />
          ) : (
            <>
              {stat.instrumentsReport.data.error &&
              stat.instrumentsReport.data.error === 'no-content' ? (
                <NotEnoughData />
              ) : (
                <>
                  <div className="mb-4">
                    <InstrumentsReportChart
                      data={
                        stat.instrumentsReport.data ? stat.instrumentsReport.data : []
                      }
                      user={user.user}
                    />
                  </div>
                  <div>
                    {user.user.is_premium === 1 ? (
                      <InstrumentsReportTable
                        data={
                          stat.instrumentsReport.data ? stat.instrumentsReport.data : []
                        }
                        user={user.user}
                      />
                    ) : (
                      <UnlockAllFeatures title="Detailed report" compact />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(InstrumentsReportPage))
