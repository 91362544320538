import React, { useState } from 'react'

import actions from '../../../redux/instrument/actions'

import InstrumentsListItem from './InstrumentsListItem'
import TradesListPagination from '../trades/TradesListPagination'

// eslint-disable-next-line no-unused-vars
const InstrumentsList = ({ dispatch, instruments, btnLoading }) => {
  // eslint-disable-next-line no-unused-vars
  const [page, handlePages] = useState(1)

  const updateState = (target, value) => {
    handlePages(value)
    dispatch({
      type: actions.LIST,
      payload: {
        page: value,
      },
    })
  }

  return (
    <div className="animate__animated animate__fadeIn">
      {instruments.data.length > 0 && (
        <div className="row">
          {instruments.data.map((item) => {
            return <InstrumentsListItem key={item.id} dispatch={dispatch} instrument={item} />
          })}
        </div>
      )}
      {instruments.data && (
        <TradesListPagination
          currentPage={instruments.current_page}
          totalPages={instruments.last_page}
          updateState={updateState}
        />
      )}
    </div>
  )
}

export default InstrumentsList
