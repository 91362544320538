import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Breadcrumb, PageHeader } from 'antd'

import AnalysisNewsList from '../../../components/ui-2/analysis/news'

const AllStockNews = () => {
  const pageTitle = 'Stock Market News'

  return (
    <>
      <Helmet title={pageTitle} />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/analysis/stocks">Stocks Analysis</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={pageTitle} />
      <AnalysisNewsList
        view="grid"
        title="Latest Stock News and Market Trends"
        limit={300}
      />
    </>
  )
}

export default AllStockNews
