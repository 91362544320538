import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

/**
 * Get users accounts saga
 */
export function* LIST() {
  try {
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
    const getAccounts = yield call(
      axiosRequest,
      '/api/v1/accounts/list',
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { accounts: getAccounts.data.accounts, isListLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Get single account saga
 */
export function* SINGLE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
    const getAccount = yield call(
      axiosRequest,
      `/api/v1/accounts/single/${payload.slug}`,
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: {
        account: getAccount.data.account,
        accountStats: getAccount.data.stats,
        transactions: getAccount.data.account.get_transactions,
        isListLoading: false,
      },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Save account saga
 */
export function* SAVE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: true } })
    const req = yield call(axiosRequest, '/api/v1/accounts/save', 'post', payload, true)
    if (req.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { saveAccountBtnLoading: false, accounts: req.data.accounts },
      })
      message.success({ content: 'Success', key: 'save_account' })
    } else {
      message.destroy()
      notification.warning({
        message: 'Accounts limit reached',
        description:
          req.data.message || 'Something goes wrong. Please, contact customers support.',
      })
    }
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'save_account' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
  }
}

/**
 * Update account saga
 */
export function* UPDATE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/accounts/update/${payload.id}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { saveAccountBtnLoading: false, account: req.data.account },
    })
    message.success({ content: 'Success', key: 'update_account' })
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'update_account' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
  }
}

/**
 * Delete account saga
 */
export function* DELETE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/accounts/delete/${payload.id}`,
      'post',
      payload,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
    if (req.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: req.data.accountsCheck },
      })
    }
    message.success({ content: 'Success', key: 'delete_account' })
    history.push('/accounts')
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'delete_account' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
  }
}

/**
 * Save account transaction saga
 */
export function* SAVE_TRANSACTION({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/accounts/transactions/save',
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { saveAccountBtnLoading: false, transactions: req.data.transactions },
    })
    if (req.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: req.data.accountsCheck },
      })
    }
    message.success({ content: 'Success', key: 'save_transaction' })
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'save_transaction' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
  }
}

/**
 * Update account transaction saga
 */
export function* UPDATE_TRANSACTION({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/accounts/transactions/update/${payload.id}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { saveAccountBtnLoading: false, transactions: req.data.transactions },
    })
    if (req.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: req.data.accountsCheck },
      })
    }
    message.success({ content: 'Success', key: 'update_transaction' })
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'update_transaction' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
  }
}

/**
 * Delete account transaction saga
 */
export function* DELETE_TRANSACTION({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/accounts/transactions/delete/${payload.id}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { saveAccountBtnLoading: false, transactions: req.data.transactions },
    })
    if (req.data.accountsCheck) {
      yield put({
        type: 'user/SET_STATE',
        payload: { accountsCheck: req.data.accountsCheck },
      })
    }
    message.success({ content: 'Success', key: 'delete_transaction' })
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'delete_transaction' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { saveAccountBtnLoading: false } })
  }
}

/**
 * Reset accounts state
 */
export function RESET() {
  put({ type: actions.RESET })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.SINGLE, SINGLE),
    takeEvery(actions.SAVE, SAVE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.TRANSACTION_SAVE, SAVE_TRANSACTION),
    takeEvery(actions.TRANSACTION_UPDATE, UPDATE_TRANSACTION),
    takeEvery(actions.TRANSACTION_DELETE, DELETE_TRANSACTION),
    takeEvery(actions.RESET, RESET),
  ])
}
