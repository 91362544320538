import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

const OverviewItem = ({ title, instrument, value = 0, valuePostfix = null }) => {
  return (
    <div className={styles.SliderItem}>
      <Link to={`/analysis/stocks/symbol/${instrument.slug}`}>
        <h5 className={styles.Title}>{title}</h5>
        <div className={styles.Logo}>
          <div className={styles.Logo__Wrapper}>
            <img src={instrument.logo} alt={instrument.name} />
          </div>
        </div>
        <h4 className={styles.InstrumentName}>
          <span>
            {instrument.name.split(' ').slice(0, 2).join(' ').replace(/,\s*$/, '')}
          </span>{' '}
          {instrument.symbol}
        </h4>
        <div className={styles.Price} data-direction={value >= 0.0 ? 'up' : 'down'}>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            instrument.price || 0,
          )}
        </div>
        <div className={styles.Value} data-direction={value >= 0.0 ? 'up' : 'down'}>
          {new Intl.NumberFormat().format(value)} {valuePostfix || ''}
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.65625 0L11.1875 1.53125L7.9375 4.78125L5.28125 2.125L0.34375 7.0625L1.28125 8L5.28125 4L7.9375 6.65625L12.125 2.46875L13.6562 4V0H9.65625Z"
              fill="#23E33E"
            />
          </svg>
        </div>
      </Link>
    </div>
  )
}

export default OverviewItem
