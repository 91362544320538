import { notification } from 'antd'

const axiosError = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    notification.error({ description: error.response.data.message, message: 'Error' })
  } else {
    notification.error({
      description: 'Something goes wrong, please try again later...',
      message: 'Error',
    })
  }
}

export default axiosError
