import React from 'react'
import { Button, Form, Input, Select, message } from 'antd'
import moment from 'moment'
import 'moment-timezone'

import actions from '../../../../redux/user/actions'

import { DATE_FORMATS, TIME_FORMATS } from '../../../../config/data/date-time'

const GeneralSettingsForm = ({ dispatch, user, countries, currencies }) => {
  const onFinish = (values) => {
    message.loading({ content: 'Please, wait...', key: 'general_settings_update' })
    dispatch({
      type: actions.GENERAL_SETTINGS,
      payload: values,
    })
  }

  const onFinishFailed = () => {
    message.error({
      content: 'Something goes wrong. Try again later.',
      key: 'general_settings_update',
    })
  }

  return (
    <>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          country: user.get_country.id,
          currency: user.get_currency.id,
          timezone: user.timezone,
          date_format: user.date_format,
          time_format: user.time_format,
        }}
      >
        <div className="row">
          <div className="col-6">
            <Form.Item
              name="first_name"
              label="First name"
              hasFeedback
              rules={[{ required: true, message: 'Please input your first name' }]}
            >
              <Input size="large" placeholder="Input your first name" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item
              name="last_name"
              label="Last name"
              hasFeedback
              rules={[{ required: true, message: 'Please input your last name' }]}
            >
              <Input size="large" placeholder="Input your last name" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Item
              name="country"
              label="Country"
              hasFeedback
              rules={[{ required: true, message: 'Please select your country' }]}
            >
              <Select size="large">
                {countries.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Item
              name="currency"
              label="Account currency"
              hasFeedback
              rules={[{ required: true, message: 'Please select your currency' }]}
            >
              <Select size="large">
                {currencies.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Item
              name="timezone"
              label="Timezone"
              hasFeedback
              rules={[{ required: true, message: 'Please select your timezone' }]}
            >
              <Select size="large">
                {moment.tz.names().map((item) => {
                  return (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Item
              name="date_format"
              label="Date format"
              hasFeedback
              rules={[{ required: true, message: 'Please select date format' }]}
            >
              <Select size="large">
                {DATE_FORMATS.map((item) => {
                  return (
                    <Select.Option value={item.format} key={item.format}>
                      {moment().format(item.format)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Item
              name="time_format"
              label="Time format"
              hasFeedback
              rules={[{ required: true, message: 'Please select time format' }]}
            >
              <Select size="large">
                {TIME_FORMATS.map((item) => {
                  return (
                    <Select.Option value={item.format} key={item.format}>
                      {moment().format(item.format)} ({item.name})
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <Button
              type="primary"
              size="large"
              className="text-center fw-600"
              htmlType="submit"
              loading={user.loading}
            >
              Update Information
            </Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default GeneralSettingsForm
