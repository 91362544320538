import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PageHeader, Breadcrumb, Button, Popconfirm, message } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import actions from '../../../redux/account/actions'

import DefaultLoader from '../../../components/system/Loaders/default'
import AccountTransactionItem from '../../../components/ui/accounts/TransactionsListItem'
import EditAccountDrawer from '../../../components/ui/accounts/drawers/EditAccountDrawer'
import AddTransactionDrawer from '../../../components/ui/accounts/drawers/AddTransactionDrawer'
import EditTransactionDrawer from '../../../components/ui/accounts/drawers/EditTransactionDrawer'
import AccountPerformanceChart from '../../../components/ui/accounts/AccountPerformanceChart'
import AccountStats from '../../../components/ui/accounts/AccountStats'
import Card from '../../../components/ui-2/card'

const mapStateToProps = ({ account, user }) => ({
  account,
  user,
})

class SingleAccountPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deletePopConfirm: false,
      editAccountDrawer: false,
      addTransactionDrawer: false,
      editTransactionDrawer: false,
      transactionToEdit: {},
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch({
      type: actions.SINGLE,
      payload: {
        slug: match.params.slug,
      },
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  onDrawerOpen = () => {
    this.setState({ editAccountDrawer: true })
  }

  onDrawerClose = () => {
    this.setState({ editAccountDrawer: false })
  }

  onAddTransactionsDrawerOpen = () => {
    this.setState({ addTransactionDrawer: true })
  }

  onAddTransactionsDrawerClose = () => {
    this.setState({ addTransactionDrawer: false })
  }

  onEditTransactionsDrawerOpen = (transaction) => {
    this.setState({ editTransactionDrawer: true, transactionToEdit: transaction })
  }

  onEditTransactionsDrawerClose = () => {
    this.setState({ editTransactionDrawer: false })
  }

  handlePopConfirmVisibility = () => {
    const { deletePopConfirm } = this.state
    if (!deletePopConfirm) {
      this.setState({ deletePopConfirm: true })
    } else {
      this.setState({ deletePopConfirm: false })
    }
  }

  handleAccountDelete = () => {
    const { dispatch, account } = this.props
    message.loading({ content: 'Please, wait...', key: 'delete_account' })
    dispatch({
      type: actions.DELETE,
      payload: { id: account.account.id },
    })
  }

  render() {
    const { account, dispatch, user } = this.props
    const { accountStats } = account
    const {
      deletePopConfirm,
      editAccountDrawer,
      addTransactionDrawer,
      editTransactionDrawer,
      transactionToEdit,
    } = this.state

    if (account.isListLoading) {
      return <DefaultLoader />
    }

    const pageTitle = account.account.name

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/accounts">Accounts</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button type="primary" key="1" onClick={this.onAddTransactionsDrawerOpen}>
                <PlusOutlined />
                <span className="ml-2">Add transaction</span>
              </Button>,
              <Button type="success" key="2" onClick={this.onDrawerOpen}>
                <EditOutlined />
                <span className="ml-2">Edit</span>
              </Button>,
              <Button
                type="danger"
                className="ml-3"
                key="3"
                onClick={this.handlePopConfirmVisibility}
              >
                <DeleteOutlined />
                <Popconfirm
                  title="Do you really want to delete this account?"
                  placement="bottomRight"
                  visible={deletePopConfirm}
                  onVisibleChange={this.handlePopConfirmVisibility}
                  okText="Yes"
                  okType="danger"
                  onConfirm={this.handleAccountDelete}
                >
                  <span className="ml-2">Delete</span>
                </Popconfirm>
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__fadeIn">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-8">
              <AccountPerformanceChart
                trades={account.account.get_trades ? account.account.get_trades : []}
                user={user.user}
              />
              <Card
                wrapperClass="m-b-30"
                title={`Transactions (${account.transactions.length})`}
              >
                <div className="transactions-list">
                  {account.transactions.map((item) => {
                    return (
                      <AccountTransactionItem
                        key={item.slug}
                        dispatch={dispatch}
                        transaction={item}
                        user={user.user}
                        accountId={account.account.id}
                        onEditTransactionsDrawerOpen={this.onEditTransactionsDrawerOpen}
                      />
                    )
                  })}
                </div>
              </Card>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <AccountStats
                account={account.account}
                stats={accountStats}
                user={user.user}
              />
            </div>
          </div>
        </div>
        <EditAccountDrawer
          account={account.account}
          drawerVisible={editAccountDrawer}
          onDrawerClose={this.onDrawerClose}
          dispatch={dispatch}
        />
        <AddTransactionDrawer
          dispatch={dispatch}
          accountId={account.account.id}
          addTransactionDrawer={addTransactionDrawer}
          onAddTransactionsDrawerClose={this.onAddTransactionsDrawerClose}
        />
        <EditTransactionDrawer
          dispatch={dispatch}
          accountId={account.account.id}
          transaction={transactionToEdit}
          editTransactionDrawer={editTransactionDrawer}
          onEditTransactionDrawerClose={this.onEditTransactionsDrawerClose}
          onEditTransactionsDrawerOpen={this.onEditTransactionsDrawerOpen}
        />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(SingleAccountPage))
