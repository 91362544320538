import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

/**
 * Get advanced stats list
 */
export function* LIST() {
  try {
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
    const req = yield call(axiosRequest, '/api/v1/advanced-stats/list', 'get', null, true)
    yield put({
      type: actions.SET_STATE,
      payload: { stats: req.data.stats, isListLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Get single advanced stat
 */
export function* SINGLE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/advanced-stats/single/${payload.slug}`,
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { stats: req.data.stats, stat: req.data.stat, values: req.data.values },
    })
    message.success({ content: 'Success', key: 'as_single' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'as_single' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Save advanced stat
 */
export function* SAVE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      '/api/v1/advanced-stats/save',
      'post',
      payload,
      true,
    )
    if (req.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { stats: req.data.stats },
      })
      message.success({ content: 'Success', key: 'save_stat' })
    } else {
      message.destroy()
      notification.warning({
        message: 'Advanced stats limit reached',
        description:
          req.data.message || 'Something goes wrong. Please, contact customers support.',
      })
    }
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'save_stat' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Update advanced stat
 */
export function* UPDATE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/advanced-stats/update/${payload.slug}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { stats: req.data.stats },
    })
    message.success({ content: 'Success', key: 'update_stat' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'update_stat' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Delete advanced stat
 */
export function* DELETE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/advanced-stats/delete/${payload.slug}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { stats: req.data.stats },
    })
    message.success({ content: 'Success', key: 'delete_stat' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'delete_stat' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Save advanced stat value
 */
export function* VALUE_SAVE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      '/api/v1/advanced-stats/values/save',
      'post',
      payload,
      true,
    )
    if (req.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { values: req.data.values },
      })
      message.success({ content: 'Saved', key: 'create_stat_value' })
    } else {
      message.destroy()
      notification.warning({
        message: 'Advanced stats values limit reached',
        description:
          req.data.message || 'Something goes wrong. Please, contact customers support.',
      })
    }
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'create_stat_value' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Update advanced stat value
 */
export function* VALUE_UPDATE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/advanced-stats/values/update/${payload.valueId}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { values: req.data.values },
    })
    message.success({ content: 'Success', key: 'update_stat_value' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'update_stat_value' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Delete advanced stat value
 */
export function* VALUE_DELETE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      `/api/v1/advanced-stats/values/delete/${payload.valueId}`,
      'post',
      payload,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { values: req.data.values },
    })
    message.success({ content: 'Success', key: 'delete_value' })
  } catch (e) {
    message.error({ content: 'Something goes wrong', key: 'delete_value' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Reset accounts state
 */
export function RESET() {
  put({ type: actions.RESET })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.SINGLE, SINGLE),
    takeEvery(actions.SAVE, SAVE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.VALUE_SAVE, VALUE_SAVE),
    takeEvery(actions.VALUE_UPDATE, VALUE_UPDATE),
    takeEvery(actions.VALUE_DELETE, VALUE_DELETE),
    takeEvery(actions.RESET, RESET),
  ])
}
