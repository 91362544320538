import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

import Card from '../../../card'

import longNumberToShort from '../../../../../helpers/longNumberToShort'

import styles from './styles.module.scss'

const BalancesWidget = ({ stat, user }) => {
  const [data, setData] = useState({
    totalBalance: 0,
    fees: 0,
    deposits: 0,
    withdrawals: 0,
  })

  useEffect(() => {
    if (!stat.isLoading && stat.dashboard && stat.dashboard.stats) {
      setData({
        totalBalance: stat.dashboard.stats.totalBalance,
        fees: stat.dashboard.fees,
        deposits: stat.dashboard.deposits,
        withdrawals: stat.dashboard.withdrawals,
      })
    }
  }, [stat])

  return (
    <Card title="Accounts">
      <Spin spinning={stat.isLoading}>
        <div className={styles.balancesWidget}>
          <div className="row">
            <div className="col-6 col-xl-3 mb-2 mb-xl-0">
              <div className={styles.item}>
                <div className={styles.icon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                    <polyline points="17 6 23 6 23 12" />
                  </svg>
                </div>
                <div className={`my-auto ${styles.content}`}>
                  <h4 className={styles.title}>
                    {longNumberToShort(parseFloat(data.totalBalance).toFixed(2))}{' '}
                    {user.get_currency && user.get_currency.short_name
                      ? user.get_currency.short_name
                      : ''}
                  </h4>
                  <p className={styles.subTitle}>Total Balance</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-3 mb-2 mb-xl-0">
              <div className={`${styles.item} ${styles.itemDanger}`}>
                <div className={styles.icon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="16" x2="12" y2="12" />
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                  </svg>
                </div>
                <div className={`my-auto ${styles.content}`}>
                  <h4 className={styles.title}>
                    {longNumberToShort(parseFloat(data.fees).toFixed(2))}{' '}
                    {user.get_currency && user.get_currency.short_name
                      ? user.get_currency.short_name
                      : ''}
                  </h4>
                  <p className={styles.subTitle}>Fees Paid</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-3 mb-2 mb-xl-0">
              <div className={`${styles.item} ${styles.itemWarning}`}>
                <div className={styles.icon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </div>
                <div className={`my-auto ${styles.content}`}>
                  <h4 className={styles.title}>
                    {longNumberToShort(parseFloat(data.deposits).toFixed(2))}{' '}
                    {user.get_currency && user.get_currency.short_name
                      ? user.get_currency.short_name
                      : ''}
                  </h4>
                  <p className={styles.subTitle}>Total Deposits</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-3 mb-2 mb-xl-0">
              <div className={`${styles.item} ${styles.itemSuccess}`}>
                <div className={styles.icon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="12" y1="1" x2="12" y2="23" />
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                  </svg>
                </div>
                <div className={`my-auto ${styles.content}`}>
                  <h4 className={styles.title}>
                    {longNumberToShort(parseFloat(data.withdrawals).toFixed(2))}{' '}
                    {user.get_currency && user.get_currency.short_name
                      ? user.get_currency.short_name
                      : ''}
                  </h4>
                  <p className={styles.subTitle}>Total Withdrawals</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Card>
  )
}

export default BalancesWidget
