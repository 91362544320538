import { history } from '../index'

const convertObjToUrlParams = (obj) => {
  const str = []

  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    if (obj[p]) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }
  }
  const urlParams = `?${str.join('&')}`
  history.push(`${history.location.pathname}${urlParams}`)
  return urlParams
}

export default convertObjToUrlParams
