import React from 'react'
import { v4 } from 'uuid'
import { Link } from 'react-router-dom'
import { Alert } from 'antd'

const LayoutAlerts = ({ user }) => {
  const emailReturn = () => {
    return (
      <span>
        Please validate your email address. <Link to="/settings/general">Verify now</Link>
      </span>
    )
  }

  const getAccountAlertString = (account) => {
    return (
      <span>
        You need to add deposits/withdrawals for account <strong>&ldquo;{account.name}&ldquo;</strong>. Missing deposits
        can cause statistics errors. Note that first deposit should be before your first trade for this account.{' '}
        <Link to={`/accounts/single/${account.slug}`}>Fix now</Link>
      </span>
    )
  }

  const accountsChecksRenderer = () => {
    if (user.accountsCheck && user.accountsCheck.length > 0) {
      return user.accountsCheck.map((account) => {
        if (account.status === false) {
          return <Alert message={getAccountAlertString(account)} key={v4()} type="warning" showIcon className="mb-2" />
        }
        return null
      })
    }
    return null
  }

  return (
    <div className="mt-4">
      {!user.user.email_verified_at && <Alert message={emailReturn()} type="error" showIcon className="mb-2" />}
      {accountsChecksRenderer()}
    </div>
  )
}

export default LayoutAlerts
