import React, { useState, useEffect } from 'react'
import ym from 'react-yandex-metrika'

import SubscribeButton from './SubscribeButton'
import SpinnerLoader from '../../../system/Loaders/spinner'

// import { PADDLE_PRODUCT, PADDLE_ENV } from '../../../../config/app'

const SubscriptionPromo = ({ user }) => {
  const [price, handlePrice] = useState('loading')

  useEffect(() => {
    // const { Paddle } = window
    // if (PADDLE_ENV === 'sandbox') {
    //   Paddle.Environment.set(PADDLE_ENV)
    // }
    ym('reachGoal', 'subscriptionPromoViewed')
    handlePrice(
      `<table><tr><td>Tradiry Premium</td><td>$20 <small>(Billed monthly)</small></td></tr><tr><td colspan="2">VAT not included</td></tr></table>`,
    )
    // eslint-disable-next-line consistent-return
    // Paddle.Product.Prices(PADDLE_PRODUCT, 1, (data) => {
    //   if (data.price) {
    //     handlePrice(
    //       `<table><tr><td>Tradiry Premium</td><td>${data.price.net.replaceAll(
    //         'US$',
    //         '$',
    //       )} <small>(Billed monthly)</small></td></tr><tr><td>Taxes (VAT)</td><td>${data.price.tax.replaceAll(
    //         'US$',
    //         '$',
    //       )}</td></tr><tr class="total"><td>Total</td><td>${data.price.gross.replaceAll(
    //         'US$',
    //         '$',
    //       )}</td></tr></table>`,
    //     )
    //   } else {
    //     handlePrice('<p>20$/month (without VAT)</p>')
    //   }
    //   // Added Yandex.Metrika Goal
    //   ym('reachGoal', 'subscriptionPromoViewed')
    // })
  }, [])

  return (
    <>
      <div className="row mb-5 subscription-promo">
        <div className="col-12 col-md-8 col-lg-5 offset-lg-1">
          <div className="subscription-promo__details">
            <h2>
              Unlock all features of <strong>Tradiry Trading Journal</strong>
            </h2>
            <ul>
              <li>Unlimited trades</li>
              <li>Unlimited accounts</li>
              <li>Unlimited trading systems</li>
              <li>Unlimited advanced stats</li>
              <li>
                Trade tags <sup>Coming soon</sup>
              </li>
              <li>Trade charting</li>
              <li>Trades import (CSV, MetaTrader 4, MetaTrader 5)</li>
              <li>Artificial intelligence insights</li>
              <li>Premium support</li>
            </ul>
            {user.is_premium === 0 ? (
              <>
                <h2 className="bt-1">
                  Your current subscription is <strong>Free</strong>
                </h2>
                <ul>
                  <li>Up to 50 trades</li>
                  <li>1 account</li>
                  <li>1 trading system</li>
                  <li>Up to 5 advanced stats</li>
                  <li>Up to 3 values per advanced stat</li>
                </ul>
              </>
            ) : (
              <>
                <h2 className="bt-1">
                  Your current subscription is <strong>Premium</strong>
                </h2>
                <p>
                  Your <strong>Premium</strong> subscription has been canceled.
                </p>
                <p>
                  Premium features will be available until{' '}
                  <u>
                    <i>{user.premium_expire}</i>
                  </u>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="subscription-promo__subscribe-block">
            <h2>
              Tradiry <strong>Premium</strong>
            </h2>
            <p>
              Start working with Tradiry online trading journal which can provide
              everything you need to increase your trading performance to a whole new
              level.
            </p>
            {price === 'loading' ? (
              <SpinnerLoader />
            ) : (
              <p
                className="subscription-promo__price"
                dangerouslySetInnerHTML={{ __html: price }}
              />
            )}
            <SubscribeButton user={user} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionPromo
