/* eslint import/no-cycle:0 */
import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Skeleton, Empty, notification, message } from 'antd'

import TradesImportList from '../../../components/ui/settings/import-list'

import actions from '../../../redux/filter/actions'
import getAPITokenFromLocalStorage from '../../../helpers/getAPITokenFromLocalStorage'
import checkIfUserHaveSubscription from '../../../helpers/premiumUserCheck'
import { API_URL } from '../../../config/app'

const mapStateToProps = ({ filter, user }) => ({ filter, user })

class MyImportsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      imports: [],
    }
  }

  componentDidMount() {
    const { user } = this.props
    if (checkIfUserHaveSubscription(user.user)) {
      axios
        .get(`${API_URL}/api/v1/import-trades`, getAPITokenFromLocalStorage(true))
        .then((res) => {
          if (res.data.success) {
            this.setState({
              imports: res.data.imports,
              loading: false,
            })
          }
        })
        .catch(() => {
          notification.error({
            description: 'Something goes wrong. Try again later',
            message: 'Error',
          })
        })
    }
  }

  showImportedTrades = (importId) => {
    const { dispatch, filter } = this.props
    const newFilters = filter
    newFilters.isEnabled = true
    newFilters.importId = importId
    dispatch({
      type: actions.APPLY_AND_SEE_TRADES_LIST,
      payload: newFilters,
    })
  }

  deleteImport = (importCode) => {
    message.loading({
      content: 'Please, wait...',
      duration: 300,
      key: 'delete_imported_trades',
    })
    axios
      .delete(
        `${API_URL}/api/v1/import-trades/${importCode}`,
        getAPITokenFromLocalStorage(true),
      )
      .then((res) => {
        message.success({ content: 'Success', key: 'delete_imported_trades' })
        if (res.data.success) {
          this.setState({ imports: res.data.imports, loading: false })
        }
      })
      .catch(() => {
        message.error({
          content: 'Something goes wrong. Try again later.',
          key: 'delete_imported_trades',
        })
      })
  }

  render() {
    const pageTitle = 'My Trades Imports'
    const { user } = this.props
    const { imports, loading } = this.state

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {loading ? (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <>
              {imports.length === 0 ? (
                <Empty description="Imports not found" />
              ) : (
                <TradesImportList
                  imports={imports}
                  user={user.user}
                  showImportedTrades={this.showImportedTrades}
                  deleteImport={this.deleteImport}
                />
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(MyImportsPage))
