import React from 'react'

const SIDE_MENU = [
  {
    category: false,
    name: 'Dashboard',
    key: 'dashboard',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
          fill="black"
        />
      </svg>
    ),
    url: '/dashboard',
    premiumOnly: false,
  },
  {
    category: false,
    name: 'Trades',
    key: 'trades',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
          fill="black"
        />
        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
      </svg>
    ),
    url: '/trades',
    premiumOnly: false,
  },
  {
    category: false,
    name: 'Reports',
    key: 'reports',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M8.4 14L15.6 8.79999L20 9.90002V6L16 4L9 11L5 12V14H8.4Z"
          fill="black"
        />
        <path
          d="M21 18H20V12L16 11L9 16H6V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z"
          fill="black"
        />
      </svg>
    ),
    url: '/reports',
    premiumOnly: false,
    isOpen: false,
    childs: [
      {
        category: false,
        name: 'Overview',
        key: 'reports-overview',
        url: '/reports/overview',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Hourly',
        key: 'reports-hourly',
        url: '/reports/hourly',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Weekday',
        key: 'reports-weekday',
        url: '/reports/weekday',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Monthly',
        key: 'reports-monthly',
        url: '/reports/monthly',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Holding Time',
        key: 'holding-time',
        url: '/reports/holding-time',
        premiumOnly: true,
      },
      {
        category: false,
        name: 'Volume',
        key: 'reports-volume',
        url: '/reports/volume',
        premiumOnly: false,
      },
      // {
      //   category: false,
      //   name: 'Advanced Stats',
      //   key: 'advanced-stats',
      //   url: '/reports/advanced-stats',
      //   premiumOnly: false,
      // },
      {
        category: false,
        name: 'Instruments',
        key: 'reports-instruments',
        url: '/reports/instruments',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'MAE/MFE',
        key: 'mae-mfa-report',
        url: '/reports/mae-mfe',
        premiumOnly: false,
      },
    ],
  },
  // {
  //   category: false,
  //   name: 'Analysis',
  //   key: 'analysis',
  //   badge: 'New',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M13.0021 10.9128V3.01281C13.0021 2.41281 13.5021 1.91281 14.1021 2.01281C16.1021 2.21281 17.9021 3.11284 19.3021 4.61284C20.7021 6.01284 21.6021 7.91285 21.9021 9.81285C22.0021 10.4129 21.5021 10.9128 20.9021 10.9128H13.0021Z"
  //         fill="black"
  //       />
  //       <path
  //         opacity="0.3"
  //         d="M11.0021 13.7128V4.91283C11.0021 4.31283 10.5021 3.81283 9.90208 3.91283C5.40208 4.51283 1.90209 8.41284 2.00209 13.1128C2.10209 18.0128 6.40208 22.0128 11.3021 21.9128C13.1021 21.8128 14.7021 21.3128 16.0021 20.4128C16.5021 20.1128 16.6021 19.3128 16.1021 18.9128L11.0021 13.7128Z"
  //         fill="black"
  //       />
  //       <path
  //         opacity="0.3"
  //         d="M21.9021 14.0128C21.7021 15.6128 21.1021 17.1128 20.1021 18.4128C19.7021 18.9128 19.0021 18.9128 18.6021 18.5128L13.0021 12.9128H20.9021C21.5021 12.9128 22.0021 13.4128 21.9021 14.0128Z"
  //         fill="black"
  //       />
  //     </svg>
  //   ),
  //   url: '/analysis',
  //   premiumOnly: false,
  //   isOpen: false,
  //   childs: [
  //     {
  //       category: false,
  //       name: 'Stocks',
  //       key: 'stocks-analysis',
  //       url: '/analysis/stocks',
  //       premiumOnly: false,
  //     },
  //     // {
  //     //   category: false,
  //     //   name: 'Crypto',
  //     //   key: 'crypto-analysis',
  //     //   url: '/analysis/crypto',
  //     //   premiumOnly: false,
  //     // },
  //     // {
  //     //   category: false,
  //     //   name: 'Forex',
  //     //   key: 'forex-analysis',
  //     //   url: '/analysis/forex',
  //     //   premiumOnly: false,
  //     // },
  //   ],
  // },
  {
    category: false,
    name: 'Tools',
    key: 'tools',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H6C6.6 2 7 2.4 7 3V6C7 6.6 6.6 7 6 7Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M13 7H10C9.4 7 9 6.6 9 6V3C9 2.4 9.4 2 10 2H13C13.6 2 14 2.4 14 3V6C14 6.6 13.6 7 13 7ZM21 6V3C21 2.4 20.6 2 20 2H17C16.4 2 16 2.4 16 3V6C16 6.6 16.4 7 17 7H20C20.6 7 21 6.6 21 6ZM7 13V10C7 9.4 6.6 9 6 9H3C2.4 9 2 9.4 2 10V13C2 13.6 2.4 14 3 14H6C6.6 14 7 13.6 7 13ZM14 13V10C14 9.4 13.6 9 13 9H10C9.4 9 9 9.4 9 10V13C9 13.6 9.4 14 10 14H13C13.6 14 14 13.6 14 13ZM21 13V10C21 9.4 20.6 9 20 9H17C16.4 9 16 9.4 16 10V13C16 13.6 16.4 14 17 14H20C20.6 14 21 13.6 21 13ZM7 20V17C7 16.4 6.6 16 6 16H3C2.4 16 2 16.4 2 17V20C2 20.6 2.4 21 3 21H6C6.6 21 7 20.6 7 20ZM14 20V17C14 16.4 13.6 16 13 16H10C9.4 16 9 16.4 9 17V20C9 20.6 9.4 21 10 21H13C13.6 21 14 20.6 14 20ZM21 20V17C21 16.4 20.6 16 20 16H17C16.4 16 16 16.4 16 17V20C16 20.6 16.4 21 17 21H20C20.6 21 21 20.6 21 20Z"
          fill="black"
        />
      </svg>
    ),
    url: '/tools',
    premiumOnly: false,
    isOpen: false,
    childs: [
      {
        category: false,
        name: 'Simulator',
        key: 'tools-simulator',
        url: '/tools/simulator',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Calendar',
        key: 'tools-calendar',
        url: '/tools/calendar',
        premiumOnly: false,
      },
    ],
  },
  {
    category: false,
    name: 'Accounts',
    key: 'accounts',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
          fill="black"
        />
        <path
          d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
          fill="black"
        />
      </svg>
    ),
    url: '/accounts',
    premiumOnly: false,
  },
  {
    category: false,
    name: 'Trading Systems',
    key: 'ts',
    icon: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z"
          fill="black"
        />
        <path
          d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z"
          fill="black"
        />
      </svg>
    ),
    url: '/trading-systems',
    premiumOnly: false,
  },
  {
    category: false,
    name: 'Market News',
    key: 'marketNews',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z"
          fill="black"
        />
        <path d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z" fill="black" />
      </svg>
    ),
    url: '/market-news',
    premiumOnly: false,
    isOpen: false,
    badge: 'New',
    childs: [
      {
        category: false,
        name: 'Forex',
        key: 'forexMarketNews',
        url: '/market-news/forex',
        premiumOnly: false,
      },
    ],
  },
  // {
  //   category: false,
  //   name: 'Advanced Stats',
  //   key: 'as',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
  //         fill="black"
  //       />
  //       <path
  //         opacity="0.3"
  //         d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
  //         fill="black"
  //       />
  //     </svg>
  //   ),
  //   url: '/advanced-stats',
  //   premiumOnly: false,
  // },
  {
    category: false,
    name: 'Settings',
    key: 'settings',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
          fill="black"
        />
        <path
          d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
          fill="black"
        />
      </svg>
    ),
    url: '/settings',
    premiumOnly: false,
    isOpen: false,
    childs: [
      {
        category: false,
        name: 'General',
        key: 'general-settings',
        url: '/settings/general',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Change Password',
        key: 'change-password-settings',
        url: '/settings/password',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Subscription',
        key: 'subscription',
        url: '/settings/subscription',
        premiumOnly: false,
      },
      {
        category: false,
        name: 'Instruments',
        key: 'instruments-settings',
        url: '/settings/instruments',
        premiumOnly: false,
      },
      // {
      //   category: false,
      //   name: 'Tags',
      //   key: 'tags-settings',
      //   url: '/settings/tags',
      // },
      {
        category: false,
        name: 'Import Trades',
        key: 'import-trades',
        url: '/settings/import-trades',
        premiumOnly: true,
      },
      {
        category: false,
        name: 'My Imports',
        key: 'my-imports',
        url: '/settings/my-imports',
        premiumOnly: true,
      },
    ],
  },
]

export default SIDE_MENU
