const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  INIT_ACCOUNT: 'user/INIT_ACCOUNT',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  GENERAL_SETTINGS: 'user/GENERAL_SETTINGS',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  SUBSCRIPTION_INFO: 'user/SUBSCRIPTION_INFO',
  LOGOUT: 'user/LOGOUT',
}

export default actions
