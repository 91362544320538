/* eslint jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import axios from 'axios'
import { Button, Empty, message } from 'antd'

import { API_URL } from '../../../../../config/app'

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
}

// eslint-disable-next-line no-unused-vars
const UploadTradeImages = ({ state, updateState = (f) => f }) => {
  const removeImage = (index) => {
    const newImagesArray = state.uploadedImages
    const formData = new FormData()
    formData.append('image', newImagesArray[index])
    newImagesArray.splice(index, 1)
    updateState('uploadedImages', newImagesArray)
    axios
      .post(`${API_URL}/api/v1/images/remove`, formData, config)
      .then(() => {
        message.success('Images was removed')
      })
      .catch(() => {
        message.error('Something goes wrong, try again later...')
      })
  }

  const uploadImages = (event) => {
    message.loading({ content: 'Loading...', key: 'upload_trade_images', duration: 20 })
    const formData = new FormData()
    const { files } = event.target
    if (event.target.files[0]) {
      for (let i = 0; i < files.length; i += 1) {
        formData.append('images[]', files[i])
      }
    }
    axios
      .post(`${API_URL}/api/v1/images/upload`, formData, config)
      .then((res) => {
        const newImagesArray = state.uploadedImages
        res.data.images.forEach((item) => {
          newImagesArray.push(item)
        })
        updateState('uploadedImages', newImagesArray)
        message.success({ content: 'Success', key: 'upload_trade_images' })
      })
      .catch(() => {
        message.error({
          content: 'Something goes wrong. Try again later.',
          key: 'upload_trade_images',
        })
      })
  }

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="row">
          {state.uploadedImages[0] ? (
            state.uploadedImages.map((item, i) => {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={item}>
                  <img src={item} className="img-fluid" alt="" />
                  <div>
                    <Button
                      type="link"
                      className="border-0 danger-color mt-1"
                      onClick={() => removeImage(i)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="w-100 pt-3 pb-3 text-center">
              <Empty description="No uploaded images" />
            </div>
          )}
        </div>
      </div>
      <div className="col-12">
        <input
          type="file"
          name="images[]"
          accept="image/*"
          multiple
          onChange={(event) => uploadImages(event)}
        />
      </div>
    </div>
  )
}

export default UploadTradeImages
