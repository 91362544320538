import React from 'react'

import NotEnoughData from '../../messages/NotEnoughData'
import MixedBarChart from '../overview/MixedBarChart'
import HourlyReportTable from './HourlyReportTable'
import UnlockAllFeatures from '../../../system/Subscription/UnlockAllFeatures'

const HourlyReportComponent = ({ data, user, showTable = true }) => {
  if ((data.error && data.error === 'no-content') || data.success === false) {
    return <NotEnoughData />
  }

  return (
    <div className="row">
      <div className="col-12">
        <MixedBarChart
          data={data.data ? data.data : []}
          user={user}
          heightClass="m-h-500"
        />
        {showTable ? (
          <HourlyReportTable data={data.data ? data.data : []} user={user} />
        ) : (
          <UnlockAllFeatures title="Detailed report" compact />
        )}
      </div>
    </div>
  )
}

export default HourlyReportComponent
