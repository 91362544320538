/* eslint jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { InputNumber, DatePicker } from 'antd'

const TradeExit = ({ state, updateState = (f) => f }) => {
  return (
    <div className="trade-form__section">
      <div className="form-group">
        <label htmlFor="exitDate">Exit Date</label>
        <DatePicker
          defaultValue={state.exitDate}
          className="w-100"
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onOk={(value) => updateState('exitDate', value)}
          onChange={(date) => updateState('exitDate', date)}
          showNow={false}
        />
      </div>
      <div className="form-group">
        <label htmlFor="exitPrice">Exit Price</label>
        <InputNumber
          defaultValue={state.exitPrice}
          className="w-100"
          onChange={(value) => updateState('exitPrice', parseFloat(value))}
          decimalSeparator="."
          min={0}
          max={999999999999999}
        />
      </div>
      <div className="form-group">
        <label htmlFor="profitLoss">Profit/Loss</label>
        <InputNumber
          defaultValue={state.profitLoss}
          className="w-100"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => updateState('profitLoss', parseFloat(value))}
          min={-999999999999999}
          max={999999999999999}
          step={0.01}
        />
      </div>
      <div className="form-group">
        <label htmlFor="fees">Fees (optional)</label>
        <InputNumber
          defaultValue={state.fees}
          className="w-100"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => updateState('fees', parseFloat(value))}
          min={-999999999999999}
          max={999999999999999}
          step={0.01}
        />
      </div>
    </div>
  )
}

export default TradeExit
