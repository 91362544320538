import styles from './block-wrapper.module.scss'

const BlockWrapper = ({ children, title }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      <div>{children}</div>
    </div>
  )
}

export default BlockWrapper
