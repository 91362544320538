import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { YMInitializer } from 'react-yandex-metrika'

import MainLayout from './main'
// eslint-disable-next-line import/no-cycle
import LoginLayout from './login/index'
import PublicLayout from './public'

import { history } from '../index'
import { notification } from 'antd'

const mapStateToProps = ({ user }) => ({ user })

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

const IndexLayout = ({ user, children, location: { pathname } }) => {
  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/secured(?=\/|$)/i.test(pathname)) {
      return 'login'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const userInfo = user.user
  const isUserLoading = user.loading
  const { accessAllowed } = user
  const isAuthLayout = getLayout() === 'login'

  const BootstrappedLayout = () => {
    const getTheme = localStorage.getItem('trLayout')
    if (getTheme && getTheme === 'dark') {
      document.body.setAttribute('data-theme', getTheme)
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/secured/login" />
    }
    if (
      pathname !== '/secured/account/init' &&
      isUserAuthorized &&
      userInfo.is_init_account === 0
    ) {
      return <Redirect to="/secured/account/init" />
    }
    // review and fix memory leak
    if (
      pathname === '/secured/account/init' &&
      isUserAuthorized &&
      userInfo.is_init_account === 1
    ) {
      return <Redirect to="/" />
    }
    if (!isAuthLayout && !accessAllowed && pathname !== '/settings/subscription') {
      history.push('/settings/subscription')
      notification.warn({
        message: 'Your 7-day trial has ended',
        description:
          "But it's not too late to take the next step. Upgrade to a paid plan to keep using Tradiry Trading Journal.",
      })
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <>
      <Helmet titleTemplate="%s | Tradiry" title="Tradiry" />
      <YMInitializer
        accounts={[69942655]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true,
        }}
      />
      {BootstrappedLayout()}
    </>
  )
}

export default withRouter(connect(mapStateToProps)(IndexLayout))
