import React from 'react'
import { Button } from 'antd'

import Card from '../../../../card'

import { history } from '../../../../../../index'

import styles from './styles.module.scss'

const FullwidthSubscribeWidget = () => {
  return (
    <Card wrapperClass="p-0" headerClass="p-0" bodyClass="p-0">
      <div className={styles.fullWidthSubscribeWidget}>
        <div className="row align-items-center">
          <div className="col-12 col-lg-10 mb-3 mb-lg-0 text-center text-lg-left">
            <h3>Upgrade Your Plan to Unlock all Features</h3>
          </div>
          <div className="col-12 col-lg-2 text-center text-lg-right">
            <Button
              className="btn-success"
              onClick={() => {
                history.push('/settings/subscription')
              }}
            >
              Upgrade Plan
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default FullwidthSubscribeWidget
