import React, { useEffect, useState } from 'react'
import axiosRequest from '../../../../config/axios'
import axiosError from '../../../../helpers/axios-error'
import NewsSlider from './slider'
import { Skeleton } from 'antd'
import NewsGrid from './grid'

const AnalysisNewsList = ({
  view = 'slider',
  symbol = null,
  limit = 24,
  title = null,
  subtitle = null,
}) => {
  const [loading, setLoading] = useState(true)
  const [news, setNews] = useState([])

  useEffect(() => {
    setLoading(true)
    axiosRequest(
      `/api/v1/analysis/stock/news?limit=${limit}${symbol ? `&symbol=${symbol}` : ''}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        setNews(res.data.news || [])
      })
      .catch((e) => axiosError(e))
      .finally(() => setLoading(false))
  }, [symbol, limit])

  return (
    <div>
      {loading ? (
        <Skeleton active className="mt-5" />
      ) : (
        <div>
          {view === 'slider' && (
            <NewsSlider news={news} title={title} subtitle={subtitle} />
          )}
          {view === 'grid' && <NewsGrid news={news} />}
        </div>
      )}
    </div>
  )
}

export default AnalysisNewsList
