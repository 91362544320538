import React from 'react'

const InstrumentsReportTable = ({ data, user }) => {
  return (
    <div className="card card__stat m-b-30">
      <div className="card-content card-content__no-title">
        <div className="card-body">
          <div className="table-responsive tr-table-responsive">
            <table className="table tr-table table__xs dashboard-main-stat-table">
              <thead>
                <tr>
                  <th>Instrument</th>
                  <th>Win rate</th>
                  <th>Results</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((value) => {
                  return (
                    <tr key={value.id}>
                      <td>
                        <p className="mb-1">
                          <strong>{value.instrument}</strong>
                        </p>
                        <p className="mb-0">
                          <small>Total trades: {value.data.totalTrades}</small>
                        </p>
                      </td>
                      <td>
                        <p className="mb-1">
                          {parseFloat((value.data.winTrades * 100) / value.data.totalTrades).toFixed(0)}%
                        </p>
                        <p className="mb-0">
                          <small>
                            Win: {value.data.winTrades} | Loss: {value.data.lossTrades}
                          </small>
                        </p>
                      </td>
                      <td>
                        {parseFloat(value.data.winReturn) !== 0.0 && (
                          <p className="mb-1">
                            <strong>Profit:</strong> {parseFloat(value.data.winReturn).toFixed(2)}{' '}
                            {user.get_currency && user.get_currency.short_name}{' '}
                            <small>({parseFloat(value.data.winReturnPercents).toFixed(2)}%)</small>
                          </p>
                        )}
                        {parseFloat(value.data.lossReturn) !== 0.0 && (
                          <p className="mb-1">
                            <strong>Loss:</strong> {parseFloat(value.data.lossReturn).toFixed(2)}{' '}
                            {user.get_currency && user.get_currency.short_name}{' '}
                            <small>({parseFloat(value.data.lossReturnPercents).toFixed(2)}%)</small>
                          </p>
                        )}
                        {parseFloat(value.data.returnMoney) !== 0.0 && (
                          <p className="mb-1">
                            <u>
                              <strong>Total:</strong> {parseFloat(value.data.returnMoney).toFixed(2)}{' '}
                              {user.get_currency && user.get_currency.short_name}{' '}
                              <small>({parseFloat(value.data.returnPercents).toFixed(2)}%)</small>
                            </u>
                          </p>
                        )}
                      </td>
                      <td>
                        <table className="table tr-table table__xs dashboard-main-stat-table table__fixed-cols-3">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Long</th>
                              <th>Short</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Trades</td>
                              <td>{value.data.long.totalTrades}</td>
                              <td>{value.data.short.totalTrades}</td>
                            </tr>
                            <tr>
                              <td>Won</td>
                              <td>
                                {value.data.long.totalTrades !== 0 ? (
                                  <>
                                    {parseFloat(value.data.long.winReturn).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>
                                      ({parseFloat(value.data.long.winReturnPercents).toFixed(1)}
                                      %)
                                    </small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {value.data.short.totalTrades !== 0 ? (
                                  <>
                                    {parseFloat(value.data.short.winReturn).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>
                                      ({parseFloat(value.data.short.winReturnPercents).toFixed(1)}
                                      %)
                                    </small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Loss</td>
                              <td>
                                {value.data.long.totalTrades !== 0 ? (
                                  <>
                                    {parseFloat(value.data.long.lossReturn).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>
                                      ({parseFloat(value.data.long.lossReturnPercents).toFixed(1)}
                                      %)
                                    </small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {value.data.short.totalTrades !== 0 ? (
                                  <>
                                    {parseFloat(value.data.short.lossReturn).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>
                                      ({parseFloat(value.data.short.lossReturnPercents).toFixed(1)}
                                      %)
                                    </small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>
                                  <u>Total</u>
                                </strong>
                              </td>
                              <td>
                                {value.data.long.totalTrades !== 0 ? (
                                  <>
                                    <strong>
                                      <u>
                                        {parseFloat(value.data.long.returnMoney).toFixed(2)}{' '}
                                        {user.get_currency && user.get_currency.short_name}{' '}
                                        <small>
                                          ({parseFloat(value.data.long.returnPercents).toFixed(1)}
                                          %)
                                        </small>
                                      </u>
                                    </strong>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {value.data.short.totalTrades !== 0 ? (
                                  <>
                                    <strong>
                                      <u>
                                        {parseFloat(value.data.short.returnMoney).toFixed(2)}{' '}
                                        {user.get_currency && user.get_currency.short_name}{' '}
                                        <small>
                                          ({parseFloat(value.data.short.returnPercents).toFixed(1)}
                                          %)
                                        </small>
                                      </u>
                                    </strong>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstrumentsReportTable
