import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'

import styles from './trade-details.module.scss'
import BlockWrapper from './BlockWrapper'
import Executions from './Executions'

const TradeDetails = ({ trade, user }) => {
  const data = [
    {
      name: 'Trade Details',
      items: [
        {
          label: 'Status',
          value: trade.is_open ? (
            <span className="badge badge-warning">Open</span>
          ) : (
            <>
              {trade.profit_loss >= 0 ? (
                <span className="badge badge-success">Win</span>
              ) : (
                <span className="badge badge-danger">Loss</span>
              )}
            </>
          ),
        },
        {
          label: 'Action',
          value:
            trade.action === 0 ? (
              <span className="danger-color">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M13.0001 16.1716L18.3641 10.8076L19.7783 12.2218L12.0001 20L4.22192 12.2218L5.63614 10.8076L11.0001 16.1716V4H13.0001V16.1716Z" />
                </svg>{' '}
                Sell
              </span>
            ) : (
              <span className="success-color">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M13.0001 7.82843V20H11.0001V7.82843L5.63614 13.1924L4.22192 11.7782L12.0001 4L19.7783 11.7782L18.3641 13.1924L13.0001 7.82843Z" />
                </svg>{' '}
                Buy
              </span>
            ),
        },
        {
          label: 'Instrument',
          value:
            trade.get_instrument_reference && trade.get_instrument_reference.name
              ? trade.get_instrument_reference.symbol
              : trade.get_instrument.name,
        },
        { label: 'Account', value: trade.get_account.name },
        { label: 'Trading System', value: trade.get_trading_system.name },
        { label: 'Time Frame', value: trade.get_time_frame.name },
        {
          label: 'Entry Date',
          value: moment(trade.entry_date).format(
            `${user.date_format} ${user.time_format}`,
          ),
        },
        { label: 'Entry Price', value: trade.entry_price },
        {
          label: 'Units/Lots',
          helpText:
            '0.01 lot size in forex or micro lot equals 1.000 units of any given currency.',
          value: parseFloat(trade.qty),
        },
        {
          label: 'Take Profit',
          value:
            trade.take_profit && parseFloat(trade.take_profit) !== 0
              ? trade.take_profit
              : 'N/A',
        },
        {
          label: 'Stop Loss',
          value:
            trade.stop_loss && parseFloat(trade.stop_loss) !== 0
              ? trade.stop_loss
              : 'N/A',
        },
        {
          label: 'Highest Price',
          value:
            trade.highest_price && parseFloat(trade.highest_price) !== 0
              ? trade.highest_price
              : 'N/A',
        },
        {
          label: 'Lowest Price',
          value:
            trade.lowest_price && parseFloat(trade.lowest_price) !== 0
              ? trade.lowest_price
              : 'N/A',
        },
        {
          label: 'Exit Date',
          value: moment(trade.exit_date).format(
            `${user.date_format} ${user.time_format}`,
          ),
        },
        { label: 'Exit Price', value: trade.exit_price },
        {
          label: 'Profit/Loss',
          value: (
            <span className={trade.profit_loss >= 0 ? 'success-color' : 'danger-color'}>
              {trade.profit_loss} {user.get_currency && user.get_currency.short_name}
            </span>
          ),
        },
        {
          label: 'Fees',
          value: (
            <span>
              {trade.fees} {user.get_currency && user.get_currency.short_name}
            </span>
          ),
        },
        {
          label: 'Result, %',
          value: (
            <span
              className={
                trade.profit_loss_percents >= 0 ? 'success-color' : 'danger-color'
              }
            >
              {parseFloat(trade.profit_loss_percents).toFixed(2)}%
            </span>
          ),
        },
        {
          label: 'Potential Risk',
          value: <span>{parseFloat(trade.risk_percents).toFixed(2)}%</span>,
        },
        {
          label: 'RRR-planned',
          value:
            trade.rrr_planned && parseFloat(trade.rrr_planned) !== 0
              ? parseFloat(trade.rrr_planned)
              : 'N/A',
        },
        {
          label: 'R-multiple',
          value:
            trade.r_multiple && parseFloat(trade.r_multiple) !== 0
              ? parseFloat(trade.r_multiple)
              : 'N/A',
        },
        {
          label: 'MAE',
          value: trade.mae ? (
            <span>
              {trade.mae} {user.get_currency && user.get_currency.short_name}
            </span>
          ) : (
            'N/A'
          ),
        },
        {
          label: 'MFE',
          value: trade.mfa ? (
            <span>
              {trade.mfa} {user.get_currency && user.get_currency.short_name}
            </span>
          ) : (
            'N/A'
          ),
        },
      ],
    },
  ]

  return (
    <>
      <div className={styles.tradeDetails}>
        {data.map((dataItem) => (
          <BlockWrapper key={dataItem.name} title={dataItem.name}>
            <div className={styles.details}>
              <div className={styles.detailsItems}>
                <div className="row">
                  {dataItem.items.map((item, index) => (
                    <div
                      key={`${item.label}${item.value}${index}`}
                      className={item.className || 'col-6 col-md-6 col-lg-4 col-xl-3'}
                    >
                      <div className={styles.item}>
                        <div className="d-flex justify-content-strt align-items-center">
                          <div>
                            <p>{item.label}</p>
                          </div>
                          {item.helpText && (
                            <Tooltip
                              placement="top"
                              trigger="hover"
                              title={item.helpText}
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z" />
                                </svg>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                        {item.asText ? (
                          <div className={styles.asText}>{item.value}</div>
                        ) : (
                          <h4>{item.value}</h4>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </BlockWrapper>
        ))}
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          {trade.get_executions && trade.get_executions.length > 0 ? (
            <BlockWrapper title="History">
              <Executions user={user} executions={trade.get_executions} />
            </BlockWrapper>
          ) : null}
        </div>
        {trade.description && (
          <div className="col-12 col-lg-6">
            <BlockWrapper title="Additional Information">
              <div className={styles.details}>
                <div className={styles.detailsItems}>
                  <div dangerouslySetInnerHTML={{ __html: trade.description }}></div>
                </div>
              </div>
            </BlockWrapper>
          </div>
        )}
      </div>
    </>
  )
}

export default TradeDetails
