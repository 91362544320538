import React from 'react'
import { RiQuestionLine } from 'react-icons/ri'
import { Popover } from 'antd'

import Card from '../../../card'

import styles from './head-stats.module.scss'

const HeadStat = ({ title = '', helpText = null, value = 0, extraData = null }) => {
  return (
    <Card wrapperClass="mb-0">
      <div className={styles.headStats}>
        <div className={styles.title}>
          {title}{' '}
          {helpText && (
            <Popover trigger="hover" content={helpText} style={{ maxWidth: '240px' }}>
              <RiQuestionLine />
            </Popover>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className={styles.content}>{value}</div>
        </div>
      </div>
    </Card>
  )
}

export default HeadStat
