import React, { createContext, useEffect, useState } from 'react'

const ThemeContext = createContext({})

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    const getTheme = localStorage.getItem('trLayout')
    if (getTheme) {
      setTheme(getTheme)
    } else {
      setTheme('light')
    }
  }, [])

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
