import React from 'react'

import Card from '../../../card'
import GeneralStatsItem from './partials/item'
import GeneralInsights from '../../tradiry-ai/GeneralInsights'

import longNumberToShort from '../../../../../helpers/longNumberToShort'

import styles from './styles.module.scss'

const GeneralStats = ({ stat, user }) => {
  const userCurrency =
    user.get_currency && user.get_currency.short_name ? user.get_currency.short_name : ''

  const renderTHead = () => {
    return (
      <thead>
        <tr>
          <th>Name</th>
          <th>Result</th>
          {/*<th>24h %</th>*/}
          {/*<th>7d %</th>*/}
          {/*<th>30d %</th>*/}
          {/*<th>Last 7 days</th>*/}
        </tr>
      </thead>
    )
  }

  return (
    <div className={styles.resultsWidget}>
      <div className="match-height row">
        <div className="col-12 col-lg-4">
          <Card>
            <div className={styles.responsive}>
              <table>
                {renderTHead()}
                <tbody>
                  <GeneralStatsItem
                    name="Trades"
                    subTitle="Total trades"
                    result={stat.marketsStat.totalTrades}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Win Rate"
                    subTitle=" "
                    result={`${new Intl.NumberFormat().format(
                      stat.winLoseTradesComparison.winPercents,
                    )}%`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="ARPT, $"
                    subTitle={`Avg. return per trade in ${userCurrency}`}
                    result={`${longNumberToShort(
                      stat.stats.avgReturnPerTrade,
                    )} ${userCurrency}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="ARPT, %"
                    subTitle={`Avg. return per trade in %`}
                    result={`${new Intl.NumberFormat().format(
                      stat.stats.avgReturnPercentsPerTrade,
                    )} %`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Avg. win, $"
                    subTitle="Avg win per trade"
                    result={`${longNumberToShort(
                      stat.stats.avgWinPerTrade,
                    )} ${userCurrency}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Avg. loss, $"
                    subTitle="Avg loss per trade"
                    result={`${longNumberToShort(
                      stat.stats.avgLossPerTrade,
                    )} ${userCurrency}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Best trade, $"
                    subTitle=""
                    result={`${longNumberToShort(stat.stats.biggestWin)} ${userCurrency}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Worst trade, $"
                    subTitle=""
                    result={`${longNumberToShort(
                      stat.stats.biggestLoss,
                    )} ${userCurrency}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                </tbody>
              </table>
            </div>
          </Card>
        </div>
        <div className="col-12 col-lg-4">
          <Card>
            <div className={styles.responsive}>
              <table>
                {renderTHead()}
                <tbody>
                  <GeneralStatsItem
                    name="Avg. units per trade"
                    subTitle=" "
                    result={longNumberToShort(stat.stats.avgUnitsPerTrade)}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Avg. trade length"
                    subTitle=" "
                    result={`${
                      stat.stats.avgTimePerTrade.d !== 0
                        ? `${stat.stats.avgTimePerTrade.d}d`
                        : ''
                    } ${
                      stat.stats.avgTimePerTrade.h !== 0
                        ? `${stat.stats.avgTimePerTrade.h}h`
                        : ''
                    } ${
                      stat.stats.avgTimePerTrade.m !== 0
                        ? `${stat.stats.avgTimePerTrade.m}m`
                        : ''
                    } ${
                      stat.stats.avgTimePerTrade.s !== 0
                        ? `${stat.stats.avgTimePerTrade.s}s`
                        : ''
                    }`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Longs won"
                    subTitle={`${new Intl.NumberFormat().format(
                      stat.stats.tradeAction.buyWinTrades,
                    )} of ${new Intl.NumberFormat().format(
                      stat.stats.tradeAction.buyTrades,
                    )}`}
                    result={`${new Intl.NumberFormat().format(
                      stat.stats.tradeAction.buyWinPercents,
                    )}%`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Shorts won"
                    subTitle={`${new Intl.NumberFormat().format(
                      stat.stats.tradeAction.sellWinTrades,
                    )} of ${new Intl.NumberFormat().format(
                      stat.stats.tradeAction.sellTrades,
                    )}`}
                    result={`${new Intl.NumberFormat().format(
                      stat.stats.tradeAction.sellWinPercents,
                    )}%`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Profit factor"
                    subTitle=" "
                    result={`${new Intl.NumberFormat().format(stat.stats.profitFactor)}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="Expectancy"
                    subTitle={`Expected to make ${longNumberToShort(
                      stat.stats.expectancy,
                    )} ${userCurrency} <br/> from each trade`}
                    result={`${longNumberToShort(stat.stats.expectancy)} ${userCurrency}`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="AHPR"
                    subTitle="Avg. holding period returns"
                    result={`${new Intl.NumberFormat().format(stat.stats.ahpr)}%`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                  <GeneralStatsItem
                    name="GHPR"
                    subTitle="Geometric holding period returns"
                    result={`${new Intl.NumberFormat().format(stat.stats.ghpr)}%`}
                    diff7=" "
                    diff24="Soon"
                    diff30=" "
                  />
                </tbody>
              </table>
            </div>
          </Card>
        </div>
        <div className="col-12 col-lg-4">
          <GeneralInsights />
        </div>
      </div>
    </div>
  )
}

export default GeneralStats
