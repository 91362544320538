const actions = {
  SET_STATE: 'tags/SET_STATE',
  LIST: 'tags/LIST',
  CREATE: 'tags/CREATE',
  UPDATE: 'tags/UPDATE',
  DELETE: 'tags/DELETE',
  ASSIGN_TO_TRADE: 'tags/ASSIGN_TO_TRADE',
  REMOVE_FROM_TRADE: 'tags/REMOVE_FROM_TRADE',
}

export default actions
