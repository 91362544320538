import React, { useState } from 'react'
import { Select } from 'antd'
import { Line } from 'react-chartjs-2'

import Card from '../../../ui-2/card'

import longNumberToShort from '../../../../helpers/longNumberToShort'
import colors from '../../../../config/apexcharts/colors'

const AccountPerformanceChart = ({ trades, user }) => {
  const [viewType, handleViewType] = useState('profit_loss_percents')

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Return',
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.primaryColor,
        borderColor: colors.primaryColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: [],
      },
    ],
  }

  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              if (viewType === 'profit_loss_percents') {
                return `${longNumberToShort(value, '0.0a')}%`
              }
              return `${longNumberToShort(value, '0.0a')} ${user.get_currency.short_name}`
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const renderChart = () => {
    if (trades.length > 0) {
      let labelNum = 0
      let returnValue = 0
      data.labels.push(labelNum)
      data.datasets[0].data.push(returnValue)
      labelNum += 1
      trades.forEach((trade) => {
        if (viewType === 'profit_loss_percents') {
          returnValue += parseFloat(trade.profit_loss_percents)
        } else {
          returnValue += parseFloat(trade.profit_loss)
        }
        data.labels.push(labelNum)
        data.datasets[0].data.push(returnValue)
        labelNum += 1
      })
    }
    return <Line data={data} options={options} />
  }

  if (trades.length === 0) {
    return null
  }

  return (
    <Card wrapperClass="m-b-30">
      <div className="mb-4 d-flex justify-content-end align-items-center">
        <div className="mr-2">Performance:</div>
        <div>
          <Select defaultValue={viewType} onChange={(value) => handleViewType(value)}>
            <Select.Option value="profit_loss">
              Profit/Loss ({user.get_currency && user.get_currency.short_name})
            </Select.Option>
            <Select.Option value="profit_loss_percents">Profit/Loss(%)</Select.Option>
          </Select>
        </div>
      </div>
      <div className="h-400 limit-height-400">{renderChart()}</div>
    </Card>
  )
}

export default AccountPerformanceChart
