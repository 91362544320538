import React, { useState } from 'react'
import ym from 'react-yandex-metrika'
import axios from 'axios'
import { Form, Input, Button, message, notification } from 'antd'

import { API_URL } from '../../../../config/app'
import getAPITokenFromLocalStorage from '../../../../helpers/getAPITokenFromLocalStorage'
import Card from '../../../ui-2/card'

const ValidateEmail = ({ user }) => {
  const [sendCodeLoading, handleSendCodeLoading] = useState(false)
  const [validateLoading, handleValidateLoading] = useState(false)

  if (user.email_verified_at !== null) {
    return null
  }

  const sendVerificationCode = () => {
    handleSendCodeLoading(true)
    axios
      .get(
        `${API_URL}/api/v1/user/send-email-verification-code`,
        getAPITokenFromLocalStorage(true),
      )
      .then((res) => {
        if (res.data.success) {
          handleSendCodeLoading(false)
          notification.success({
            message: 'Success',
            description: 'Verification code was sent to your email address.',
          })
        }
      })
      .catch(() => {
        message.error({
          content: 'Something goes wrong, try again later.',
          key: 'validate_email',
        })
      })
  }

  const onFinish = (values) => {
    handleValidateLoading(true)
    axios
      .post(
        `${API_URL}/api/v1/user/validate-email`,
        { code: values.code },
        getAPITokenFromLocalStorage(true),
      )
      .then((res) => {
        if (res.data.success) {
          handleValidateLoading(false)
          ym('reachGoal', 'userValidateEmail')
          message.success({
            content:
              'Your email was successfully verified. Page will reloaded in 5 seconds.',
            key: 'validate_email',
            duration: 10,
          })
          // eslint-disable-next-line func-names
          setTimeout(function () {
            window.location.reload(false)
          }, 5000)
        }
      })
      .catch(() => {
        message.error({
          content: 'Something goes wrong, try again later.',
          key: 'validate_email',
        })
      })
  }

  const onFinishFailed = () => {
    message.error({
      content: 'Verification code is missing or incorrect',
      key: 'validate_email',
    })
  }

  return (
    <Card>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div className="row">
          <div className="col-12">
            <h4 className="mb-3">Email Verification</h4>
            <ol className="pl-4">
              <li>
                Click <strong>Send Code</strong> button to send verification code to your
                email
              </li>
              <li>Check your email (don&rsquo;t forget to check spam)</li>
              <li>
                Input verification code here and press <strong>Validate Email</strong>{' '}
                button
              </li>
            </ol>
          </div>
          <div className="col-8">
            <Form.Item
              name="code"
              rules={[{ required: true, message: 'Please input verification code!' }]}
            >
              <Input placeholder="Verification code" />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item>
              <Button
                type="primary"
                onClick={() => sendVerificationCode()}
                className="w-100"
                loading={sendCodeLoading}
              >
                Send Code
              </Button>
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item className="mb-0">
              <Button
                type="primary"
                className="w-100"
                htmlType="submit"
                loading={validateLoading}
              >
                Validate Email
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Card>
  )
}

export default ValidateEmail
