import React from 'react'
import { Modal, Form, Input, Button, message } from 'antd'

import actions from '../../../../redux/instrument/actions'

const AddInstrumentModal = ({ dispatch, isVisible, handleVisibility }) => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    message.loading({ content: 'Please, wait...', key: 'create_instrument' })
    dispatch({
      type: actions.CREATE,
      payload: {
        name: values.name,
      },
    })
    handleVisibility(false)
    form.resetFields()
  }

  const onFinishFailed = () => {
    message.error({ content: 'Something goes wrong. Check the fields and try again please.', key: 'create_instrument' })
  }

  return (
    <Modal
      title="Add new instrument"
      visible={isVisible}
      footer={null}
      onCancel={() => handleVisibility(false)}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input instrument name',
            },
          ]}
        >
          <Input placeholder="Input instrument name" autoComplete="off" />
        </Form.Item>
        <Form.Item className="mb-0 text-right">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddInstrumentModal
