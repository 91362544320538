import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'

import axiosRequest from '../../../../../config/axios'
import axiosError from '../../../../../helpers/axios-error'

import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import ListItem from './ListItem'

const SimpleStocksList = ({ title }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    axiosRequest('/api/v1/analysis/stock/top-50', 'get', null, true)
      .then((res) => {
        setData(res.data.stocks || [])
      })
      .catch((e) => axiosError(e))
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className={styles.SimpleStocksList}>
          <h2 className={styles.Title}>{title}</h2>
          <div className={styles.ListWrapper}>
            <div className={styles.Header}>
              <div className="row">
                <div className="col-5 col-md-4 col-xl-4" data-label="true">
                  Name
                </div>
                <div className="col-2 col-xl-1" data-label="true">
                  Price
                </div>
                <div
                  className="col-2 col-xl-1 d-none d-md-flex d-xl-flex"
                  data-label="true"
                >
                  Chg, %
                </div>
                <div className="col-2 col-xl-1 d-none d-xl-flex" data-label="true">
                  Chg
                </div>
                <div className="col-2 col-xl-1" data-label="true">
                  Volume
                </div>
                <div className="col-2 col-xl-1" data-label="true">
                  Mkt. Cap
                </div>
                <div className="col-2 col-xl-1 d-none d-xl-flex" data-label="true">
                  P/E
                </div>
                <div className="col-2 col-xl-1 d-none d-xl-flex" data-label="true">
                  EPS
                </div>
                <div className="col-2 col-xl-1 d-none d-xl-flex" data-label="true">
                  Employees
                </div>
              </div>
            </div>
            <div className={styles.Content}>
              {data.map((item) => (
                <ListItem data={item} key={item._id} />
              ))}
            </div>
          </div>
          <div className={styles.ExploreAll}>
            <Link to="/analysis/stocks">
              <svg
                width="7"
                height="9"
                viewBox="0 0 7 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.52344 0L0.46875 1.05469L3.87891 4.5L0.46875 7.94531L1.52344 9L6.02344 4.5L1.52344 0Z"
                  fill="black"
                />
              </svg>
              Explore All Stocks
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default SimpleStocksList
