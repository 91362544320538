import React from 'react'

// eslint-disable-next-line no-unused-vars
const TagsList = ({ dispatch, tags }) => {
  return (
    <>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi debitis deleniti distinctio enim facere harum
        inventore mollitia pariatur repudiandae sit temporibus unde, vel vitae? Adipisci culpa dicta dignissimos hic
        non!
      </p>
    </>
  )
}

export default TagsList
