import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { IoChevronDownOutline } from 'react-icons/io5'

import styles from './style.module.scss'

import SIDE_MENU from '../../../config/data/side-menu'

// eslint-disable-next-line no-unused-vars
const Sidebar = () => {
  const { pathname } = useLocation()
  const [menu, setMenu] = useState(SIDE_MENU)
  const [logoTheme, setLogoTheme] = useState(localStorage.getItem('trLayout') || 'light')

  useEffect(() => {
    const getLayout = localStorage.getItem('trLayout')
    if (getLayout && getLayout === 'dark') {
      setLogoTheme('dark')
    } else {
      setLogoTheme('light')
    }
  }, [])

  const expandMenu = (e, menuItem) => {
    if (menuItem.childs && menuItem.childs.length > 0) {
      e.preventDefault()
      const findMenuIndex = _.findIndex(menu, (data) => data.key === menuItem.key)
      if (findMenuIndex) {
        const newMenu = menu
        newMenu[findMenuIndex].isOpen = !menuItem.isOpen
        setMenu([...newMenu])
      }
    }
  }

  const generateMenu = () => {
    return (
      <>
        {menu.map((menuFirstLevel) => (
          <li key={menuFirstLevel.key}>
            <Link
              data-open={menuFirstLevel.isOpen ? 'yes' : 'no'}
              to={menuFirstLevel.url}
              onClick={(e) => expandMenu(e, menuFirstLevel)}
              className={
                pathname === menuFirstLevel.url ? styles.activeMenuItem : 'menu-item'
              }
            >
              <div className={styles.menuItemIcon}>{menuFirstLevel.icon}</div>
              <div className={styles.menuItemName}>
                {menuFirstLevel.name}{' '}
                {menuFirstLevel.badge && <sup>{menuFirstLevel.badge}</sup>}
              </div>
              {menuFirstLevel.childs && (
                <div className={styles.menuItemExpandIcon}>
                  <IoChevronDownOutline />
                </div>
              )}
            </Link>
            {menuFirstLevel.childs && menuFirstLevel.childs.length > 0 ? (
              <ul
                className={`${styles.trSideSubMenu} ${
                  menuFirstLevel.isOpen ? styles.trSideSubMenuOpen : 'd-noned'
                }`}
              >
                {menuFirstLevel.childs.map((secondLevel) => (
                  <li key={secondLevel.key}>
                    <Link
                      to={secondLevel.url}
                      className={
                        pathname === secondLevel.url ? styles.activeMenuItem : 'menu-item'
                      }
                    >
                      <div className={styles.menuItemName}>{secondLevel.name}</div>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
        <li>
          <a
            href="https://t.me/TradiryTradingJournal"
            target="_blank"
            rel="noreferrer nofollow noopener"
          >
            <div className={styles.menuItemIcon}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M16 0.200012H4C1.8 0.200012 0 2.00001 0 4.20001V16.2C0 18.4 1.8 20.2 4 20.2H16C18.2 20.2 20 18.4 20 16.2V4.20001C20 2.00001 18.2 0.200012 16 0.200012ZM15 10.2C15 10.9 14.8 11.6 14.6 12.2H18V16.2C18 17.3 17.1 18.2 16 18.2H12V14.8C11.4 15.1 10.7 15.2 10 15.2C9.3 15.2 8.6 15 8 14.8V18.2H4C2.9 18.2 2 17.3 2 16.2V12.2H5.4C5.1 11.6 5 10.9 5 10.2C5 9.50001 5.2 8.80001 5.4 8.20001H2V4.20001C2 3.10001 2.9 2.20001 4 2.20001H8V5.60001C8.6 5.30001 9.3 5.20001 10 5.20001C10.7 5.20001 11.4 5.40001 12 5.60001V2.20001H16C17.1 2.20001 18 3.10001 18 4.20001V8.20001H14.6C14.8 8.80001 15 9.50001 15 10.2Z"
                  fill="black"
                />
                <path
                  d="M12 1.40002C15.4 2.20002 18 4.80003 18.8 8.20003H14.6C14.1 7.00003 13.2 6.10003 12 5.60003V1.40002ZM5.40001 8.20003C5.90001 7.00003 6.80001 6.10003 8.00001 5.60003V1.40002C4.60001 2.20002 2.00001 4.80003 1.20001 8.20003H5.40001ZM14.6 12.2C14.1 13.4 13.2 14.3 12 14.8V19C15.4 18.2 18 15.6 18.8 12.2H14.6ZM8.00001 14.8C6.80001 14.3 5.90001 13.4 5.40001 12.2H1.20001C2.00001 15.6 4.60001 18.2 8.00001 19V14.8Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className={styles.menuItemName}>Support</div>
          </a>
        </li>
      </>
    )
  }

  return (
    <div className={styles.trSidebar}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={styles.trSidebarLogo}>
        {logoTheme === 'light' ? (
          <svg
            width="600"
            height="600"
            viewBox="0 0 600 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_213_2)">
              <rect width="600" height="600" rx="61" fill="#007BE8" />
              <path
                d="M61 129.5H58.5V132V169.532V172.032H61H197.955V468V470.5H200.455H238H240.507L240.5 467.993L239.606 131.993L239.599 129.5H237.106H61Z"
                fill="white"
                stroke="white"
                strokeWidth="5"
              />
              <path
                d="M268 129.5H265.5V132V167.745V170.245H268H366.287C383.084 170.245 394.843 173.14 402.417 179.59C409.895 185.956 413.824 196.258 413.824 212.426V239.234C413.824 254.16 410.74 264.232 403.691 270.676C396.579 277.18 384.866 280.521 366.287 280.521H366.264L366.242 280.522L267.955 282.309L265.5 282.354V284.809V314.298V315.299L266.191 316.023L412.728 469.725L413.466 470.5H414.537H461H466.77L462.824 466.29L329.472 324.033V322.16H352.884C390.502 322.16 415.586 316.61 431.132 303.137C446.798 289.56 452.171 268.497 451.884 239.222V205.277C451.884 178.495 444.839 159.335 430.226 146.959C415.706 134.663 394.209 129.5 366.287 129.5H268Z"
                fill="white"
                stroke="white"
                strokeWidth="5"
              />
              <circle cx="514" cy="450" r="23" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_213_2">
                <rect width="600" height="600" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="600"
            height="600"
            viewBox="0 0 600 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_2)">
              <rect width="600" height="600" rx="61" fill="black" />
              <path
                d="M239.606 131.993L239.599 129.5H237.106H61H58.5V132V169.532V172.032H61H197.955V468V470.5H200.455H238H240.507L240.5 467.993L239.606 131.993Z"
                fill="white"
                stroke="white"
                strokeWidth="5"
              />
              <path
                d="M268 129.5H265.5V132V167.745V170.245H268H366.287C383.084 170.245 394.843 173.14 402.417 179.59C409.895 185.956 413.824 196.258 413.824 212.426V239.234C413.824 254.16 410.74 264.232 403.691 270.676C396.579 277.18 384.866 280.521 366.287 280.521H366.264L366.242 280.522L267.955 282.309L265.5 282.354V284.809V314.298V315.299L266.191 316.023L412.728 469.725L413.466 470.5H414.537H461H466.77L462.824 466.29L329.472 324.033V322.16H352.884C390.502 322.16 415.586 316.61 431.132 303.137C446.798 289.56 452.171 268.497 451.884 239.222V205.277C451.884 178.495 444.839 159.335 430.226 146.959C415.706 134.663 394.209 129.5 366.287 129.5H268Z"
                fill="white"
                stroke="white"
                strokeWidth="5"
              />
              <circle cx="514" cy="450" r="23" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect width="600" height="600" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
      <div className="h-100 overflow-hidden pb-5">
        <PerfectScrollbar>
          <ul className={styles.trSideMenu}>{generateMenu()}</ul>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default Sidebar
