import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { Modal, message } from 'antd'

import { QUILL_FORMATS, QUILL_MODULES } from '../../../../../config/quill-config'

import actions from '../../../../../redux/trade/actions'

const EditNoteModal = ({ dispatch, note, isModalVisible, handleModalVisibility }) => {
  const [noteText, handleNote] = useState('')

  const onOkClick = () => {
    message.loading({ content: 'Please, wait...', key: 'update_trade_note', duration: 30 })
    if (noteText === '') {
      handleNote('')
      handleModalVisibility(false)
      message.success({ content: 'Success', key: 'update_trade_note' })
      return false
    }
    dispatch({
      type: actions.UPDATE_NOTE,
      payload: {
        noteId: note.id,
        trade: note.trade,
        note: noteText === '' ? note.note : noteText,
      },
    })
    handleNote('')
    handleModalVisibility(false)
    return true
  }

  return (
    <Modal
      title="Edit note"
      destroyOnClose
      visible={isModalVisible}
      okText="Save"
      cancelText="Cancel"
      onOk={() => onOkClick()}
      onCancel={() => handleModalVisibility(false)}
      width={720}
    >
      <div className="form-group">
        <ReactQuill
          theme="snow"
          onChange={(html) => handleNote(html)}
          defaultValue={note.note}
          formats={QUILL_FORMATS}
          modules={QUILL_MODULES}
          placeholder="Write note for this trade..."
        />
      </div>
    </Modal>
  )
}

export default EditNoteModal
