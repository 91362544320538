/* eslint jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { InputNumber, Select } from 'antd'

const OptionalInformation = ({ state, updateState = (f) => f }) => {
  return (
    <div className="trade-form__section">
      <div className="form-group">
        <label htmlFor="highestPrice">Highest Price (optional)</label>
        <InputNumber
          defaultValue={state.highestPrice}
          className="w-100"
          onChange={(value) => updateState('highestPrice', parseFloat(value))}
          decimalSeparator="."
          min={0}
          max={999999999999999}
        />
      </div>
      <div className="form-group">
        <label htmlFor="lowestPrice">Lowest Price (optional)</label>
        <InputNumber
          defaultValue={state.lowestPrice}
          className="w-100"
          onChange={(value) => updateState('lowestPrice', parseFloat(value))}
          decimalSeparator="."
          min={0}
          max={999999999999999}
        />
      </div>
      <div className="form-group">
        <label htmlFor="isHitTP">Trade hit take profit?</label>
        <Select
          className="w-100"
          defaultValue={state.isHitTP.toString()}
          onChange={(value) => updateState('isHitTP', parseInt(value, 10))}
        >
          <Select.Option value="0">No</Select.Option>
          <Select.Option value="1">Yes</Select.Option>
        </Select>
      </div>
    </div>
  )
}

export default OptionalInformation
