import React, { useState } from 'react'
import { Button, message, Popconfirm } from 'antd'
import ReactQuill from 'react-quill'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment/moment'

import actions from '../../../../../redux/trade/actions'
import { QUILL_FORMATS, QUILL_MODULES } from '../../../../../config/quill-config'

import styles from './trade-notes.module.scss'
import EditNoteModal from '../../../../ui/trades/single-trade/notes/EditNote'

const TradeNotes = ({ dispatch, tradeId, notes }) => {
  const [note, handleNote] = useState('')
  const [editModalVisible, handleEditModalVisibility] = useState(false)
  const [noteToEdit, handleNoteToEdit] = useState({})

  const confirmNoteDelete = (noteId) => {
    message.loading({
      content: 'Please, wait...',
      key: 'delete_trade_note',
      duration: 60,
    })
    dispatch({
      type: actions.DELETE_NOTE,
      payload: {
        noteId,
      },
    })
  }

  const openEditModal = (e, note) => {
    e.preventDefault()
    handleNoteToEdit(note)
    handleEditModalVisibility(true)
  }

  const submitForm = (e) => {
    e.preventDefault()
    message.loading({ content: 'Please, wait...', key: 'save_trade_note' })
    if (note === '') {
      message.warning({ content: 'Note field is required', key: 'save_trade_note' })
      return false
    }
    dispatch({
      type: actions.SAVE_NOTE,
      payload: {
        trade: tradeId,
        note,
      },
    })
    handleNote('')
    return true
  }

  return (
    <div className={styles.tradeNotes}>
      <div className={styles.list}>
        {notes.map((note) => (
          <div key={`note_${note.id}`} className={styles.note}>
            <div className="d-flex justify-content-start align-items-center note-item__controls mt-3">
              <div className={styles.info}>{moment(note.created_at).fromNow()}</div>
              <div className="ml-4 ml-md-5">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <Button
                      type="text"
                      className="ant-btn-text__extended"
                      size="large"
                      onClick={(e) => openEditModal(e, note)}
                    >
                      <EditOutlined className="success-color" />
                    </Button>
                  </div>
                  <div>
                    <Popconfirm
                      title="Do you want to delete selected note?"
                      placement="topLeft"
                      onConfirm={() => confirmNoteDelete(note.id)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      okText="Delete"
                      okType="danger"
                    >
                      <Button type="text" className="ant-btn-text__extended" size="large">
                        <DeleteOutlined className="danger-color" />
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: note.note }}
              className={styles.description}
            />
          </div>
        ))}
      </div>
      <div>
        <form onSubmit={submitForm}>
          <div className={`form-group ${styles.form}`}>
            <ReactQuill
              theme="bubble"
              className="trade-note-editor"
              onChange={(html) => handleNote(html)}
              value={note}
              formats={QUILL_FORMATS}
              modules={QUILL_MODULES}
              placeholder="Write note for this trade..."
            />
          </div>
          <div className="form-group text-right mb-0">
            <div className="d-flex justify-content-end">
              <Button type="primary" htmlType="submit">
                <PlusOutlined /> Add note
              </Button>
            </div>
          </div>
        </form>
      </div>
      <EditNoteModal
        dispatch={dispatch}
        isModalVisible={editModalVisible}
        note={noteToEdit}
        handleModalVisibility={handleEditModalVisibility}
      />
    </div>
  )
}

export default TradeNotes
