import React from 'react'
import { Helmet } from 'react-helmet'

import RegisterComponent from '../../../components/system/Auth/Register'

const Register = () => {
  return (
    <div>
      <Helmet title="Register" />
      <RegisterComponent />
    </div>
  )
}

export default Register
