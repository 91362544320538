import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

import Card from '../../../card'
import TotalReturnRadialChart from './chart'

import longNumberToShort from '../../../../../helpers/longNumberToShort'

import styles from './styles.module.scss'

const TotalReturn = ({ stat, user }) => {
  const [data, setData] = useState({ return: 0, totalTrades: 0, winRate: 0 })

  useEffect(() => {
    if (!stat.isLoading && stat.dashboard && stat.dashboard.stats) {
      setData({
        return: stat.dashboard.stats.totalReturn,
        totalTrades: stat.dashboard.winLoseTradesComparison.total,
        winRate: stat.dashboard.winLoseTradesComparison.winPercents,
      })
    }
  }, [stat])

  return (
    <Card>
      <Spin spinning={stat.isLoading}>
        <div className={styles.totalReturn}>
          <div className="row align-items-lg-center">
            <div className="col-6">
              <h3 className={styles.title}>Total Return</h3>
              <p className={styles.label}>
                <small>Total Trades Return</small>
              </p>
              <h4 className={styles.value}>
                {longNumberToShort(parseFloat(data.return).toFixed(2))}{' '}
                {user.get_currency && user.get_currency.short_name
                  ? user.get_currency.short_name
                  : ''}
              </h4>
              <p className={styles.extra}>
                {data.totalTrades} trades with win rate {data.winRate}%
              </p>
            </div>
            <div className="col-6">
              <TotalReturnRadialChart winRate={data.winRate} />
            </div>
          </div>
        </div>
      </Spin>
    </Card>
  )
}

export default TotalReturn
