/* eslint no-unused-vars:0 */
import { all, takeEvery, put } from 'redux-saga/effects'

import actions from './actions'

// eslint-disable-next-line import/no-cycle
import { history } from '../../index'

export function* APPLY({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload })
  } catch (e) {
    put({ type: actions.RESET })
  }
}

export function* APPLY_AND_SEE_TRADES_LIST({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload })
    history.push('/trades')
  } catch (e) {
    put({ type: actions.RESET })
  }
}

export function* ApplyAndSeeTrades({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload })
  } catch (e) {
    put({ type: actions.RESET })
  }
}

export function RESET() {
  put({ type: actions.RESET })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.APPLY, APPLY),
    takeEvery(actions.APPLY_AND_SEE_TRADES_LIST, APPLY_AND_SEE_TRADES_LIST),
    takeEvery(actions.RESET, RESET),
  ])
}
