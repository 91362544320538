import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Spin, Progress } from 'antd'

import Card from '../../../card'

import longNumberToShort from '../../../../../helpers/longNumberToShort'

import styles from './styles.module.scss'

const InstrumentsWidget = ({ stat, user }) => {
  const [data, setData] = useState({ instruments: [], totalTrades: 0 })
  useEffect(() => {
    if (
      !stat.isLoading &&
      stat.dashboard &&
      stat.dashboard.instrumentsStat &&
      stat.dashboard.marketsStat
    ) {
      const instrumentsArr = []
      const instrumentsStats = stat.dashboard.instrumentsStat
      const totalTrades = stat.dashboard.marketsStat.totalTrades
      Object.keys(instrumentsStats).forEach((key) => {
        const instrument = instrumentsStats[key]
        if (instrument.total.trades > 0) {
          // console.log(instrument)
          const data = {
            key: key,
            name: instrument.name,
            long: instrument.long,
            short: instrument.short,
            total: instrument.total,
            resultPercents: (instrument.total.trades * 100) / totalTrades,
          }
          instrumentsArr.push({
            ...data,
            resultPercents: Math.round(data.resultPercents * 10) / 10,
          })
        }
      })
      // console.log(instrumentsArr)
      setData({
        instruments: _.orderBy(instrumentsArr, ['resultPercents'], ['desc']),
        totalTrades: totalTrades,
      })
    }
  }, [stat])

  return (
    <Card title="Top Instruments">
      <Spin spinning={stat.isLoading}>
        <div className={styles.instrumentsWidget}>
          <PerfectScrollbar className="select__ps select__ps--height">
            <div className={styles.inner}>
              {data.instruments.map((instrument) => (
                <div key={instrument.key} className={styles.item}>
                  <div className={styles.iconWrapper}>
                    <div className={styles.icon}>
                      <span>
                        {`${instrument.name.charAt(0)}${instrument.name.charAt(
                          instrument.name.length >= 4 ? 3 : 2,
                        )}`}
                      </span>
                    </div>
                  </div>
                  <div className={styles.name}>
                    <h4>{instrument.name}</h4>
                    <p>
                      Closed{' '}
                      <strong>
                        {new Intl.NumberFormat().format(instrument.total.trades)}
                      </strong>{' '}
                      trades with win rate{' '}
                      <strong>
                        {new Intl.NumberFormat().format(
                          (Math.round(
                            (instrument.total.winTrades * 100) / instrument.total.trades,
                          ) *
                            10) /
                            10,
                        )}
                        %
                      </strong>
                      .
                      <br />
                      Total return:{' '}
                      <strong>
                        {longNumberToShort(instrument.total.winMoney || 0)}{' '}
                        {user.get_currency && user.get_currency.short_name
                          ? user.get_currency.short_name
                          : ''}
                      </strong>
                    </p>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.percents}>{instrument.resultPercents}%</div>
                    <div className={styles.progress}>
                      <Progress
                        type="circle"
                        percent={instrument.resultPercents}
                        width={20}
                        strokeWidth={16}
                        strokeColor="#007be8"
                        trailColor="#e4e6ef"
                        showInfo={false}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </PerfectScrollbar>
        </div>
      </Spin>
    </Card>
  )
}

export default InstrumentsWidget
