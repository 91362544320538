import React from 'react'
import { Modal, Form, Input, Button, message } from 'antd'

import actions from '../../../../redux/instrument/actions'

const EditInstrumentModal = ({ dispatch, instrument, modalVisible, handleModalVisibility }) => {
  const onFinish = (values) => {
    message.loading({ content: 'Please, wait...', key: 'edit_instrument' })
    dispatch({
      type: actions.UPDATE,
      payload: {
        id: instrument.id,
        name: values.name,
      },
    })
    handleModalVisibility(false)
  }

  const onFinishFailed = () => {
    message.error({ content: 'Something goes wrong. Check the fields and try again please.', key: 'edit_instrument' })
  }

  return (
    <Modal
      title={`Edit instrument ${instrument.name}`}
      destroyOnClose
      footer={null}
      visible={modalVisible}
      onCancel={() => handleModalVisibility(false)}
    >
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="name"
          initialValue={instrument.name}
          rules={[
            {
              required: true,
              message: 'Please input instrument name',
            },
          ]}
        >
          <Input placeholder="Input instrument name" autoComplete="off" />
        </Form.Item>
        <Form.Item className="mb-0 text-right">
          <Button type="primary" htmlType="submit">
            Update Instrument
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditInstrumentModal
