import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Avatar, Menu, message, Tooltip, Badge } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { TbApps, TbMoon, TbFilter, TbDiamond } from 'react-icons/tb'

import actions from '../../../../redux/user/actions'
import HeaderShortcutsDropDown from '../../../ui-2/widgets/dropdowns/shortcuts'

import styles from '../style.module.scss'

const mapStateToProps = ({ user, dispatch }) => ({
  user,
  dispatch,
})

const UserMenu = ({
  filter,
  user,
  dispatch,
  handleSwitchTheme,
  handleFilterDrawerVisibility,
}) => {
  const userInfo = user.user
  const [open, setOpen] = useState(false)
  // console.log(userInfo)

  const handleOpenChange = (flag) => {
    setOpen(flag)
  }

  const logOut = () => {
    message.loading({ content: 'Please, wait...', key: 'user_logout' })
    dispatch({
      type: actions.LOGOUT,
    })
  }

  const userMenu = (
    <Menu>
      <Menu.Item>
        <strong>{`${userInfo.first_name} ${userInfo.last_name}`}</strong>
        <div>
          <strong>Billing plan: </strong>
          {userInfo.is_premium === 0 ? 'Free' : 'Premium'}
        </div>
        {userInfo.premium_expire !== null && (
          <div>
            <strong>Expire: </strong>
            {userInfo.premium_expire}
          </div>
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/settings/general">
          <i className="fe fe-settings mr-2" /> Settings
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/settings/subscription">
          <i className="fe fe-credit-card mr-2" /> Subscription
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logOut}>
        <i className="fe fe-log-out mr-2" /> Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <div className={styles.headerRightMenu}>
      {!userInfo.is_premium && (
        <div className="mr-4">
          <Link to="/settings/subscription" className={styles.upgradeAccount}>
            <TbDiamond /> <span>Upgrade to Premium</span>
          </Link>
        </div>
      )}
      <div className="mr-4">
        <Tooltip
          placement="bottom"
          title={filter.isEnabled ? 'Filters applied' : 'Filters'}
          overlayClassName="tr-tooltip"
        >
          <Badge dot={filter.isEnabled} className="tr-badge">
            <Link
              to="/"
              className={styles.darkLightMode}
              onClick={(e) => {
                e.preventDefault()
                handleFilterDrawerVisibility(true)
              }}
            >
              <TbFilter />
            </Link>
          </Badge>
        </Tooltip>
      </div>
      <div className="mr-4">
        <Tooltip placement="bottom" title="Shortcuts" overlayClassName="tr-tooltip">
          <Dropdown
            onOpenChange={handleOpenChange}
            open={open}
            overlayClassName="tr-shortcuts-dropdown"
            trigger={['click']}
            overlay={<HeaderShortcutsDropDown setOpen={setOpen} />}
            placement="bottomRight"
            autoAdjustOverflow
          >
            <Link
              to="/"
              className={styles.darkLightMode}
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              <TbApps />
            </Link>
          </Dropdown>
        </Tooltip>
      </div>
      <div className="mr-4">
        <Link
          to="/"
          className={styles.darkLightMode}
          onClick={(e) => {
            e.preventDefault()
            handleSwitchTheme()
          }}
        >
          <TbMoon />
        </Link>
      </div>
      <Dropdown
        overlay={userMenu}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName={styles.tr_dropdown__ul}
      >
        <div className={styles.tr_header__dropdown}>
          <Avatar
            className={styles.tr_avatar}
            shape="circle"
            size="large"
            icon={<UserOutlined />}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default connect(mapStateToProps)(UserMenu)
