import React, { useState } from 'react'
import styles from '../../../../ui/trades/single-trade/styles/trade-executions.module.scss'
import moment from 'moment'

const Executions = ({ executions, user }) => {
  const [itemsToRender, setItemsToRender] = useState(5)

  return (
    <div className={styles.Executions}>
      {executions.map((execution, index) => {
        if (index + 1 <= itemsToRender) {
          return (
            <div
              key={`trade_${execution.trade}_execution_${execution.id}`}
              className={styles.Execution}
            >
              <div className={styles.Execution__Item}>
                <div className={styles.value}>
                  {moment(execution.execution_date).format(
                    `${user.date_format} ${user.time_format}`,
                  )}
                </div>
                <div className={styles.action}>
                  <div className={styles.dot} data-action={execution.action} />
                  <div>{execution.action === 0 ? 'Sell' : 'Buy'}</div>
                </div>
              </div>
              <div className={styles.Execution__Item}>
                <div className={styles.title}>Units/Lots</div>
                <div className={styles.value}>{parseFloat(execution.qty)}</div>
              </div>
              <div className={styles.Execution__Item}>
                <div className={styles.title}>Price</div>
                <div className={styles.value}>{execution.execution_price}</div>
              </div>
              <div className={styles.Execution__Item}>
                <div className={styles.title}>Fees</div>
                <div className={styles.value}>
                  {execution.execution_fees}{' '}
                  {user.get_currency && user.get_currency.short_name}
                </div>
              </div>
            </div>
          )
        }

        return null
      })}
      {executions.length > itemsToRender ? (
        <div className={styles.extend}>
          <button
            onClick={() => {
              setItemsToRender(executions.length)
            }}
          >
            Show All
          </button>
        </div>
      ) : (
        <>
          {executions.length === itemsToRender && (
            <div className={styles.extend}>
              <button onClick={() => setItemsToRender(5)}>Show Less</button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Executions
