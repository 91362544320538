import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

function handleError(e) {
  message.error('Something goes wrong.')
  // eslint-disable-next-line no-unused-expressions
  e.response && e.response.status === 401 && window.location.reload()
  history.push('/')
}

/**
 * Get user instruments list
 */
export function* LIST({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { pageLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/instruments?page=${payload.page}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { instruments: req.data.instruments, pageLoading: false } })
  } catch (e) {
    handleError(e)
    yield put({ type: actions.SET_STATE, payload: { pageLoading: true } })
  }
}

/**
 * Create instrument
 */
export function* CREATE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { btnLoading: true } })
    const req = yield call(axiosRequest, '/api/v1/instruments/create', 'post', payload, true)
    yield put({ type: actions.SET_STATE, payload: { instruments: req.data.instruments, btnLoading: false } })
    message.success({ content: 'Done', key: 'create_instrument' })
  } catch (e) {
    message.error({ content: 'Something goes wrong.', key: 'create_instrument' })
    handleError(e)
    yield put({ type: actions.SET_STATE, payload: { btnLoading: false } })
  }
}

/**
 * Create instrument
 */
export function* UPDATE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { btnLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/instruments/${payload.id}`, 'patch', payload, true)
    yield put({ type: actions.SET_STATE, payload: { instruments: req.data.instruments, btnLoading: false } })
    message.success({ content: 'Done', key: 'edit_instrument' })
  } catch (e) {
    handleError(e)
    yield put({ type: actions.SET_STATE, payload: { btnLoading: false } })
  }
}

/**
 * Create instrument
 */
export function* DELETE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { btnLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/instruments/${payload.id}`, 'delete', null, true)
    yield put({ type: actions.SET_STATE, payload: { instruments: req.data.instruments, btnLoading: false } })
    message.success({ content: 'Done', key: 'delete_instrument' })
  } catch (e) {
    handleError(e)
    yield put({ type: actions.SET_STATE, payload: { btnLoading: false } })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
