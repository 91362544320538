import actions from './actions'

const initialState = {
  tradeMarket: [],
  tradeAction: [],
  tradeAccount: [],
  tradeInstrument: [],
  tradeTS: [],
  tradeTimeFrame: [],
  advancedStats: [],
  isEnabled: false,
  from: '',
  to: '',
  getBy: 'exit_date',
  importId: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET:
      return {
        tradeMarket: [],
        tradeAction: [],
        tradeAccount: [],
        tradeInstrument: [],
        tradeTS: [],
        tradeTimeFrame: [],
        isEnabled: false,
        from: '',
        to: '',
        getBy: 'exit_date',
      }
    default:
      return state
  }
}
