import React, { useState } from 'react'
import classNames from 'classnames'
import axios from 'axios'
import { history } from 'index'
import { Link } from 'react-router-dom'
import { Button, Form, Input, message, notification } from 'antd'

import styles from '../style.module.scss'

import { API_URL } from '../../../../config/app'

const SetNewPasswordOnReset = ({ resetToken }) => {
  const [btnLoading, handleBtnLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values) => {
    handleBtnLoading(true)
    if (values.password !== values.passwordConfirm) {
      notification.error({ message: 'Error', description: 'The two passwords that you entered do not match!' })
      return null
    }
    axios
      .post(`${API_URL}/api/v1/auth/new-password`, {
        password: values.password,
        confirmation: values.passwordConfirm,
        token: resetToken,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({ message: 'Success', description: 'Password successfully changed' })
          handleBtnLoading(false)
          history.push('/secured/login')
        }
      })
      .catch((e) => {
        if (e.response.data && e.response.data.msg) {
          notification.error({ message: 'Error', description: e.response.data.msg })
        } else {
          notification.error({ message: 'Error', description: 'Something goes wrong, try again please' })
        }
        handleBtnLoading(false)
      })
    return true
  }

  const onFinishFailed = () => {
    message.error('Something goes wrong. Reload the page and try again please.')
  }

  return (
    <div>
      <div className={classNames('card', styles.auth_card)}>
        <h1 className={styles.auth_card__title}>Password Reset</h1>
        {/* begin auth form */}
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mb-4"
        >
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              { required: true, message: 'Please input a new password' },
              { min: 8, message: `Password must be at least 8 characters` },
            ]}
          >
            <Input type="password" size="large" placeholder="New password" />
          </Form.Item>
          <Form.Item
            name="passwordConfirm"
            hasFeedback
            rules={[{ required: true, message: 'Please confirm a new password' }]}
          >
            <Input type="password" size="large" placeholder="Confirm password" />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            className="text-center w-100 fw-600"
            htmlType="submit"
            loading={btnLoading}
          >
            Save new password
          </Button>
        </Form>
      </div>
      <div className="text-center mt-3 mb-auto">
        <Link to="/secured/login">Sign in</Link>
      </div>
    </div>
  )
}

export default SetNewPasswordOnReset
