import React from 'react'
import ym from 'react-yandex-metrika'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import classNames from 'classnames'
import styles from '../style.module.scss'

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
})

const RegisterComponent = ({ dispatch, user }) => {
  const onFinish = (values) => {
    // console.log(values)
    dispatch({
      type: 'user/REGISTER',
      payload: values,
    })
    ym('reachGoal', 'userRegistered')
  }

  const onFinishFailed = () => {
    message.error({ content: 'Error' })
  }

  const validateMessages = {
    required: 'This field is required!',
    types: {
      email: 'This is not a valid e-mail address',
    },
  }

  return (
    <div>
      <div className={classNames('card', styles.auth_card)}>
        <h1 className={classNames(styles.auth_card__title, 'mb-2')}>
          Your success starts here
        </h1>
        <p className="mb-4">
          The trading journal that will increase your trading to the next level.
        </p>
        {/* begin auth form */}
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mb-4"
          validateMessages={validateMessages}
        >
          <Form.Item name="email" hasFeedback rules={[{ type: 'email', required: true }]}>
            <Input size="large" placeholder="E-mail Address" />
          </Form.Item>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              { required: true },
              { min: 8, message: `Password must be at least 8 characters` },
            ]}
          >
            <Input size="large" type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            hasFeedback
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(
                    'The two passwords that you entered do not match!',
                  )
                },
              }),
            ]}
          >
            <Input size="large" type="password" placeholder="Confirm your password" />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            className="text-center w-100 fw-600"
            htmlType="submit"
            loading={user.loading}
          >
            Create a free account
          </Button>
        </Form>
        {/* end auth form */}
        {/* begin tos and privacy policy agree */}
        <div className="text-left">
          <p className="mb-0 text-center">
            By signing up, you agree to the{' '}
            <a href="https://tradiry.com/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://tradiry.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
      {/* begin sign in link */}
      <div className="text-center mt-3 mb-auto">
        <span className="mr-2">Already have an account?</span>
        <Link to="/secured/login">Sign in</Link>
      </div>
      {/* end sign in link */}
    </div>
  )
}

export default connect(mapStateToProps)(RegisterComponent)
