import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import actions from '../../../redux/trading-system/actions'

import DefaultLoader from '../../../components/system/Loaders/default'
import TradingSystemListItem from '../../../components/ui/trading-systems/TradingSystemsListItem'

const mapStateToProps = ({ user, tradingSystem }) => ({
  user,
  tradingSystem,
})

class TradingSystemsList extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.LIST,
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  render() {
    const pageTitle = 'Trading Systems'
    const { tradingSystem, user } = this.props

    if (tradingSystem.isListLoading) {
      return <DefaultLoader />
    }

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Link to="/create/trading-system" className="ant-btn ant-btn-primary" key="1">
                <PlusOutlined />
                <span className="ml-2">Add new trading system</span>
              </Link>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__slideInUp">
          <Row className="accounts-list mt-2" gutter={24}>
            {tradingSystem.tradingSystems.length > 0 &&
              tradingSystem.tradingSystems.map((item) => {
                return <TradingSystemListItem key={item.id} ts={item} user={user.user} />
              })}
          </Row>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(TradingSystemsList))
