/* eslint-disable react/jsx-curly-newline,camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import axios from 'axios'
import ym from 'react-yandex-metrika'
import { Button, message } from 'antd'

import {
  PADDLE_DEBUG,
  PADDLE_ENV,
  PADDLE_PRODUCT,
  PADDLE_VENDOR,
  PADDLE_API_URL,
  API_URL,
} from '../../../../config/app'
import getAPITokenFromLocalStorage from '../../../../helpers/getAPITokenFromLocalStorage'
import { useSelector } from 'react-redux'

const SubscribeButton = ({
  user,
  btnText = 'Subscribe now',
  fullWidth = true,
  btnSize = 'large',
}) => {
  console.log(user)

  // useEffect(() => {
  //   const { Paddle } = window
  //   Paddle.Setup({
  //     vendor: PADDLE_VENDOR,
  //     debug: PADDLE_DEBUG,
  //     eventCallback(data) {
  //       // The data.event will specify the event type
  //       if (data.event === 'Checkout.Close') {
  //         message.error({
  //           content: 'Your purchase has been cancelled, we hope to see you again soon!',
  //           key: 'subscription',
  //         })
  //       }
  //     },
  //   })
  //   if (PADDLE_ENV === 'sandbox') {
  //     Paddle.Environment.set(PADDLE_ENV)
  //   }
  // }, [])

  // const paddleBtn = () => {
  //   const { Paddle } = window
  //   // Added Yandex.Metrika Goal
  //   ym('reachGoal', 'userPressSubscribeButton')
  //   // eslint-disable-next-line camelcase
  //   const { id, email, first_name, last_name } = user
  //   Paddle.Checkout.open({
  //     product: PADDLE_PRODUCT,
  //     email,
  //     passthrough: { id, firstName: first_name, lastName: last_name },
  //     disableLogout: true,
  //     successCallback: (checkoutData) => {
  //       const checkoutId = checkoutData.checkout.id
  //       // eslint-disable-next-line no-shadow,func-names
  //       Paddle.Order.details(checkoutId, function (data) {
  //         // Order data, downloads, receipts etc... available within 'data' variable.
  //         if (data.order.subscription_id) {
  //           const subscriptionId = data.order.subscription_id
  //           Paddle.Spinner.show()
  //           axios
  //             .post(
  //               `${API_URL}/api/v1/user/subscription/add`,
  //               { subscriptionId },
  //               getAPITokenFromLocalStorage(true),
  //             )
  //             .then((res) => {
  //               message.success({
  //                 content:
  //                   'Thank you for signing up for your Tradiry Premium Subscription',
  //                 key: 'subscription',
  //               })
  //               // eslint-disable-next-line func-names
  //               setTimeout(function () {
  //                 window.location.reload(false)
  //               }, 250)
  //             })
  //             .catch((e) => {
  //               // eslint-disable-next-line no-alert
  //               alert('Something goes wrong. Please contact support team.')
  //               // eslint-disable-next-line no-console
  //               console.log(e)
  //             })
  //         }
  //       })
  //     },
  //   })
  // }

  const payProGlobal = (e) => {
    let link = `https://store.payproglobal.com/checkout?products[1][id]=83984&page-template=17113&language=en&currency=USD${
      user.first_name ? `&billing-first-name=${user.first_name}` : ''
    }${user.last_name ? `&billing-last-name=${user.last_name}` : ''}&billing-email=${
      user.email
    }&exfo=742`
    // link = 'https://store.payproglobal.com/checkout?products[1][id]=83984&page-template=17113&language=en&currency=USD&use-test-mode=true&secret-key=?h0VmEzr-Q&exfo=742'
    window.showCheckout(e, link)
  }

  return (
    <div className={fullWidth && 'w-100'}>
      <Button
        type="primary"
        className={fullWidth ? 'w-100 text-uppercase' : 'text-uppercase'}
        onClick={(e) => payProGlobal(e)}
        size={btnSize}
      >
        {btnText}
      </Button>
    </div>
  )
}

export default SubscribeButton
