import React from 'react'

import SliderItem from '../slider/SliderItem'

import styles from '../slider/news-slider.module.scss'

const NewsGrid = ({ news }) => {
  return (
    <div className={`${styles.NewsList} mt-0`}>
      <div className={styles.News}>
        <div className={`${styles.News__Content} p-0`}>
          <div className="row">
            {news.map((item) => (
              <div
                className="col-12 col-md-6 col-lg-4 mb-4"
                key={`${item.url}${item.publishedDate}`}
              >
                <SliderItem item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsGrid
