import React from 'react'
import { Skeleton } from 'antd'
import Card from '../../../ui-2/card'

const ReportsOverviewLoader = () => {
  const renderLoaderCard = () => {
    return (
      <Card>
        <Skeleton active />
        <Skeleton active />
      </Card>
    )
  }

  return (
    <div className="row">
      <div className="col-12">{renderLoaderCard()}</div>
      <div className="col-12 col-xl-6">
        {renderLoaderCard()}
        {renderLoaderCard()}
        {renderLoaderCard()}
      </div>
      <div className="col-12 col-xl-6">
        {renderLoaderCard()}
        {renderLoaderCard()}
        {renderLoaderCard()}
      </div>
    </div>
  )
}

export default ReportsOverviewLoader
