/* eslint no-unused-vars:0 */
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader } from 'antd'

import actions from '../../../redux/stat/actions'

import createUrlParamsStringFromFilters from '../../../helpers/createUrlParamsFromFilters'
import reloadPageWithUrlParams from '../../../helpers/reloadPageWithUrlParams'

import ReportsOverviewLoader from '../../../components/system/Loaders/reports-loaders/OverviewLoader'
import HourlyReportComponent from '../../../components/ui/reports/hourly/HourlyReportComponent'

const mapStateToProps = ({ stat, filter, user }) => ({ stat, filter, user })

class HourlyReportPage extends React.Component {
  componentDidMount() {
    const { dispatch, filter } = this.props
    dispatch({
      type: actions.HOURLY_REPORT,
      payload: {
        query: createUrlParamsStringFromFilters(filter),
      },
    })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filter, dispatch } = this.props
    if (filter !== prevProps.filter) {
      reloadPageWithUrlParams(dispatch, actions.HOURLY_REPORT, createUrlParamsStringFromFilters(filter))
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  reloadPageWithUrlParams(paramsString) {
    const { dispatch } = this.props
    dispatch({
      type: actions.HOURLY_REPORT,
      payload: {
        query: paramsString,
      },
    })
  }

  render() {
    const pageTitle = 'Hourly Report'
    const { stat, user } = this.props

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/overview">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {stat.isLoading ? (
            <ReportsOverviewLoader />
          ) : (
            <HourlyReportComponent data={stat.hourlyReport} user={user.user} />
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(HourlyReportPage))
