import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import 'moment-timezone'

import styles from './style.module.scss'

const AccountListItem = ({ account, user }) => {
  return (
    <Col span="24" md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
      <Card
        className={`${styles.accounts_list__item} mb-4`}
        title={account.name}
        extra={<Link to={`/accounts/single/${account.slug}`}>Details</Link>}
      >
        <p className={styles.accounts_list_item__info_text}>
          Balance: {account.balance.replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
          {user.get_currency && user.get_currency.short_name}
        </p>
        <p className={styles.accounts_list_item__info_text}>
          Trades: {account.get_trades_count}
        </p>
        <p className={classNames('mb-0', styles.accounts_list_item__info_text)}>
          Created at:{' '}
          {moment(account.created_at)
            .tz(user.timezone)
            .format(`${user.date_format} ${user.time_format}`)}
        </p>
      </Card>
    </Col>
  )
}

export default AccountListItem
