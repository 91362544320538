import React from 'react'
import { Helmet } from 'react-helmet'

import LoginComponent from '../../../components/system/Auth/Login'

const Login = () => {
  return (
    <div>
      <Helmet title="Login" />
      <LoginComponent />
    </div>
  )
}

export default Login
