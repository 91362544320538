import React from 'react'
import { Line } from 'react-chartjs-2'

import longNumberToShort from '../../../../helpers/longNumberToShort'
import colors from '../../../../config/apexcharts/colors'

const SimulatorChart = ({ data, user }) => {
  const datasetObj = {
    label: 'Current Results',
    fill: false,
    lineTension: 0.1,
    backgroundColor: colors.primaryColor,
    borderColor: colors.primaryColor,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: colors.white,
    pointBorderWidth: 0,
    pointHoverRadius: 0,
    pointHoverBorderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 10,
    data: [],
  }

  const chartData = {
    labels: [],
    datasets: [
      {
        ...datasetObj,
        data: [],
      },
      {
        ...datasetObj,
        label: 'Simulated',
        backgroundColor: colors.warningColor,
        borderColor: colors.warningColor,
        data: [],
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: true,
      labels: {
        fontColor: colors.labelColor,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              return `${longNumberToShort(value, '0.0a')} ${user.get_currency.short_name}`
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const drawChart = () => {
    if (!data || !data.original.trades || !data.simulated.trades) {
      return null
    }
    const originalTrades = data.original.trades
    const simulatedTrades = data.simulated.trades
    originalTrades.forEach((originalTrade) => {
      chartData.labels.push(originalTrade.number)
      chartData.datasets[0].data.push(originalTrade.totalReturn)
    })
    simulatedTrades.forEach((simulatedTrade) => {
      chartData.datasets[1].data.push(simulatedTrade.totalReturn)
    })
    return <Line data={chartData} options={options} />
  }

  return (
    <div className="card card__stat m-b-30">
      <div className="card-header">
        <h3 className="card-title">Chart</h3>
      </div>
      <div className="card-content">
        <div style={{ position: 'relative', height: '600px', width: '100%' }}>
          {drawChart()}
        </div>
      </div>
    </div>
  )
}

export default SimulatorChart
