import React from 'react'
import { Helmet } from 'react-helmet'

import comingSoonImage from '../../assets/svg/coming-soon.svg'

const ComingSoon = () => {
  return (
    <>
      <Helmet title="Coming Soon..." />
      <div className="row">
        <div className="col-12 col-xl-8 offset-xl-2">
          <h1 className="mt-4 mt-md-5 text-center">Coming soon...</h1>
        </div>
        <div className="col-12 col-lg-6 offset-lg-3">
          <img src={comingSoonImage} alt="" className="img-fluid" />
        </div>
      </div>
    </>
  )
}

export default ComingSoon
