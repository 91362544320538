import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader } from 'antd'

import actions from '../../../redux/user/actions'

import SpinnerLoader from '../../../components/system/Loaders/spinner'
import SubscriptionDetails from '../../../components/ui/settings/subscription/SubscriptionDetails'
import SubscriptionPromo from '../../../components/ui/settings/subscription/SubscriptionPromo'

const mapStateToProps = ({ user }) => ({
  user,
})

class SubscriptionPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.SUBSCRIPTION_INFO,
    })
  }

  render() {
    const pageTitle = 'Subscription'
    const { user } = this.props

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/dashboard/general">Settings</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {user.loading ? (
            <SpinnerLoader />
          ) : (
            <>
              {user.subscription ? (
                <SubscriptionDetails user={user.user} subscription={user.subscription} />
              ) : (
                <>
                  <SubscriptionPromo user={user.user} />
                </>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(SubscriptionPage))
