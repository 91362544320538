/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react'
import classNames from 'classnames'

const TradesListPagination = ({ currentPage, totalPages, updateState }) => {
  const handleChangePage = (page) => {
    if (currentPage === 1 && page <= 1) {
      page = 1
    }
    if (currentPage === totalPages && page >= totalPages) {
      page = totalPages
    }
    updateState('page', page)
  }

  if (totalPages === 1) {
    return null
  }

  return (
    <div className="simple-pagination">
      <div className="d-flex justify-content-end align-items-center simple-pagination__wrapper">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={classNames(
            'pagination-item',
            currentPage === 1 && 'pagination-item__disabled',
          )}
          onClick={() => handleChangePage(1)}
        >
          <span>
            <i className="fe fe-chevrons-left" />
          </span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={classNames(
            'pagination-item',
            currentPage - 1 < 1 && 'pagination-item__disabled',
          )}
          onClick={() => handleChangePage(currentPage - 1)}
        >
          <span>
            <i className="fe fe-chevron-left" />
          </span>
        </div>
        <div className="pagination-item pagination-text">
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={classNames(
            'pagination-item',
            currentPage + 1 > totalPages && 'pagination-item__disabled',
          )}
          onClick={() => handleChangePage(currentPage + 1)}
        >
          <span>
            <i className="fe fe-chevron-right" />
          </span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={classNames(
            'pagination-item',
            currentPage === totalPages && 'pagination-item__disabled',
          )}
          onClick={() => handleChangePage(totalPages)}
        >
          <span>
            <i className="fe fe-chevrons-right" />
          </span>
        </div>
      </div>
    </div>
  )
}

export default TradesListPagination
