import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import actions from '../../../redux/instrument/actions'

import DefaultLoader from '../../../components/system/Loaders/default'
import InstrumentsList from '../../../components/ui/instruments/InstrumentsList'
import AddInstrumentModal from '../../../components/ui/instruments/modals/AddInstrumentModal'

const mapStateToProps = ({ instrument }) => ({
  instrument,
})

class InstrumentsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      createModalVisible: false,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.LIST,
      payload: {
        page: 1,
      },
    })
  }

  handleCreateModalVisibility = (condition) => {
    this.setState({ createModalVisible: condition })
  }

  render() {
    const pageTitle = 'Instruments'
    const { instrument, dispatch } = this.props
    const { createModalVisible } = this.state

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/dashboard/general">Settings</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button type="primary" key="1" onClick={() => this.handleCreateModalVisibility(true)}>
                <PlusOutlined />
                <span className="ml-2">Add new instrument</span>
              </Button>,
            ]}
          />
        </div>
        {instrument.pageLoading ? (
          <DefaultLoader />
        ) : (
          <InstrumentsList
            dispatch={dispatch}
            instruments={instrument.instruments}
            btnLoading={instrument.btnLoading}
          />
        )}
        <AddInstrumentModal
          dispatch={dispatch}
          isVisible={createModalVisible}
          handleVisibility={this.handleCreateModalVisibility}
          btnLoading={instrument.btnLoading}
        />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(InstrumentsPage))
