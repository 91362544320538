import React, { useState } from 'react'
import { message } from 'antd'
import actions from '../../../../redux/trade/actions'

import TradeHeader from './partials/TradeHeader'
import ShareTradeModal from '../../../ui/trades/single-trade/ShareTradeModal'
import TradeDetails from './partials/TradeDetails'
import TradeChart from './partials/TradeChart'
import BlockWrapper from './partials/BlockWrapper'
import TradeScreenshots from './partials/TradeScreenshots'
import TradeNotes from './partials/TradeNotes'

const SingleTrade = ({
  dispatch,
  trade,
  notes,
  user,
  closeSingleTradeDrawer = (f) => f,
  buildQueryStringFromState = (f) => f,
}) => {
  const [shareModalVisibility, handleShareModalVisibility] = useState(false)

  const handleDeleteTrade = () => {
    message.loading({ content: 'Please, wait...', key: 'delete_trade' })
    dispatch({
      type: actions.DELETE,
      payload: {
        slug: trade.slug,
        query: buildQueryStringFromState(),
      },
    })
    // Close drawer
    closeSingleTradeDrawer()
  }

  if (trade === false) {
    return null
  }

  return (
    <div className="mb-4 mb-lg-5">
      <TradeHeader
        trade={trade}
        user={user}
        shareModalVisibility={handleShareModalVisibility}
        handleDeleteTrade={handleDeleteTrade}
      />
      {trade.get_instrument_reference && trade.get_instrument_reference.symbol ? (
        <TradeChart trade={trade} />
      ) : null}
      <TradeDetails trade={trade} user={user} />
      <div className="row">
        {trade.get_images && trade.get_images.length > 0 ? (
          <div className="col-12 col-md-6 mb-4">
            <BlockWrapper title="Screenshots">
              <TradeScreenshots trade={trade} />
            </BlockWrapper>
          </div>
        ) : null}
        <div className="col-12 col-md-6 mb-4">
          <BlockWrapper title="Notes">
            <TradeNotes notes={notes} tradeId={trade.id} dispatch={dispatch} />
          </BlockWrapper>
        </div>
      </div>
      <ShareTradeModal
        dispatch={dispatch}
        visible={shareModalVisibility}
        handleVisibility={handleShareModalVisibility}
        trade={trade}
      />
    </div>
  )
}

export default SingleTrade
