import React from 'react'
import { Card, Popconfirm, message } from 'antd'
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment'
import 'moment-timezone'
import styles from './style.module.scss'

import actions from '../../../../redux/account/actions'

const AccountTransactionItem = ({
  dispatch,
  accountId,
  transaction,
  user,
  onEditTransactionsDrawerOpen,
}) => {
  const onDeleteConfirm = () => {
    // Show message
    message.loading({ content: 'Please, wait...', key: 'delete_transaction' })
    // Dispatch
    dispatch({
      type: actions.TRANSACTION_DELETE,
      payload: {
        id: transaction.id,
        account: accountId,
      },
    })
  }

  const getTransactionType = (type) => {
    switch (type) {
      case 0:
        return 'Withdrawal'
      case 1:
        return 'Deposit'
      case 2:
        return 'Dividends'
      case 3:
        return 'Taxes'
      case 4:
        return 'Fees'
      default:
        return null
    }
  }

  return (
    <Card
      className={classNames(styles.card__transaction, 'mb-3')}
      bodyStyle={{ padding: 0 }}
    >
      <div className={styles.card_transaction__body}>
        <div className="d-flex align-items-center flex-wrap">
          {transaction.action === 1 || transaction.action === 2 ? (
            <div
              className={classNames(
                'flex-shrink-1',
                'mr-3',
                styles.transactions__arrow,
                styles.transactions__arrow_success,
              )}
            >
              <i className="fe fe-arrow-up" />
            </div>
          ) : (
            <div
              className={classNames(
                'flex-shrink-1',
                'mr-3',
                styles.transactions__arrow,
                styles.transactions__arrow_danger,
              )}
            >
              <i className="fe fe-arrow-down" />
            </div>
          )}
          <div className={classNames('flex-grow-1', 'mr-2', styles.transactions__value)}>
            Value: {transaction.profit_loss.replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
            {user.get_currency.short_name}
          </div>
          <div className={classNames('flex-grow-1', 'mr-2', styles.transactions__date)}>
            Date:{' '}
            {moment(transaction.entry_date).format(
              `${user.date_format} ${user.time_format}`,
            )}
          </div>
          <div className={classNames('flex-grow-1', 'mr-2', styles.transactions__date)}>
            Type: {getTransactionType(transaction.action)}
          </div>
          <div className="d-flex align-items-center">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div
              className={classNames(
                'mr-2',
                'success-avatar',
                styles.transactions__buttons,
                'success-background',
              )}
              onClick={() => onEditTransactionsDrawerOpen(transaction)}
            >
              <EditOutlined />
            </div>
            <Popconfirm
              title="Do you really want to delete this transaction?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              placement="bottomRight"
              okText="Yes, delete"
              cancelText="Cancel"
              okType="danger"
              onConfirm={onDeleteConfirm}
            >
              <div
                className={classNames(
                  'danger-avatar',
                  styles.transactions__buttons,
                  'danger-background',
                )}
              >
                <DeleteOutlined />
              </div>
            </Popconfirm>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default AccountTransactionItem
