import moment from 'moment/moment'

const importTradesTips = {
  binanceUsdM: (
    <>
      <ul>
        <li className="mb-3">
          Open <strong>Trade History</strong> page (Dashboard -> Orders -> Futures Order
          -> Trade History) or click{' '}
          <a
            href="https://www.binance.com/en/my/orders/futures/tradehistory"
            target="_blank"
            rel="noreferrer nofollow noopener"
          >
            here to open Binance website
          </a>
          .
        </li>
        <li className="mb-3">
          Choose <strong>USD@-M</strong> tab <br />
          <img
            src="https://tradiry-storage.sfo2.cdn.digitaloceanspaces.com/assets/import-templates/Screenshot%202024-02-06%20at%2021.47.29.png"
            className="img-fluid"
            alt="USD@-M"
          />
        </li>
        <li className="mb-3">
          Open <strong>Export Trade History</strong> popup. <br />
          <img
            src="https://tradiry-storage.sfo2.cdn.digitaloceanspaces.com/assets/import-templates/Screenshot%202024-02-06%20at%2021.41.44.png"
            className="img-fluid"
            alt="Export Trade History"
          />
          <br />
          <img
            src="https://tradiry-storage.sfo2.cdn.digitaloceanspaces.com/assets/import-templates/Screenshot%202024-02-06%20at%2021.49.09.png"
            alt="Export Trade History"
            className="img-fluid"
          />
        </li>
        <li className="mb-3">
          Select time period you want to export and click <strong>Export</strong> button.
          Tradiry will import only closed trades.{' '}
          <strong className="warning-color">
            You can create a new report to import new closed transactions. Please note
            that the new report must contain the entire history of the trade from opening
            to closing, otherwise the system will not be able to process the file
            correctly. After the import is complete, make sure that the system has not
            created any duplicate trades. For example, you imported transactions from 1
            January 2024 to 30 January 2024 where there were 8 closed trades and 2 open.
            The system imports only 8 trades. You can upload a new file from 1 January
            2024 to 5 February to add new trades. If you upload a file with data from 30
            January 2024, the system will not be able to determine when the old trades
            were opened.
          </strong>
        </li>
        <li className="mb-3">
          Convert downloaded file to <strong>CSV</strong> format with Excel, Google Sheets
          etc.
        </li>
        <li className="mb-3">
          If you used <strong>"Beyond 90 days"</strong>, then the resulting file will
          already be in CSV format and you can upload it immediately.
        </li>
        <li>
          It usually takes a couple of minutes to process a file, however if you are
          uploading large files it can take over 30 minutes. You can close this page, once
          the file is processed, the trades will appear on the <strong>Trades</strong>{' '}
          page.
        </li>
      </ul>
    </>
  ),
  ctrader: (
    <>
      <ul>
        <li>
          The statement must be <strong>in English.</strong> Change cTrader language to
          English before export.
        </li>
        <li>
          You can import statements <strong>from Desktop app only</strong>. cTrader Web is
          not supported for now.
        </li>
        <li>
          You can import up to{' '}
          <strong>
            <u>1000 trades per import</u>
          </strong>
        </li>
        <li>
          Open <strong>History</strong> tab and select period or choose date range
        </li>
        <li>Then right click on any column in history and check all fields</li>
        <li>On the right side click statement button and save it as HTML</li>
        <li>
          Fill the form and <strong>Import</strong> button
        </li>
        <li>Contact support team if you have any questions about trades import</li>
      </ul>
    </>
  ),
  mt4: (
    <>
      <ul>
        <li>
          The statement must be <strong>in English.</strong> Change MetaTrader 4 language
          to English before export.
        </li>
        <li>
          You can import up to{' '}
          <strong>
            <u>1000 trades per import</u>
          </strong>
        </li>
        <li>
          Navigate to the <strong>Account history</strong> tab in the MetaTrader 4
          Terminal (bottom of the platform)
        </li>
        <li>
          Then right click anywhere in the terminal and click on{' '}
          <strong>All history</strong> or choose <strong>Custom period</strong>, this will
          show all your closed trades
        </li>
        <li>
          Repeat the operation and right click again on the terminal, then select one of
          the two types of statement : Normal report or detailed. Essentially, the
          detailed report will give you more metrics on your account as well as a chart
          showing the P&L. A normal report will just show the positions closed and the
          total P&L. Both works for import.
        </li>
        <li>
          Fill the form and <strong>Import</strong> button
        </li>
        <li>Contact support team if you have any questions about trades import</li>
      </ul>
    </>
  ),
  mt5: (
    <>
      <ul>
        <li>
          The statement must be <strong>in English.</strong> Change MetaTrader 5 language
          to English before export.
        </li>
        <li>
          You can import up to{' '}
          <strong>
            <u>1000 trades per import</u>
          </strong>
        </li>
        <li>
          Navigate to the <strong>History</strong> tab in the MetaTrader 5 Terminal
          (bottom of the platform)
        </li>
        <li>
          Then right click anywhere in the terminal and click on{' '}
          <strong>All history</strong> or choose <strong>Custom period</strong>, this will
          show all your closed trades
        </li>
        <li>
          Right click again and select <strong>Positions</strong>
        </li>
        <li>
          Repeat the operation and right click again on the terminal, then select{' '}
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <strong>Reports > HTML report</strong> to import.
        </li>
        <li>
          Fill the form and <strong>Import</strong> button
        </li>
        <li>Contact support team if you have any questions about trades import</li>
      </ul>
    </>
  ),
  csv: (
    <>
      <ul>
        <li>
          The statement must be <strong>in English</strong>
        </li>
        <li>
          You can import up to{' '}
          <strong>
            <u>250 trades per import</u>
          </strong>
        </li>
        <li>Ensure you fill all fields</li>
        <li>Download Excel template and input your data</li>
        <li>Save Excel template in .csv (Comma-separated Values, CSV) format</li>
        <li>You will be able to preview you trades before import</li>
        <li>
          Available markets: <strong>Forex, Stocks, Crypto, Futures, Options</strong>
        </li>
        <li>
          Available time frames:{' '}
          <strong>1m, 5m, 15m, 30m, 1h, 4h, 5h, 1d, 1w, month</strong>
        </li>
        <li>
          All prices should be <strong>0.00000</strong> format
        </li>
        <li>
          Date format (24-hours format){' '}
          <strong>{moment().format('YYYY-MM-DD HH:mm')}</strong>
        </li>
        <li>
          Trade option can be <strong>Buy</strong> or <strong>Sell</strong> (capitalized)
        </li>
        <li>
          Do not include extra formatting like <strong>$1000.00</strong>,{' '}
          <strong>1,000.99</strong> or <strong>1000 USD</strong>. Correct input:{' '}
          <strong>1000.99</strong> or just <strong>1000</strong>
        </li>
      </ul>
      <p className="mb-0">
        <a
          href="https://storage.tradiry.com/assets/import-templates/tradiry_import_template_1.0.xlsx"
          className="btn btn-primary w-100"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Excel template
        </a>
      </p>
    </>
  ),
}

export default importTradesTips
