import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

/**
 * User login saga
 */
export function* LOGIN({ payload }) {
  const { email, password } = payload
  try {
    // Set loading
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    // API request
    const login = yield call(axiosRequest, '/api/v1/auth/login', 'post', {
      email,
      password,
    })
    // Check if success
    if (login.data && login.data.access_token) {
      const loginResponse = login.data
      localStorage.setItem('apiToken', loginResponse.access_token)
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          authorized: true,
          user: loginResponse.user,
          accessAllowed: loginResponse.accessAllowed,
        },
      })
      if (loginResponse.accountsCheck) {
        yield put({
          type: 'user/SET_STATE',
          payload: { accountsCheck: loginResponse.accountsCheck },
        })
      }
      let welcomeMessage = 'Logged in!'
      if (loginResponse.user.first_name !== null) {
        welcomeMessage = `Welcome back, ${loginResponse.user.first_name}!`
      }
      notification.success({
        message: 'Success',
        description: welcomeMessage,
      })
      yield history.push('/')
    } else {
      yield put({ type: 'user/SET_STATE', payload: { loading: false } })
      notification.error({
        message: 'Login failed',
        description: 'Something goes wrong. Reload the page and try again please.',
      })
    }
  } catch (e) {
    // Return error
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.error({
      message: 'Login failed',
      description: 'These credentials do not match our records',
    })
  }
}

/**
 * User registration saga
 */
export function* REGISTER({ payload }) {
  // eslint-disable-next-line camelcase
  const { email, password, password_confirmation } = payload
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    yield call(axiosRequest, '/api/v1/auth/register', 'post', {
      name: email,
      email,
      password,
      // eslint-disable-next-line camelcase
      password_confirmation,
    })
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.success({
      message: 'Success',
      description: 'Account was created',
    })
    yield history.push('/secured/login')
  } catch (e) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.error({
      message: 'Error',
      description: 'The given data was invalid or the email has already been taken.',
    })
  }
}

/**
 * Logout saga
 */
export function* LOGOUT() {
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    yield call(axiosRequest, '/api/v1/auth/logout', 'get', null, true)
    yield put({ type: 'user/SET_STATE', payload: { loading: false, authorized: false } })
    message.success({ content: 'Success', key: 'user_logout' })
    yield history.push('/secured/login')
  } catch (e) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    message.error({ content: 'Logout failed', key: 'user_logout' })
  }
}

/**
 * Init account saga
 */
export function* ACCOUNT_INIT({ payload }) {
  try {
    // Set loading
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    const initAccount = yield call(
      axiosRequest,
      '/api/v1/user/settings/init',
      'post',
      payload,
      true,
    )
    if (initAccount.data.success) {
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, user: initAccount.data.user },
      })
      yield history.push('/')
      message.success({ content: 'Success' })
    }
  } catch (e) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.error({
      message: 'Account init failed',
      description: 'Check your inputs and try again.',
    })
  }
}

/**
 * User general settings save saga
 */
export function* GENERAL_SETTINGS({ payload }) {
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/user/settings/general/save',
      'post',
      payload,
      true,
    )
    if (req.data.success) {
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, user: req.data.user },
      })
      message.success({ content: 'Success', key: 'general_settings_update' })
    }
  } catch (e) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.error({
      message: 'Error',
      description: 'Check your inputs and try again.',
    })
  }
}

/**
 * Change password saga
 */
export function* CHANGE_PASSWORD({ payload }) {
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/user/settings/password/change',
      'post',
      payload,
      true,
    )
    if (req.data.success) {
      message.success({ content: 'Password was changed', key: 'change_password' })
    } else {
      message.error({ content: req.data.errorMsg, key: 'change_password' })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    message.error({ content: 'Something goes wrong...', key: 'change_password' })
  }
}

/**
 * Get user subscription details
 */
export function* SUBSCRIPTION_INFO() {
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/user/subscription/pay-pro-global/info',
      'get',
      null,
      true,
    )
    if (req.data.success) {
      yield put({
        type: 'user/SET_STATE',
        payload: { subscription: req.data.subscription, loading: false },
      })
    } else {
      message.error({
        content: 'Something goes wrong. Try again later or contact customers support.',
        key: 'subscription',
      })
    }
  } catch (e) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    message.error({
      content: 'Something goes wrong. Try again later or contact customers support.',
      key: 'subscription',
    })
  }
}

/**
 * Check user auth status on app load
 */
export function* GET_CURRENT_ACCOUNT_OR_FAIL() {
  const apiToken = localStorage.getItem('apiToken')
  if (!apiToken) {
    yield history.push('/secured/login')
  } else {
    try {
      yield put({ type: 'user/SET_STATE', payload: { loading: true } })
      const authCheck = yield call(
        axiosRequest,
        '/api/v1/user-details',
        'get',
        null,
        true,
      )
      if (authCheck.data) {
        // if (authCheck.data.accessAllowed === false) {
        //   notification.warn({
        //     message: 'Your 7-day trial has ended',
        //     description:
        //       "But it's not too late to take the next step. Upgrade to a paid plan to keep using Tradiry Trading Journal.",
        //   })
        // }
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            authorized: true,
            user: authCheck.data.user,
            accessAllowed: authCheck.data.accessAllowed,
          },
        })
      }
      if (authCheck.data.accountsCheck) {
        yield put({
          type: 'user/SET_STATE',
          payload: { accountsCheck: authCheck.data.accountsCheck },
        })
      }
    } catch (e) {
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: false },
      })
      // Disable redirect to login page for pages with "secured" in URL
      if (!/^\/secured(?=\/|$)/i.test(history.location.pathname)) {
        yield history.push('/secured/login')
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.INIT_ACCOUNT, ACCOUNT_INIT),
    takeEvery(actions.GENERAL_SETTINGS, GENERAL_SETTINGS),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.SUBSCRIPTION_INFO, SUBSCRIPTION_INFO),
    GET_CURRENT_ACCOUNT_OR_FAIL(),
  ])
}
