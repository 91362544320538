import React from 'react'
import { Helmet } from 'react-helmet'
import { Skeleton, message } from 'antd'
import axios from 'axios'

import { API_URL } from '../../../config/app'

import InitAccountComponent from '../../../components/system/Auth/InitAccount'

class AccountInit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataLoading: true,
      countries: [],
      currencies: [],
    }
  }

  componentDidMount() {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
    }
    axios
      .get(`${API_URL}/api/v1/data/common/countries-currencies`, config)
      .then((res) => {
        this.setState({
          dataLoading: false,
          countries: res.data.countries,
          currencies: res.data.currencies,
        })
      })
      .catch(() => {
        message.error('Something goes wrong. Try again later or contact support.')
      })
  }

  render() {
    const { dataLoading, countries, currencies } = this.state
    if (dataLoading) {
      return (
        <div>
          <Helmet title="Init Your Account" />
          <Skeleton active />
        </div>
      )
    }
    return (
      <div>
        <Helmet title="Init Your Account" />
        <InitAccountComponent countries={countries} currencies={currencies} />
      </div>
    )
  }
}

export default AccountInit
