import React from 'react'
import { Helmet } from 'react-helmet'

import ForgotPasswordComponent from '../../../components/system/Auth/ForgotPassword'

class ForgotPassword extends React.Component {
  render() {
    return (
      <>
        <Helmet title="Password Reset" />
        <ForgotPasswordComponent />
      </>
    )
  }
}

export default ForgotPassword
