import React from 'react'

import styles from './styles.module.scss'

const Card = ({
  children,
  title = null,
  bodyTitle = null,
  wrapperClass = '',
  bodyClass = '',
  headerClass = '',
  highlight = false,
}) => {
  return (
    <div
      className={`${styles.card} ${
        highlight ? styles.cardHighlight : ''
      } ${wrapperClass}`}
    >
      {title && (
        <div className={`${styles.cardHeader} ${headerClass}`}>
          <h3 className={styles.title}>{title}</h3>
        </div>
      )}
      <div className={`${styles.cardBody} ${bodyClass}`}>
        {bodyTitle && <h3 className={styles.bodyTitle}>{bodyTitle}</h3>}
        {children}
      </div>
    </div>
  )
}

export default Card
