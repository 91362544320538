/* eslint react/jsx-indent:0 */
import React, { useState } from 'react'
import { Modal, Button, Checkbox, message } from 'antd'

import actions from '../../../../redux/trade/actions'

const ShareTradeModal = ({ dispatch, visible, handleVisibility, trade }) => {
  const [showReturnMoney, handleShowReturnMoney] = useState(
    trade.get_share_options !== null ? trade.get_share_options.show_return_money : false,
  )
  const [showReturnPercents, handleShowReturnPercents] = useState(
    trade.get_share_options !== null ? trade.get_share_options.show_return_percents : true,
  )
  const [showAdvancedStats, handleShowAdvancedStats] = useState(
    trade.get_share_options !== null ? trade.get_share_options.show_advanced_stats : true,
  )
  const [showDescription, handleShowDescription] = useState(
    trade.get_share_options !== null ? trade.get_share_options.show_description : true,
  )
  const [showNotes, handleShowNotes] = useState(
    trade.get_share_options !== null ? trade.get_share_options.show_notes : true,
  )
  const [showImages, handleShowImages] = useState(
    trade.get_share_options !== null ? trade.get_share_options.show_images : true,
  )

  const handleEnable = () => {
    message.loading({ content: 'Please, wait...', duration: 300, key: 'enable_share_trade' })
    dispatch({
      type: actions.ENABLE_SHARE_TRADE,
      payload: {
        slug: trade.slug,
        formData: {
          showReturnMoney,
          showReturnPercents,
          showAdvancedStats,
          showDescription,
          showImages,
          showNotes,
        },
      },
    })
  }

  const handleDisable = () => {
    message.loading({ content: 'Please, wait...', duration: 300, key: 'disable_share_trade' })
    dispatch({
      type: actions.DISABLE_SHARE_TRADE,
      payload: {
        slug: trade.slug,
      },
    })
  }

  const handleModalClose = () => {
    handleVisibility(false)
  }

  return (
    <Modal
      title="Share trade options"
      visible={visible}
      onOk={handleEnable}
      onCancel={handleModalClose}
      destroyOnClose
      footer={
        trade.share_code === null
          ? [
              <Button key="submit" type="primary" onClick={handleEnable}>
                Save
              </Button>,
            ]
          : [
              <Button key="disable" type="danger" onClick={handleDisable}>
                Remove share link
              </Button>,
              <Button key="submit" type="primary" onClick={handleEnable}>
                Save
              </Button>,
            ]
      }
    >
      <>
        {trade.share_code !== null && (
          <p>
            <a href={`https://tradiry.com/share/${trade.share_code}`} target="_blank" rel="noreferrer noopener">
              {`https://tradiry.com/share/${trade.share_code}`}
            </a>
          </p>
        )}
        <div className="row mr-3">
          <div className="col-6">
            <div className="mb-3">
              <Checkbox defaultChecked={showReturnMoney} onChange={(e) => handleShowReturnMoney(e.target.checked)}>
                Show Return, $
              </Checkbox>
            </div>
            <div className="mb-3">
              <Checkbox
                defaultChecked={showReturnPercents}
                onChange={(e) => handleShowReturnPercents(e.target.checked)}
              >
                Show Return, %
              </Checkbox>
            </div>
            <div className="mb-3">
              <Checkbox defaultChecked={showAdvancedStats} onChange={(e) => handleShowAdvancedStats(e.target.checked)}>
                Show Advanced Stats
              </Checkbox>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <Checkbox defaultChecked={showDescription} onChange={(e) => handleShowDescription(e.target.checked)}>
                Show Trade Description
              </Checkbox>
            </div>
            <div className="mb-3">
              <Checkbox defaultChecked={showNotes} onChange={(e) => handleShowNotes(e.target.checked)}>
                Show Trade Notes
              </Checkbox>
            </div>
            <div className="mb-3">
              <Checkbox defaultChecked={showImages} onChange={(e) => handleShowImages(e.target.checked)}>
                Show Uploaded Images
              </Checkbox>
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default ShareTradeModal
