import React, { useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Empty, Checkbox } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'

import SingleTradeDrawer from './SingleTradeDrawer'

import actions from '../../../redux/trade/actions'
import styles from './trades-list-table.module.scss'

const tableHead = [
  {
    name: 'Action',
    key: 'action',
    sortable: true,
  },
  {
    name: 'Status',
    key: 'status',
    sortable: false,
  },
  {
    name: 'Instrument',
    key: 'instrument',
    sortable: true,
  },
  {
    name: 'Profit/Loss',
    key: 'profit_loss',
    sortable: true,
  },
  {
    name: 'Open date',
    key: 'entry_date',
    sortable: true,
  },
  {
    name: 'Open Price',
    key: 'entry_price',
    sortable: true,
  },
  {
    name: 'Units',
    key: 'qty',
    sortable: true,
  },
  {
    name: 'Close Date',
    key: 'exit_date',
    sortable: true,
  },
  {
    name: 'Close Price',
    key: 'exit_price',
    sortable: true,
  },
  // {
  //   name: 'Return, %',
  //   key: 'profit_loss_percents',
  //   sortable: true,
  // },
  {
    name: 'Account',
    key: 'account',
    sortable: true,
  },
  // {
  //   name: 'Time Frame',
  //   key: 'timeframe',
  //   sortable: true,
  // },
]

const TradesListTable = ({
  dispatch,
  trades,
  trade,
  state,
  user,
  updateState,
  tradesChecked,
  buildQueryStringFromState,
}) => {
  const [isSingleTradeVisible, handleSingleTradeVisibility] = useState(false)

  const handleTableSorting = (item) => {
    if (item.sortable) {
      updateState('page', 1)
      if (item.key === state.orderBy) {
        if (state.orderType === 'DESC') {
          updateState('orderType', 'ASC')
        } else {
          updateState('orderType', 'DESC')
        }
      } else {
        updateState('orderBy', item.key, true, true)
      }
    }
  }

  const checkUncheckTrade = (event, tradeId) => {
    const tradesIdsArr = tradesChecked
    if (event.target.checked) {
      if (!tradesIdsArr.includes(tradeId)) {
        tradesIdsArr.push(tradeId)
      }
    } else {
      tradesIdsArr.splice(tradesIdsArr.indexOf(tradeId), 1)
    }
    updateState('tradesChecked', tradesIdsArr, false)
  }

  const checkUncheckAllTrades = (event) => {
    // console.log(`All trades checked = ${event.target.checked}`)
    let tradesIdsArr = tradesChecked
    trades.forEach((item) => {
      if (event.target.checked) {
        if (!tradesIdsArr.includes(item.id)) {
          tradesIdsArr.push(item.id)
        }
      } else {
        tradesIdsArr = []
      }
    })
    updateState('tradesChecked', tradesIdsArr, false)
  }

  const openSingleTradeDrawer = (slug) => {
    dispatch({
      type: actions.SINGLE,
      payload: {
        slug,
        withData: false,
      },
    })
    handleSingleTradeVisibility(true)
  }

  const closeSingleTradeDrawer = () => {
    dispatch({
      type: actions.RESET_SINGLE_TRADE,
      payload: '',
    })
    handleSingleTradeVisibility(false)
  }

  const renderTableHead = () => {
    return (
      <thead>
        <tr>
          <th>
            <Checkbox onChange={(event) => checkUncheckAllTrades(event)} />
          </th>
          {tableHead.map((item) => {
            return (
              <th
                key={item.key}
                className={classNames(
                  item.sortable && 'sortable',
                  item.key === state.orderBy && 'active',
                )}
                onClick={() => handleTableSorting(item)}
              >
                <span>{item.name}</span>
                {item.key === state.orderBy && (
                  <i
                    className={
                      state.orderType === 'DESC' ? 'fe fe-arrow-down' : 'fe fe-arrow-up'
                    }
                  />
                )}
              </th>
            )
          })}
          <th> </th>
        </tr>
      </thead>
    )
  }

  return (
    <>
      <div className="table-responsive tr-table-responsive">
        {trades.length > 0 ? (
          <table className="table tr-table">
            {renderTableHead()}
            <tbody>
              {trades.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Checkbox
                        onChange={(event) => checkUncheckTrade(event, item.id)}
                        checked={tradesChecked.includes(item.id)}
                      />
                    </td>
                    <td className="font-size__x1-5">
                      {item.action === 0 ? (
                        <i className="fe fe-trending-down danger-color" />
                      ) : (
                        <i className="fe fe-trending-up success-color" />
                      )}
                    </td>
                    <td className="text-nowrap">
                      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */}
                      <span
                        className="badge badge-light mr-2"
                        role="button"
                        onClick={() => openSingleTradeDrawer(item.slug)}
                      >
                        <i className="fe fe-search" /> Details
                      </span>
                      {item.is_open ? (
                        <span className="badge badge-warning">Open</span>
                      ) : (
                        <>
                          {item.profit_loss >= 0 ? (
                            <span className="badge badge-success">Win</span>
                          ) : (
                            <span className="badge badge-danger">Loss</span>
                          )}
                        </>
                      )}
                      {item.share_code !== null && (
                        <a
                          href={`https://tradiry.com/share/${item.share_code}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <ShareAltOutlined className="ml-2 primary-color" />
                        </a>
                      )}
                    </td>
                    <td>
                      {item.get_instrument_reference ? (
                        <>
                          {item.get_instrument_reference.logo &&
                          item.get_instrument_reference.logo_secondary ? (
                            <div className={styles.InstrumentReference}>
                              <div className={styles.Logo}>
                                <div className={styles.Base}>
                                  <img src={item.get_instrument_reference.logo} alt="" />
                                </div>
                                <div className={styles.Quote}>
                                  <img
                                    src={item.get_instrument_reference.logo_secondary}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className={styles.Name}>
                                {item.get_instrument_reference.symbol}
                              </div>
                            </div>
                          ) : (
                            <strong>{item.get_instrument_reference.symbol}</strong>
                          )}
                        </>
                      ) : (
                        <strong>{item.get_instrument && item.get_instrument.name}</strong>
                      )}
                    </td>
                    {item.is_open ? (
                      <td>-</td>
                    ) : (
                      <td
                        className={
                          item.profit_loss >= 0 ? 'success-color' : 'danger-color'
                        }
                      >
                        <strong>{item.profit_loss}</strong>{' '}
                        {user.get_currency && user.get_currency.short_name}
                        <sup className="pl-1">
                          {parseFloat(item.profit_loss_percents).toFixed(2)}%
                        </sup>
                      </td>
                    )}
                    <td>
                      {moment(item.entry_date).format(
                        `${user.date_format} ${user.time_format}`,
                      )}
                    </td>
                    <td>{item.entry_price}</td>
                    <td>{parseFloat(item.qty)}</td>
                    {item.is_open ? (
                      <>
                        <td>-</td>
                        <td>-</td>
                      </>
                    ) : (
                      <>
                        <td>
                          {moment(item.exit_date).format(
                            `${user.date_format} ${user.time_format}`,
                          )}
                        </td>
                        <td>{item.exit_price}</td>
                        {/*<td*/}
                        {/*  className={*/}
                        {/*    item.profit_loss_percents >= 0*/}
                        {/*      ? 'success-color'*/}
                        {/*      : 'danger-color'*/}
                        {/*  }*/}
                        {/*>*/}
                        {/*  {parseFloat(item.profit_loss_percents).toFixed(2)}%*/}
                        {/*</td>*/}
                      </>
                    )}
                    <td>
                      {item.get_account.name.length > 14
                        ? `${item.get_account.name.substr(0, 14)}...`
                        : item.get_account.name}
                    </td>
                    {/*<td>{item.get_time_frame.name}</td>*/}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <Empty
            imageStyle={{
              height: 180,
            }}
            className="pt-5 pb-5 text-center w-100"
            description={<span>Trades not found</span>}
          >
            <Link to="/trades/form/create" className="ant-btn ant-btn-primary">
              Add Your First Trade
            </Link>
          </Empty>
        )}
      </div>
      <SingleTradeDrawer
        dispatch={dispatch}
        trade={trade}
        user={user}
        isSingleTradeVisible={isSingleTradeVisible}
        closeSingleTradeDrawer={closeSingleTradeDrawer}
        buildQueryStringFromState={buildQueryStringFromState}
      />
    </>
  )
}

export default TradesListTable
