import React from 'react'

import HeadStat from './partials/HeadStat'

import longNumberToShort from '../../../../helpers/longNumberToShort'
import NotEnoughData from '../../../ui/messages/NotEnoughData'
import DefaultLoader from '../../../system/Loaders/default'
import Card from '../../card'
import GeneralPerformanceChart from './partials/GeneralPerformance'
import GeneralStats from '../../widgets/dashboard/general-stats'

import styles from './index.module.scss'
import StatsWithProgressWidget from '../../widgets/dashboard/stats-with-progress'

const ReportsOverviewLayout = ({ data, user, dashboard }) => {
  if ((data.error && data.error === 'no-content') || data.success === false) {
    return <NotEnoughData />
  }

  if (!data.stats || !data.stats.totalTrades || !dashboard || !dashboard.success) {
    return <DefaultLoader />
  }

  return (
    <div className={styles.layout}>
      <div className={styles.firstRow}>
        <HeadStat
          title="Total Trades"
          value={new Intl.NumberFormat('en-US').format(
            parseInt(data.stats.totalTrades, 10),
          )}
        />
        <HeadStat
          title="Net Profit/Loss"
          value={`${longNumberToShort(parseFloat(data.stats.totalReturn))} ${
            user.get_currency && user.get_currency.short_name
              ? user.get_currency.short_name
              : ''
          }`}
          helpText="Total realized net profit and loss for closed trades"
        />
        <HeadStat
          title="Profit Factor"
          value={data.stats.profitFactor}
          helpText="The profit factor shows how many times the gross profit (Sum of all the winning trades) exceeds the gross loss (Sum of all the losing trades). The value above 1.0 indicates a profitable trading system."
        />
        <HeadStat
          title="Profitability"
          value={`${data.stats.winPercents}%`}
          helpText={`Won ${data.stats.winTrades} out of ${data.stats.totalTrades} trades, which represents ${data.stats.winPercents}%. Lost ${data.stats.lossTrades} out of ${data.stats.totalTrades} trades, which represents ${data.stats.lossPercents}%.`}
        />
      </div>
      <div className={styles.secondRow}>
        <Card wrapperClass="mb-0">
          <GeneralPerformanceChart data={data} user={user} />
        </Card>
      </div>
      <div className={`${styles.secondRow} mb-0`}>
        <GeneralStats stat={dashboard} user={user} />
      </div>
      <div className={styles.secondRow}>
        <div className="match-height row">
          <div className="col-12 col-md-6 col-xl-4">
            <StatsWithProgressWidget
              title="Hourly Results"
              user={user}
              data={dashboard && dashboard.hourlyStat ? dashboard.hourlyStat : {}}
              isLoading={false}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <StatsWithProgressWidget
              title="Weekday Results"
              user={user}
              data={dashboard && dashboard.weeklyStat ? dashboard.weeklyStat : {}}
              isLoading={false}
            />
          </div>
          <div className="col-12 col-md-12 col-xl-4">
            <StatsWithProgressWidget
              title="Monthly Results"
              user={user}
              data={dashboard && dashboard.monthlyStat ? dashboard.monthlyStat : {}}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportsOverviewLayout
