import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Button, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import actions from '../../../redux/account/actions'

import AccountListItem from '../../../components/ui/accounts/AccountListItem'
import CreateAccountDrawer from '../../../components/ui/accounts/drawers/CreateAccountDrawer'
import DefaultLoader from '../../../components/system/Loaders/default'

const mapStateToProps = ({ account, user }) => ({
  account,
  user,
})

class AccountsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      drawerVisible: false,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.LIST,
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  showCreateAccountDrawer = () => {
    this.setState({ drawerVisible: true })
  }

  onCreateAccountDrawerClose = () => {
    this.setState({ drawerVisible: false })
  }

  render() {
    const pageTitle = 'Accounts'
    const { account, user, dispatch } = this.props
    const { drawerVisible } = this.state

    if (account.isListLoading) {
      return <DefaultLoader />
    }

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button type="primary" key="1" onClick={this.showCreateAccountDrawer}>
                <PlusOutlined />
                <span className="ml-2">Add new account</span>
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__fadeIn">
          <Row className="accounts-list mt-2" gutter={24}>
            {account.accounts.length > 0 &&
              account.accounts.map((item) => {
                return <AccountListItem key={item.id} account={item} user={user.user} />
              })}
          </Row>
        </div>
        <CreateAccountDrawer
          drawerVisible={drawerVisible}
          onDrawerClose={this.onCreateAccountDrawerClose}
          dispatch={dispatch}
        />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(AccountsList))
