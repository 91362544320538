import React from 'react'
import moment from 'moment'

import styles from './news-slider.module.scss'

const SliderItem = ({ item }) => {
  return (
    <div className={styles.Item}>
      <div className={styles.Image}>
        <a
          href={item.url}
          className={styles.Image__Wrapper}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={item.image} alt={item.title} />
        </a>
      </div>
      <div className={styles.Item__Posted}>
        Posted by <span>{item.site}</span>
      </div>
      <h4 className={styles.Item__Title}>
        <a href={item.url} target="_blank" rel="nofollow noopener noreferrer">
          {item.title}
        </a>
      </h4>
      <div className={styles.Item__Text}>{item.text}</div>
      <div className={styles.Item__Info}>
        <div className={styles.Item__Info__Symbol}>{item.symbol}</div>
        <div className={styles.Item__Info__Date}>
          {moment(item.publishedDate).format('DD MMM YY')}
        </div>
      </div>
    </div>
  )
}

export default SliderItem
