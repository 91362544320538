import React from 'react'
import ym from 'react-yandex-metrika'
import { connect } from 'react-redux'
import { Form, Input, Select, Button, Row, Col, message } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import 'moment-timezone'
import styles from '../style.module.scss'

import actions from '../../../../redux/user/actions'

import { DATE_FORMATS, TIME_FORMATS } from '../../../../config/data/date-time'

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
})

const InitAccountComponent = ({ countries, currencies, user, dispatch }) => {
  const onFinish = (values) => {
    dispatch({
      type: actions.INIT_ACCOUNT,
      payload: values,
    })
    ym('reachGoal', 'userFinishedRegistration')
  }

  const onFinishFailed = () => {
    message.error({
      content: 'Something goes wrong. Try again later.',
      key: 'init_user_account',
    })
  }

  return (
    <div>
      <div className={classNames('card', styles.auth_card)}>
        <h1 className={styles.auth_card__title}>Account initialization</h1>
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            country: 1,
            currency: 108,
            timezone: 'Europe/London',
            date_format: 'DD.MM.YYYY',
            time_format: 'HH:mm',
          }}
        >
          <Row gutter={24}>
            <Col span="24" md={{ span: 12 }}>
              <Form.Item
                name="first_name"
                label="First name"
                hasFeedback
                rules={[{ required: true, message: 'Please input your first name' }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span="24" md={{ span: 12 }}>
              <Form.Item
                name="last_name"
                label="Last name"
                hasFeedback
                rules={[{ required: true, message: 'Please input your last name' }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span="24">
              <Form.Item
                name="country"
                label="Country"
                hasFeedback
                rules={[{ required: true, message: 'Please select your country' }]}
              >
                <Select>
                  {countries.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span="24">
              <Form.Item
                name="currency"
                label="Account currency"
                hasFeedback
                rules={[{ required: true, message: 'Please select your currency' }]}
              >
                <Select>
                  {currencies.map((item) => {
                    if (item.id !== 108) {
                      return null
                    }
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span="24">
              <Form.Item
                name="timezone"
                label="Timezone"
                hasFeedback
                rules={[{ required: true, message: 'Please select your timezone' }]}
              >
                <Select>
                  {moment.tz.names().map((item) => {
                    return (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span="24">
              <Form.Item
                name="date_format"
                label="Date format"
                hasFeedback
                rules={[{ required: true, message: 'Please select date format' }]}
              >
                <Select>
                  {DATE_FORMATS.map((item) => {
                    return (
                      <Select.Option value={item.format} key={item.format}>
                        {moment().format(item.format)}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span="24">
              <Form.Item
                name="time_format"
                label="Time format"
                hasFeedback
                rules={[{ required: true, message: 'Please select time format' }]}
              >
                <Select>
                  {TIME_FORMATS.map((item) => {
                    return (
                      <Select.Option value={item.format} key={item.format}>
                        {moment().format(item.format)} ({item.name})
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                className="text-center w-100 fw-600"
                htmlType="submit"
                loading={user.loading}
              >
                Finish
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InitAccountComponent)
