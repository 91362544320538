import React from 'react'
import { Link } from 'react-router-dom'

const UnlockAllFeatures = ({ title, hideButton = false, compact = false }) => (
  <>
    <div
      className={`card card__premium-unlock ${
        compact && 'card__premium-unlock__compact'
      } m-b-30`}
    >
      <div className="card-content card-content__no-title">
        <div className="card-body text-center">
          <h3>
            {title} available for <strong>Premium</strong> accounts only
          </h3>
          <p>Upgrade your account to unlock all features</p>
          {!hideButton && (
            <p>
              Use discount coupon <strong>UPGRADE15</strong> to get 15% off
            </p>
          )}
          {!hideButton && (
            <Link to="/settings/subscription" className="btn btn-sm btn-white">
              Upgrade to <strong>Premium</strong>
            </Link>
          )}
        </div>
      </div>
    </div>
  </>
)

export default UnlockAllFeatures
