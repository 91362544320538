import React from 'react'

import styles from '../styles.module.scss'
import GeneralStatsChart from './chart'

const GeneralStatsItem = ({
  name,
  subTitle,
  result,
  diff24 = 0,
  diff7 = 0,
  diff30 = 0,
  chartData = [189, 208, 198, 200, 207, 192, 201],
}) => {
  return (
    <tr>
      <td className={styles.name}>
        <h4>{name}</h4>
        <p>
          <small dangerouslySetInnerHTML={{ __html: subTitle }} />
        </p>
      </td>
      <td className={styles.result}>{result}</td>
      {/*<td className={styles.percentage}>{diff24}</td>*/}
      {/*<td className={styles.percentage}>{diff7}</td>*/}
      {/*<td className={styles.percentage}>{diff30}</td>*/}
      {/*<td className={styles.chart}>*/}
      {/*  <div className={styles.chartWrapper}>*/}
      {/*    <GeneralStatsChart data={chartData} name={name} />*/}
      {/*  </div>*/}
      {/*</td>*/}
    </tr>
  )
}

export default GeneralStatsItem
