import React from 'react'

import styles from './trade-header.module.scss'
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'

const TradeHeader = ({ trade, user, shareModalVisibility, handleDeleteTrade }) => {
  console.log(trade)

  return (
    <div className={styles.tradeHeader}>
      {trade.get_instrument_reference && trade.get_instrument_reference.id ? (
        <>
          {trade.get_instrument_reference.logo &&
          trade.get_instrument_reference.logo_secondary ? (
            <div className={styles.forexLogo}>
              <img src={trade.get_instrument_reference.logo} alt="" />
              <img src={trade.get_instrument_reference.logo_secondary} alt="" />
            </div>
          ) : null}
        </>
      ) : null}
      <div className={styles.tradeInfo}>
        <h2>
          {trade.get_instrument_reference && trade.get_instrument_reference.name
            ? trade.get_instrument_reference.name
            : trade.get_instrument.name}
        </h2>
        <div className={styles.shortDescription}>
          {trade.action === 0 ? <span>Sell</span> : <span>Buy</span>}{' '}
          <span>
            <small>at</small> {trade.entry_price}{' '}
            {!trade.is_open && (
              <>
                <small>Profit/Loss:</small>{' '}
                <span
                  className={trade.profit_loss >= 0 ? 'success-color' : 'danger-color'}
                >
                  {trade.profit_loss} {user.get_currency && user.get_currency.short_name}{' '}
                  <sup>
                    {trade.profit_loss_percents >= 0.0 ? '+' : ''}
                    {parseFloat(trade.profit_loss_percents || 0).toFixed(2)}%
                  </sup>
                </span>{' '}
                <small>Fees:</small> {trade.fees || 0}{' '}
                {user.get_currency && user.get_currency.short_name}
              </>
            )}
          </span>
        </div>
        <div className={styles.tradeControls}>
          <Button
            type="link"
            className="ant-btn primary-color border-0 ant-btn-link mr-1 pl-0"
            onClick={() => shareModalVisibility(true)}
          >
            <ShareAltOutlined /> Share trade
          </Button>
          <Link
            to={`/trades/form/${trade.slug}`}
            className="ant-btn success-color border-0 ant-btn-link mr-1"
          >
            <EditOutlined /> Edit
          </Link>
          <Popconfirm
            title="Do you want to delete this trade?"
            placement="bottomLeft"
            onConfirm={() => handleDeleteTrade()}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            okText="Delete"
            okType="danger"
          >
            <Button type="link" className="danger-color border-0">
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  )
}

export default TradeHeader
