import React, { useEffect, useRef, useState } from 'react'
import { createChart, ColorType, CrosshairMode, LineStyle } from 'lightweight-charts'
import moment from 'moment'
import _ from 'lodash'

import BlockWrapper from './BlockWrapper'
import DefaultLoader from '../../../../system/Loaders/default'

import axiosRequest from '../../../../../helpers/axios-request'
import axiosError from '../../../../../helpers/axios-error'
import colors from '../../../../../config/apexcharts/colors'

import styles from './trade-chart.module.scss'

const TradeChart = ({ trade }) => {
  const chartContainerRef = useRef()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    axiosRequest(`/api/v2/twelve-data/trade-time-series/${trade.slug}`, 'get')
      .then((res) => {
        let chartData = []
        if (res.data && res.data.data.data && res.data.data.data.values) {
          chartData = _.map(res.data.data.data.values, (item) => {
            return {
              high: parseFloat(item.high),
              low: parseFloat(item.low),
              open: parseFloat(item.open),
              close: parseFloat(item.close),
              time: moment(item.datetime).unix(),
            }
          })
        }
        setData(_.sortBy(chartData, 'time', 'ASC'))
      })
      .catch((e) => {
        axiosError(e)
      })
      .finally(() => setLoading(false))
  }, [trade])

  useEffect(() => {
    let chart = null
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth })
    }
    const countDecimals = function (value) {
      if (Math.floor(value) === value) return 0
      return value.toString().split('.')[1].length || 0
    }
    const myPriceFormatter = (p) =>
      p.toFixed(countDecimals(parseFloat(trade.entry_price)))
    if (!loading && data.length > 0 && chartContainerRef && chartContainerRef.current) {
      chart = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: colors.sidebarBg },
          textColor: colors.fontColor,
        },
        grid: {
          vertLines: { color: colors.borderColor },
          horzLines: { color: colors.borderColor },
        },
        width: chartContainerRef.current.clientWidth,
        height:
          chartContainerRef.current.clientWidth <= 740
            ? 350
            : parseInt((5 * chartContainerRef.current.clientWidth) / 16, 10).toString(),
        timeScale: {
          timeVisible: true,
        },
        localization: {
          priceFormatter: myPriceFormatter,
        },
      })
      chart.timeScale().fitContent()

      // Setting the border color for the vertical axis
      chart.priceScale('right').applyOptions({
        borderColor: colors.borderColor,
      })

      // Setting the border color for the horizontal axis
      chart.timeScale().applyOptions({
        borderColor: colors.borderColor,
      })

      chart.applyOptions({
        crosshair: {
          // Change mode from default 'magnet' to 'normal'.
          // Allows the crosshair to move freely without snapping to datapoints
          mode: CrosshairMode.Normal,
          // Vertical crosshair line (showing Date in Label)
          vertLine: {
            color: colors.gridLinesColor,
            labelBackgroundColor: colors.primaryColorLight,
          },
          // Horizontal crosshair line (showing Price in Label)
          horzLine: {
            color: colors.gridLinesColor,
            labelBackgroundColor: colors.primaryColorLight,
          },
        },
      })

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderVisible: false,
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
      })

      candlestickSeries.setData(data)

      // Executions
      const chartMarkers = []
      const entryDate = moment(trade.entry_date, 'YYYY-MM-DD HH:mm:ss').unix()
      const exitDate = moment(trade.exit_date, 'YYYY-MM-DD HH:mm:ss').unix()
      if (trade.get_executions && trade.get_executions.length > 0) {
        trade.get_executions.forEach((exec) => {
          const execDate = moment(exec.execution_date, 'YYYY-MM-DD HH:mm:ss').unix()
          chartMarkers.push({
            time: execDate,
            position: exec.action === 0 ? 'aboveBar' : 'belowBar',
            color: exec.action === 0 ? '#e91e63' : '#2196F3',
            shape: exec.action === 0 ? 'arrowDown' : 'arrowUp',
            text: `${exec.action === 0 ? 'Sell' : 'Buy'} ${exec.qty} at ${parseFloat(
              exec.execution_price,
            )}`,
          })
        })
      } else {
        chartMarkers.push({
          time: entryDate,
          position: trade.action === 0 ? 'aboveBar' : 'belowBar',
          color: trade.action === 0 ? '#e91e63' : '#2196F3',
          shape: trade.action === 0 ? 'arrowDown' : 'arrowUp',
          text: `Open ${parseFloat(trade.entry_price)}`,
        })
        chartMarkers.push({
          time: exitDate,
          position: trade.action === 0 ? 'belowBar' : 'aboveBar',
          color: '#f68410',
          shape: 'circle',
          text: `Closed ${parseFloat(trade.exit_price)}`,
        })
      }
      candlestickSeries.setMarkers(chartMarkers)

      // TP & SL
      if (trade.take_profit && parseFloat(trade.take_profit)) {
        candlestickSeries.createPriceLine({
          price: parseFloat(trade.take_profit),
          color: colors.successColor,
          lineWidth: 1,
          axisLabelVisible: true,
          title: 'Take Profit',
        })
      }
      if (trade.stop_loss && parseFloat(trade.stop_loss)) {
        candlestickSeries.createPriceLine({
          price: parseFloat(trade.stop_loss),
          color: colors.dangerColor,
          lineWidth: 1,
          axisLabelVisible: true,
          title: 'Stop Loss',
        })
      }

      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (chart) {
        window.removeEventListener('resize', handleResize)

        chart.remove()
      }
    }
  }, [data, loading])

  return (
    <BlockWrapper title="Trade Chart">
      <div className={styles.tradeChart}>
        {loading ? <DefaultLoader /> : <div ref={chartContainerRef}></div>}
      </div>
    </BlockWrapper>
  )
}

export default TradeChart
