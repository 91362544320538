import React from 'react'
import axios from 'axios'
import { API_URL } from '../../../../config/app'
import { Button, Empty, message } from 'antd'
import { QUILL_FORMATS, QUILL_MODULES } from '../../../../config/quill-config'
import ReactQuill from 'react-quill'

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
}

const AdvancedCard = ({ trade, updateData }) => {
  const removeImage = (index) => {
    const newImagesArray = trade.images
    const formData = new FormData()
    formData.append('image', newImagesArray[index])
    newImagesArray.splice(index, 1)
    updateData('images', newImagesArray)
    axios
      .post(`${API_URL}/api/v1/images/remove`, formData, config)
      .then(() => {
        message.success('Images was removed')
      })
      .catch(() => {
        message.error('Something goes wrong, try again later...')
      })
  }

  const uploadImages = (event) => {
    message.loading({ content: 'Loading...', key: 'upload_trade_images', duration: 20 })
    const formData = new FormData()
    const { files } = event.target
    if (event.target.files[0]) {
      for (let i = 0; i < files.length; i += 1) {
        formData.append('images[]', files[i])
      }
    }
    axios
      .post(`${API_URL}/api/v1/images/upload`, formData, config)
      .then((res) => {
        const newImagesArray = trade.images
        res.data.images.forEach((item) => {
          newImagesArray.push(item)
        })
        updateData('images', newImagesArray)
        message.success({ content: 'Success', key: 'upload_trade_images' })
      })
      .catch(() => {
        message.error({
          content: 'Something goes wrong. Try again later.',
          key: 'upload_trade_images',
        })
      })
      .finally(() => {
        event.target.value = ''
      })
  }
  return (
    <>
      <h5 className="trade-form__subtitle">Screenshots</h5>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <div className="row">
            {trade.images[0]
              ? trade.images.map((item, i) => {
                  return (
                    <div className="col-12 col-md-4 col-lg-3" key={item}>
                      <img src={item} className="img-fluid" alt="" />
                      <div>
                        <Button
                          type="link"
                          className="border-0 danger-color mt-1"
                          onClick={() => removeImage(i)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
        <div className="col-12 mb-5">
          <input
            type="file"
            name="images[]"
            accept="image/*"
            multiple
            onChange={(event) => uploadImages(event)}
          />
        </div>
      </div>
      <h5 className="trade-form__subtitle">Additional Information</h5>
      <div className="row">
        <div className="col-12">
          <ReactQuill
            theme="snow"
            onChange={(html) => updateData('data.description', html)}
            value={trade.data.description || null}
            formats={QUILL_FORMATS}
            modules={QUILL_MODULES}
          />
        </div>
      </div>
    </>
  )
}

export default AdvancedCard
