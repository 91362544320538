import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Pagination } from 'antd'

import DefaultLoader from '../../../components/system/Loaders/default'
import MarketNewsHeader from '../../../components/ui-2/market-news/MarketNewsHeader'

import axiosRequest from '../../../config/axios'
import axiosError from '../../../helpers/axios-error'
import NewsItem from '../../../components/ui-2/market-news/NewsItem'

const ForexNews = () => {
  const [loading, setLoading] = useState(true)
  const [symbolsLoading, setSymbolsLoading] = useState(true)
  const [data, setData] = useState({ totalPages: 0, data: [] })
  const [symbols, setSymbols] = useState([])
  const [filters, setFilters] = useState({
    endpoint: 'category',
    section: 'general',
    items: 100,
    page: 1,
    search: '',
  })

  useEffect(() => {
    setLoading(true)
    setSymbolsLoading(true)
    // Get Symbols
    axiosRequest(
      `/api/v2/market-news/forex?ttl=864000&endpoint=account/tickersdbv2?items=1000`,
      'get',
      null,
      true,
    )
      .then((res) => {
        setSymbols([])
      })
      .catch((e) => {
        axiosError(e)
        setSymbols([])
      })
      .finally(() => {
        setSymbolsLoading(false)
      })
    // Get News
    const query = `${filters.endpoint}?t=1%26page=${filters.page}%26items=${
      filters.items
    }${filters.search && filters.search.length > 1 ? `%26search=${filters.search}` : ''}${
      filters.section && filters.section.length > 1 ? `%26section=${filters.section}` : ''
    }`
    axiosRequest(`/api/v2/market-news/forex?&endpoint=${query}`, 'get', null, true)
      .then((res) => {
        if (res.data && res.data.news && res.data.news.data) {
          setData({ data: res.data.news.data, totalPages: res.data.news.total_pages })
        }
      })
      .catch((e) => {
        axiosError(e)
        setData([])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filters])

  return (
    <>
      <Helmet title="Forex News" />
      <MarketNewsHeader
        title="Forex News"
        subtitle="Latest news from the best news sources"
        filters={filters}
        setFilters={setFilters}
      />
      {loading || symbolsLoading ? (
        <DefaultLoader />
      ) : (
        <>
          <div>
            {data.data.map((item) => (
              <NewsItem key={item.news_url} item={item} />
            ))}
          </div>
        </>
      )}
      {data.totalPages && data.totalPages > 1 ? (
        <div className="mt-4 mb-5 text-center">
          <Pagination
            responsive
            pageSize={filters.items}
            current={filters.page}
            total={filters.items * data.totalPages}
            showSizeChanger={false}
            onChange={(page) => setFilters({ ...filters, page })}
          />
        </div>
      ) : null}
    </>
  )
}

export default ForexNews
