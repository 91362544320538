import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Spin, Progress } from 'antd'

import Card from '../../../card'

import styles from './styles.module.scss'
import longNumberToShort from '../../../../../helpers/longNumberToShort'

const StatsWithProgressWidget = ({ title, data, user, isLoading = true }) => {
  return (
    <Card title={title}>
      <Spin spinning={isLoading}>
        <div className={styles.widgetWithProgress}>
          <PerfectScrollbar>
            <div className={styles.wrapper}>
              {data ? (
                <>
                  {Object.keys(data).map((key) => {
                    if (data[key].trades === 0) {
                      return null
                    }
                    const winRate = Math.round((data[key].win * 100) / data[key].trades)
                    const plPercents =
                      Math.round((data[key].earnPercents + data[key].lossPercents) * 10) /
                      10
                    return (
                      <div key={data[key].name} className={styles.item}>
                        <div className={styles.iconWrapper}>
                          <div className={styles.icon}>
                            <span>{data[key].name.substring(0, 2)}</span>
                          </div>
                        </div>
                        <div className={styles.name}>
                          <h4>{data[key].name}</h4>
                          <p>
                            Closed {new Intl.NumberFormat().format(data[key].trades)}{' '}
                            trades.
                            <br /> Profit/Loss{' '}
                            {longNumberToShort(
                              data[key].earnMoney + data[key].lossMoney,
                            )}{' '}
                            {user.get_currency && user.get_currency.short_name
                              ? user.get_currency.short_name
                              : ''}{' '}
                            {plPercents !== 0.0 ? (
                              <>
                                <sup className={plPercents < 0 ? styles.danger : ''}>
                                  {plPercents}%
                                </sup>
                              </>
                            ) : null}
                          </p>
                        </div>
                        <div className={styles.info}>
                          <div
                            className={`${styles.percents} ${
                              winRate < 50 && winRate > 33 ? styles.percentsWarning : ''
                            } ${winRate <= 33 ? styles.percentsDanger : ''}`}
                          >
                            <h5>{winRate}%</h5>
                            <p>
                              <small>Win Rate</small>
                            </p>
                          </div>
                          <div className={styles.progress}>
                            <Progress
                              type="circle"
                              percent={winRate}
                              width={24}
                              strokeWidth={16}
                              strokeColor={
                                winRate >= 50
                                  ? '#00ab49'
                                  : winRate <= 33
                                  ? '#e60022'
                                  : '#eb9c00'
                              }
                              trailColor={
                                winRate >= 50
                                  ? 'rgba(0, 171, 73, 0.1)'
                                  : winRate <= 33
                                  ? 'rgba(230, 0, 34, 0.1)'
                                  : 'rgba(235, 156, 0, 0.1)'
                              }
                              showInfo={false}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : null}
            </div>
          </PerfectScrollbar>
        </div>
      </Spin>
    </Card>
  )
}

export default StatsWithProgressWidget
