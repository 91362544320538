import React from 'react'
import { Alert, Button, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import Card from '../../../ui-2/card'

import axiosRequest from '../../../../config/axios'
import axiosError from '../../../../helpers/axios-error'

const DeleteAccount = () => {
  const [loading, setLoading] = React.useState(false)
  const deleteAccount = () => {
    setLoading(true)
    axiosRequest('/api/v1/user/settings/general/delete-account', 'delete', null, true)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        axiosError(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Card title="Delete Account">
      <div className="mb-4">
        <Alert
          type="warning"
          message="Are you sure you want to delete your account?"
          description="This will immediately delete all of your data including trades, accounts, trading systems, and more. This can’t be undone."
        />
      </div>
      <div className="text-right">
        <Popconfirm
          title="We'll be sorry to see you go, but thanks for trying Tradiry!"
          onConfirm={() => deleteAccount()}
          okText="Delete"
          okButtonProps={{ className: 'btn-danger', loading }}
          icon={<QuestionCircleOutlined style={{ color: '#e60022' }} />}
        >
          <Button
            size="large"
            loading={loading}
            className="btn-danger text-center fw-600"
          >
            Delete Account
          </Button>
        </Popconfirm>
      </div>
    </Card>
  )
}

export default DeleteAccount
