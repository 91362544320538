const tradeFormValidator = (trade) => {
  const { tradeOption, entryPrice, stopLoss, takeProfit, tradeQty, tradeInstrument, entryDate, exitDate } = trade
  // Check prices
  if (tradeOption === 0) {
    if (parseFloat(entryPrice) > parseFloat(stopLoss) && parseFloat(stopLoss) > 0) {
      return 'Entry price can not be more than stop loss'
    }
    if (parseFloat(takeProfit) > parseFloat(entryPrice) && parseFloat(takeProfit) > 0) {
      return 'Entry price can not be less than take profit'
    }
    if (parseFloat(takeProfit) > parseFloat(stopLoss) && parseFloat(stopLoss) > 0 && parseFloat(takeProfit) > 0) {
      return 'Take profit can not be more than stop loss'
    }
  } else {
    if (parseFloat(entryPrice) < parseFloat(stopLoss) && parseFloat(stopLoss) > 0) {
      return 'Entry price can not be less than stop loss'
    }
    if (parseFloat(takeProfit) < parseFloat(entryPrice) && parseFloat(takeProfit) > 0) {
      return 'Entry price can not be more than take profit'
    }
    if (parseFloat(takeProfit) < parseFloat(stopLoss) && parseFloat(stopLoss) > 0 && parseFloat(takeProfit) > 0) {
      return 'Take profit can not be less than stop loss'
    }
  }
  // Check entry and exit dates
  if (entryDate > exitDate) {
    return 'Exit date can not be earlier than entry date'
  }
  // Check if instrument is set
  if (tradeInstrument.length < 2) {
    return 'Trade instrument field is required'
  }
  // Check trade QTY
  if (tradeQty <= 0) {
    return 'Trade quantity should be more that zero'
  }
  return true
}

export default tradeFormValidator
