import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { withRouter } from 'react-router-dom'
import styles from './style.module.scss'

import Header from '../../components/layout/Header'
import LayoutAlerts from '../../components/layout/LayoutAlerts'
import Sidebar from '../../components/layout/Sidebar'
import Footer from '../../components/layout/Footer'

const mapStateToProps = ({ user, filter }) => ({
  user,
  filter,
})

const MainLayout = ({ children, filter, user }) => {
  const { pathname } = useLocation()
  const [mobileMenu, setMobileMenu] = useState(false)

  useEffect(() => {
    setMobileMenu(false)
  }, [pathname])

  const handleMobileMenu = (e) => {
    e.preventDefault()
    setMobileMenu(!mobileMenu)
  }

  return (
    <div
      className={`${styles.mainLayout} ${
        mobileMenu ? styles.mainLayoutMenuOpened : 'menu-closed'
      }`}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div className={styles.mobileMenuOverlay} onClick={(e) => handleMobileMenu(e)} />
      <aside className={styles.mainLayoutSidebar}>
        <Sidebar />
      </aside>
      <div className={styles.mainLayoutContent}>
        <Header filter={filter} handleMobileMenu={handleMobileMenu} />
        <div className={styles.headerOverlay} />
        <div className={styles.contentInner}>
          <main className={styles.contentContainer}>
            <LayoutAlerts user={user} />
            <div>{children}</div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}

// class MainLayout extends React.Component {
//   render() {
//     const { filter, children, user } = this.props
//
//
//     // return (
//     //   <Layout className={classNames('tr-layout')}>
//     //     <Sidebar user={user.user} />
//     //     <Layout>
//     //       <Header filter={filter} />
//     //       <Layout.Content className={styles.tr_main}>
//     //         <LayoutAlerts user={user} />
//     //         {children}
//     //       </Layout.Content>
//     //       <Footer />
//     //     </Layout>
//     //   </Layout>
//     // )
//   }
// }

export default withRouter(connect(mapStateToProps)(MainLayout))
