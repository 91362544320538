import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader } from 'antd'

// eslint-disable-next-line import/no-cycle
import Calendar from '../../../components/ui/calendar/Calendar'
import UnlockAllFeatures from '../../../components/system/Subscription/UnlockAllFeatures'

const mapStateToProps = ({ user }) => ({ user })

class CalendarPage extends React.Component {
  render() {
    const pageTitle = 'Calendar'
    const { user } = this.props
    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Tools</Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {user.user.is_premium === 1 ? (
            <Calendar user={user.user} />
          ) : (
            <UnlockAllFeatures title="Calendar" compact />
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(CalendarPage))
