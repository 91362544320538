import React, { useState } from 'react'
import { Button, Form, Modal, Select } from 'antd'
import axiosRequest from '../../../config/axios'
import axiosError from '../../../helpers/axios-error'

const CancelSubscriptionModal = ({ visible, setVisibility }) => {
  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    axiosRequest(
      `/api/v1/user/subscription/pay-pro-global/cancel/${values.reason}`,
      'get',
      null,
      true,
    )
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        axiosError(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title="Cancel Subscription"
      open={visible}
      onCancel={() => setVisibility(false)}
      destroyOnClose
      footer={false}
    >
      <Form layout="vertical" requiredMark={false} onFinish={onFinish}>
        <Form.Item
          name="reason"
          label="Reason"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select>
            <Select.Option value={1}>The price is too high</Select.Option>
            <Select.Option value={2}>I no longer need this product</Select.Option>
            <Select.Option value={3}>I am not satisfied with the product</Select.Option>
            <Select.Option value={4}>I switched to another product</Select.Option>
            <Select.Option value={5}>It isn't a priority right now</Select.Option>
            <Select.Option value={6}>I subscribed by mistake</Select.Option>
            <Select.Option value={8}>
              I am switching to another subscription plan
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className="mb-0">
          <Button className="btn btn-primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CancelSubscriptionModal
