const theme = localStorage.getItem('trLayout')

const colors = {
  primaryColor: '#007be8',
  primaryHover: '#42baf9',
  primaryColorLight: 'rgba(0,123,232,0.1)',
  successColor: '#00ab49',
  successColorLight: 'rgba(0,171,73,0.1)',
  dangerColor: '#e60022',
  dangerColorLight: 'rgba(230,0,34,0.1)',
  dangerHover: '#e73057',
  warningColor: '#eb9c00',
  purpleColor: '#7927a2',
  layoutBg: theme && theme === 'dark' ? '#000000' : '#ffffff',
  sidebarBg: theme && theme === 'dark' ? '#000000' : '#ffffff',
  gridLinesColor:
    theme && theme === 'dark' ? 'rgba(207, 219, 213, 0.2)' : 'rgba(207, 219, 213, 0.6)',
  labelsColor: theme && theme === 'dark' ? '#d0d2d6' : '#c8c4db',
  fontColor: theme && theme === 'dark' ? '#d0d2d6' : '#141333',
  valueColor: theme && theme === 'dark' ? '#d0d2d6' : '#475f7b',
  labelColor: theme && theme === 'dark' ? '#d0d2d6' : '#596778',
  grayLight: theme && theme === 'dark' ? '#d0d2d6' : '#828D99',
  white: theme && theme === 'dark' ? '#d0d2d6' : '#fff',
  pieRound: theme && theme === 'dark' ? '#283046' : '#FFF',
  borderColor: theme && theme === 'dark' ? '#323248' : '#e4e6ef',
  fontFamily: 'Nunito Sans, Roboto, Arial, sans-serif',
}

export default colors
