import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader } from 'antd'

import actions from '../../../redux/stat/actions'
import createUrlParamsStringFromFilters from '../../../helpers/createUrlParamsFromFilters'
import reloadPageWithUrlParams from '../../../helpers/reloadPageWithUrlParams'
// eslint-disable-next-line import/no-cycle
import checkIfUserHaveSubscription from '../../../helpers/premiumUserCheck'

import ReportsOverviewLoader from '../../../components/system/Loaders/reports-loaders/OverviewLoader'
import HoldingTimeChart from '../../../components/ui/reports/holding-time/HoldingTimeChart'
import HoldingTimeStats from '../../../components/ui/reports/holding-time/HoldingTimeStats'
import NotEnoughData from '../../../components/ui/messages/NotEnoughData'

const mapStateToProps = ({ stat, filter, user }) => ({ stat, filter, user })

class HoldingTimeReportPage extends React.Component {
  componentDidMount() {
    const { dispatch, filter, user } = this.props
    if (checkIfUserHaveSubscription(user.user)) {
      dispatch({
        type: actions.HOLDING_TIME,
        payload: {
          query: createUrlParamsStringFromFilters(filter),
        },
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filter, dispatch } = this.props
    if (filter !== prevProps.filter) {
      reloadPageWithUrlParams(
        dispatch,
        actions.HOLDING_TIME,
        createUrlParamsStringFromFilters(filter),
      )
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  render() {
    const pageTitle = 'Holding Time'
    const { stat, user } = this.props

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/overview">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {!stat.holdingTime.data || stat.isLoading ? (
            <ReportsOverviewLoader />
          ) : (
            <>
              {stat.holdingTime.data.error &&
              stat.holdingTime.data.error === 'no-content' ? (
                <NotEnoughData />
              ) : (
                <div className="row">
                  <div className="col-12">
                    <p className="muted-color fw-300">
                      Current chart show the holding time of your trades and how it
                      impacts your trading performance.
                    </p>
                  </div>
                  <div className="col-12 col-md-8 col-lg-9">
                    <HoldingTimeChart
                      chart={stat.holdingTime.data.chart}
                      user={user.user}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <HoldingTimeStats data={stat.holdingTime.data} user={user.user} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(HoldingTimeReportPage))
