import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'

import axiosRequest from '../../config/axios'
import actions from './actions'

/**
 * Dashboard request
 */
export function* DASHBOARD({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/stats/dashboard?${payload.query && payload.query}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, dashboard: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Reporting overview request
 */
export function* REPORTS_OVERVIEW({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/reports/overview?${payload.query && payload.query}`,
      'get',
      null,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, reportsOverview: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Hourly report request
 */
export function* HOURLY_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/reports/hourly?${payload.query && payload.query}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, hourlyReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Weekday report request
 */
export function* WEEKDAY_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/reports/weekday?${payload.query && payload.query}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, weekdayReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Monthly report request
 */
export function* MONTHLY_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/reports/monthly?${payload.query && payload.query}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, monthlyReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Holding time report request
 */
export function* HOLDING_TIME({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/reports/holding-time?${payload.query && payload.query}`,
      'get',
      null,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, holdingTime: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Volume report request
 */
export function* VOLUME_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/reports/volume?${payload.query && payload.query}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, volumeReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Advanced stats report request
 */
export function* ADVANCED_STAT_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/reports/advanced-stats?${payload.query && payload.query}`,
      'get',
      null,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, advancedStatsReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Advanced stats report request
 */
export function* INSTRUMENTS_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/reports/instruments?${payload.query && payload.query}`,
      'get',
      null,
      true,
    )
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, instrumentsReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * MAE/MFE report request
 */
export function* MAE_MFE_REPORT({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
    const req = yield call(axiosRequest, `/api/v1/reports/mae-mfe?${payload.query && payload.query}`, 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { isLoading: false, maeMfeReport: req.data } })
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    notification.error({ message: 'Error', description: 'Something goes wrong, try again later.' })
    yield put({ type: actions.SET_STATE, payload: { isLoading: true } })
  }
}

/**
 * Reset accounts state
 */
export function RESET() {
  put({ type: actions.RESET })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DASHBOARD, DASHBOARD),
    takeEvery(actions.REPORTS_OVERVIEW, REPORTS_OVERVIEW),
    takeEvery(actions.HOURLY_REPORT, HOURLY_REPORT),
    takeEvery(actions.WEEKDAY_REPORT, WEEKDAY_REPORT),
    takeEvery(actions.MONTHLY_REPORT, MONTHLY_REPORT),
    takeEvery(actions.HOLDING_TIME, HOLDING_TIME),
    takeEvery(actions.VOLUME_REPORT, VOLUME_REPORT),
    takeEvery(actions.ADVANCED_STAT_REPORT, ADVANCED_STAT_REPORT),
    takeEvery(actions.INSTRUMENTS_REPORT, INSTRUMENTS_REPORT),
    takeEvery(actions.MAE_MFE_REPORT, MAE_MFE_REPORT),
    takeEvery(actions.RESET, RESET),
  ])
}
