import React from 'react'
import _ from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Checkbox } from 'antd'

import styles from './filters.module.scss'

// eslint-disable-next-line no-unused-vars
const SimulatorFilters = ({ filtersData, filters, setFilters }) => {
  const onChange = (group, e) => {
    const newFiltersArray = filters
    if (e.target.checked) {
      newFiltersArray[group].push(e.target.value)
    } else {
      const findIndex = _.findIndex(newFiltersArray[group], (d) => d === e.target.value)
      if (findIndex === -1) {
        return null
      }
      newFiltersArray[group].splice(findIndex, 1)
    }
    setFilters(newFiltersArray)
    return true
  }

  return (
    <div className="card card__stat m-b-30">
      <div className="card-header">
        <h3 className="card-title">Filters</h3>
      </div>
      <div className="card-content">
        <div className={styles.simulatorFilters}>
          <PerfectScrollbar>
            <div className={styles.filtersScrollbar}>
              {filtersData.instruments && (
                <div className={styles.filtersWrapper}>
                  <h3 className={styles.title}>Instruments</h3>
                  <div className="row simulator-checkbox-group">
                    {filtersData.instruments.map((instrument) => (
                      <div className="col-6 col-lg-4" key={instrument.value}>
                        <Checkbox
                          value={instrument.value}
                          onChange={(e) => onChange('instruments', e)}
                          checked={filters.instruments.includes(instrument.value)}
                        >
                          {instrument.label}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {filtersData.hours && (
                <div className={styles.filtersWrapper}>
                  <h3 className={styles.title}>Hours</h3>
                  <div className="row simulator-checkbox-group">
                    {filtersData.hours.map((hour) => (
                      <div className="col-6 col-lg-4" key={hour.value}>
                        <Checkbox
                          value={hour.value}
                          onChange={(e) => onChange('hours', e)}
                          checked={filters.hours.includes(hour.value)}
                        >
                          {hour.label}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {filtersData.weekdays && (
                <div className={styles.filtersWrapper}>
                  <h3 className={styles.title}>Weekdays</h3>
                  <div className="row simulator-checkbox-group">
                    {filtersData.weekdays.map((weekday) => (
                      <div className="col-6 col-lg-4" key={weekday.value}>
                        <Checkbox
                          value={weekday.value}
                          onChange={(e) => onChange('weekdays', e)}
                          checked={filters.weekdays.includes(weekday.value)}
                        >
                          {weekday.label}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {filtersData.months && (
                <div className={styles.filtersWrapper}>
                  <h3 className={styles.title}>Months</h3>
                  <div className="row simulator-checkbox-group">
                    {filtersData.months.map((month) => (
                      <div className="col-6 col-lg-4" key={month.value}>
                        <Checkbox
                          value={month.value}
                          onChange={(e) => onChange('months', e)}
                          checked={filters.months.includes(month.value)}
                        >
                          {month.label}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}

export default SimulatorFilters
