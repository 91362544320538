import React from 'react'
import moment from 'moment'

import styles from './news-item.module.scss'

const NewsItem = ({ item }) => {
  return (
    <div className={styles.newsItem}>
      {item.image_url && (
        <div className={styles.image}>
          <a href={item.news_url} target="_blank" rel="noreferrer nofollow noopener">
            <img src={item.image_url} alt={item.title} />
          </a>
        </div>
      )}
      <div className={styles.content}>
        <h2>
          <a href={item.news_url} target="_blank" rel="noreferrer nofollow noopener">
            {item.title}
          </a>
        </h2>
        <p>{item.text}</p>
        <div className={styles.info}>
          <ul>
            <li>Date: {moment(item.date).format('MM/DD/YYYY HH:mm')}</li>
            {item.source_name && <li>Source: {item.source_name}</li>}
            {item.sentiment && (
              <li data-sentiment={item.sentiment}>Sentiment: {item.sentiment}</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default NewsItem
