import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import Chart from 'react-apexcharts'

import Card from '../../../card'

import styles from './styles.module.scss'

const MarketsWidget = ({ stat }) => {
  const [data, setData] = useState({
    crypto: 0,
    forex: 0,
    futures: 0,
    options: 0,
    stock: 0,
  })

  useEffect(() => {
    if (!stat.isLoading && stat.dashboard && stat.dashboard.marketsStat) {
      const { crypto, forex, futures, options, stock, totalTrades } =
        stat.dashboard.marketsStat
      setData({
        crypto: Math.round((crypto * 100) / totalTrades),
        forex: Math.round((forex * 100) / totalTrades),
        futures: Math.round((futures * 100) / totalTrades),
        options: Math.round((options * 100) / totalTrades),
        stock: Math.round((stock * 100) / totalTrades),
      })
    }
  }, [stat])

  return (
    <Card>
      <Spin spinning={stat.isLoading}>
        <div className={styles.marketsWidget}>
          <Chart
            series={[data.forex, data.stock, data.crypto, data.futures, data.options]}
            options={{
              chart: {
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  offsetY: 0,
                  startAngle: 0,
                  endAngle: 270,
                  hollow: {
                    margin: 5,
                    size: '30%',
                    background: 'transparent',
                    image: undefined,
                  },
                  dataLabels: {
                    name: {
                      show: false,
                    },
                    value: {
                      show: false,
                    },
                  },
                },
              },
              stroke: {
                lineCap: 'round',
              },
              colors: ['#00ab49', '#007be8', '#e60022', '#eb9c00', '#2c60e4'],
              labels: ['Forex', 'Stock', 'Crypto', 'Futures', 'Options'],
              legend: {
                show: true,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 40,
                offsetY: 0,
                labels: {
                  useSeriesColors: true,
                },
                markers: {
                  size: 0,
                },
                formatter: function (seriesName, opts) {
                  return (
                    seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
                  )
                },
                itemMargin: {
                  vertical: 3,
                },
              },
              responsive: [
                {
                  breakpoint: 1700,
                  options: {
                    legend: {
                      offsetY: -10,
                      offsetX: 20,
                      fontSize: '12px',
                      position: 'left',
                      itemMargin: {
                        vertical: 0,
                      },
                    },
                  },
                },
                {
                  breakpoint: 1140,
                  options: {
                    chart: {
                      height: 400,
                    },
                    legend: {
                      fontSize: '12px',
                      position: 'left',
                      itemMargin: {
                        vertical: 1,
                      },
                    },
                  },
                },
                {
                  breakpoint: 500,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      offsetY: -10,
                      fontSize: '12px',
                      position: 'left',
                      itemMargin: {
                        vertical: 1,
                      },
                    },
                  },
                },
              ],
            }}
            type="radialBar"
          />
        </div>
      </Spin>
    </Card>
  )
}

export default MarketsWidget
