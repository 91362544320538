import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import axiosRequest from '../../../../config/axios'
import axiosError from '../../../../helpers/axios-error'
import { Breadcrumb, PageHeader, Skeleton } from 'antd'

const StockDetails = () => {
  const { slug } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)
    axiosRequest(`/api/v1/analysis/stock/single/${slug}`, 'get', null, true)
      .then((res) => {
        setData(res.data.details)
      })
      .catch((e) => axiosError(e))
      .finally(() => setLoading(false))
  }, [slug])

  return (
    <>
      <Helmet
        title={
          loading || !data || !data._id
            ? 'Loading...'
            : `${data.name} (${data.symbol}) Stock Price, Quote and News`
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/analysis/stocks">Stocks Analysis</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{`${data.name} (${data.symbol}) Overview`}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={`${data.name} (${data.symbol})`}
            subTitle={`${data.exchangeShortName}: ${data.symbol} · ${data.currency}`}
          />
        </>
      )}
    </>
  )
}

export default StockDetails
