import React from 'react'
import ym from 'react-yandex-metrika'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import classNames from 'classnames'
import styles from '../style.module.scss'

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
})

const LoginComponent = ({ user, dispatch }) => {
  const onFinish = (values) => {
    dispatch({
      type: 'user/LOGIN',
      payload: values,
    })
    ym('reachGoal', 'userLoggedIn')
  }

  const onFinishFailed = () => {
    message.error('Error')
  }

  const validateMessages = {
    required: 'This field is required!',
    types: {
      email: 'Enter an email address',
    },
  }

  return (
    <div>
      <div className={classNames('card', styles.auth_card)}>
        <h1 className={styles.auth_card__title}>Welcome to Tradiry</h1>
        <p className="mb-4">Please sign-in to your account and start the adventure</p>
        {/* begin auth form */}
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mb-4"
          validateMessages={validateMessages}
        >
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              { required: true, message: 'Please input your e-mail address' },
              { type: 'email' },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
          <div className="text-right">
            <Link to="/secured/forgot-password">
              <small>Forgot Password?</small>
            </Link>
          </div>
          <Form.Item
            name="password"
            hasFeedback
            rules={[{ required: true, message: 'Please input your password' }]}
          >
            <Input size="large" type="password" placeholder="Password" />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            className="text-center w-100 fw-600"
            htmlType="submit"
            loading={user.loading}
          >
            Sign in
          </Button>
        </Form>
        {/* end auth form */}
      </div>
      {/* begin sign up link */}
      <div className="text-center mt-3 mb-auto">
        <span className="mr-2">Don&#39;t have an account?</span>
        <Link to="/secured/register">Sign up</Link>
      </div>
      {/* end sign up link */}
    </div>
  )
}

export default connect(mapStateToProps)(LoginComponent)
