import axios from 'axios'
import { API_URL } from '../config/app'

const axiosRequest = (url, method, data = null, withApiToken = true) => {
  let config = {}

  if (withApiToken) {
    config = {
      ...config,
      headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
    }
  }

  switch (method) {
    case 'post':
      return axios.post(`${API_URL}${url}`, data, config)
    case 'patch':
      return axios.patch(`${API_URL}${url}`, data, config)
    case 'delete':
      return axios.delete(`${API_URL}${url}`, config)
    default:
      return axios.get(`${API_URL}${url}`, config)
  }
}

export default axiosRequest
