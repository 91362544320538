import React from 'react'
import ReactQuill from 'react-quill'

import { QUILL_MODULES, QUILL_FORMATS } from '../../../../../config/quill-config'

const TradeDescription = ({ state, updateState = (f) => f }) => {
  return (
    <div className="row">
      <div className="col-12">
        <ReactQuill
          theme="snow"
          onChange={(html) => updateState('tradeDescription', html)}
          value={state.tradeDescription}
          formats={QUILL_FORMATS}
          modules={QUILL_MODULES}
        />
      </div>
    </div>
  )
}

export default TradeDescription
