import { all } from 'redux-saga/effects'

import user from './user/sagas'
import account from './account/sagas'
import tradingSystem from './trading-system/sagas'
import advancedStat from './advanced-stats/sagas'
import trade from './trade/sagas'
import stat from './stat/sagas'
// eslint-disable-next-line import/no-cycle
import filter from './filter/sagas'
import instrument from './instrument/sagas'
import tag from './tag/sagas'

export default function* rootSaga() {
  yield all([user(), account(), tradingSystem(), advancedStat(), trade(), stat(), filter(), instrument(), tag()])
}
