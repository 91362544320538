import actions from './actions'

const initialState = {
  dashboard: [],
  reportsOverview: [],
  hourlyReport: [],
  weekdayReport: [],
  monthlyReport: [],
  holdingTime: [],
  volumeReport: [],
  advancedStatsReport: [],
  instrumentsReport: [],
  maeMfeReport: [],
  isLoading: true,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET:
      return initialState
    default:
      return state
  }
}
