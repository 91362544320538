import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

function handleError(e, msgKey = 'message_key') {
  message.error({ content: 'Something goes wrong.', key: msgKey })
  // eslint-disable-next-line no-unused-expressions
  e.response && e.response.status === 401 && window.location.reload()
  history.push('/')
}

/**
 * Get user tags list
 */
export function* LIST() {
  try {
    yield put({ type: actions.SET_STATE, payload: { pageLoading: true } })
    const req = yield call(axiosRequest, '/api/v1/tags', 'get', null, true)
    yield put({ type: actions.SET_STATE, payload: { tags: req.data.tags, pageLoading: false } })
  } catch (e) {
    handleError(e)
    yield put({ type: actions.SET_STATE, payload: { pageLoading: true } })
  }
}

/**
 * Create tag
 */
export function* CREATE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { btnLoading: true } })
    const req = yield call(axiosRequest, '/api/v1/tags/create', 'post', payload, true)
    yield put({ type: actions.SET_STATE, payload: { tags: req.data.tags, btnLoading: false } })
    message.success({ content: 'Done', key: 'create_tag' })
  } catch (e) {
    handleError(e, 'create_tag')
    yield put({ type: actions.SET_STATE, payload: { btnLoading: false } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST), takeEvery(actions.CREATE, CREATE)])
}
