import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import actions from '../../../redux/advanced-stats/actions'

import DefaultLoader from '../../../components/system/Loaders/default'
import AdvancedStatsMenu from '../../../components/ui/advanced-stats/AdvancedStatsMenu'
import AdvancedStatsValuesList from '../../../components/ui/advanced-stats/AdvancedStatsValuesList'
import CreateAdvancedStatModal from '../../../components/ui/advanced-stats/forms/CreateAdvancedStatModal'
import EditAdvancedStatModal from '../../../components/ui/advanced-stats/forms/EditAdvancedStatModal'

const mapStateToProps = ({ advancedStat }) => ({ advancedStat })

class AdvancedStatsMainPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMenu: null,
      activeAdvancedStatId: null,
      createModalVisibility: false,
      editModalVisibility: false,
      editAdvancedStatTarget: {},
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.LIST,
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  selectAdvancedStat = (event, stat) => {
    event.preventDefault()
    message.loading({ content: 'Please, wait...', key: 'as_single' })
    const { dispatch } = this.props
    this.setState({ activeMenu: stat.slug, activeAdvancedStatId: stat.id })
    dispatch({
      type: actions.SINGLE,
      payload: {
        slug: stat.slug,
      },
    })
  }

  handleCreateStatModalVisibility = (condition) => {
    this.setState({ createModalVisibility: condition })
  }

  setAdvancedStatTargetToEdit = (stat) => {
    this.setState({ editAdvancedStatTarget: stat, editModalVisibility: true })
  }

  handleEditStatModalVisibility = (condition) => {
    this.setState({ editModalVisibility: condition })
  }

  render() {
    const pageTitle = 'Advanced Stats'
    const { dispatch, advancedStat } = this.props
    const {
      activeMenu,
      createModalVisibility,
      editModalVisibility,
      editAdvancedStatTarget,
      activeAdvancedStatId,
    } = this.state

    if (advancedStat.isListLoading) {
      return <DefaultLoader />
    }

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button type="primary" key="1" onClick={() => this.handleCreateStatModalVisibility(true)}>
                <PlusOutlined />
                <span className="ml-2">Add new advanced stat</span>
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__slideInUp">
          <div className="advanced-stats">
            <div className="row">
              <AdvancedStatsMenu
                dispatch={dispatch}
                stats={advancedStat.stats}
                active={activeMenu}
                selectAdvancedStat={this.selectAdvancedStat}
                setAdvancedStatTargetToEdit={this.setAdvancedStatTargetToEdit}
              />
              <AdvancedStatsValuesList dispatch={dispatch} statId={activeAdvancedStatId} values={advancedStat.values} />
            </div>
          </div>
        </div>
        <CreateAdvancedStatModal
          dispatch={dispatch}
          createModalVisibility={createModalVisibility}
          handleCreateStatModalVisibility={this.handleCreateStatModalVisibility}
        />
        <EditAdvancedStatModal
          dispatch={dispatch}
          editModalVisibility={editModalVisibility}
          handleEditStatModalVisibility={this.handleEditStatModalVisibility}
          editAdvancedStatTarget={editAdvancedStatTarget}
        />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(AdvancedStatsMainPage))
