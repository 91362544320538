// eslint-disable-next-line import/prefer-default-export
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost'
export const PADDLE_ENV = process.env.REACT_APP_PADDLE_ENV || 'sandbox'
export const PADDLE_VENDOR = process.env.REACT_APP_PADDLE_VENDOR || 961
export const PADDLE_PRODUCT = process.env.REACT_APP_PADDLE_PRODUCT || 8032
export const PADDLE_DEBUG = process.env.REACT_APP_PADDLE_DEBUG || false
export const PADDLE_AUTH_CODE =
  process.env.REACT_APP_PADDLE_AUTH_CODE ||
  '92156f48d1d559908340f2aeb1fe5030c14c1f91486a054733'
export const PADDLE_API_URL =
  process.env.REACT_APP_PADDLE_API_URL || 'https://sandbox-vendors.paddle.com/api/'
