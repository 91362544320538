const actions = {
  SET_STATE: 'advanced-stats/SET_STATE',
  LIST: 'advanced-stats/LIST',
  SINGLE: 'advanced-stats/SINGLE',
  SAVE: 'advanced-stats/SAVE',
  UPDATE: 'advanced-stats/UPDATE',
  DELETE: 'advanced-stats/DELETE',

  VALUE_SAVE: 'advanced-stats/values/SAVE',
  VALUE_UPDATE: 'advanced-stats/values/UPDATE',
  VALUE_DELETE: 'advanced-stats/values/DELETE',

  RESET: 'advanced-stats/RESET',
}

export default actions
