import React, { useState } from 'react'
import { Select } from 'antd'
import { Bubble } from 'react-chartjs-2'

import colors from '../../../../config/apexcharts/colors'
import longNumberToShort from '../../../../helpers/longNumberToShort'
import Card from '../../../ui-2/card'

const HoldingTimeChart = ({ chart, user }) => {
  const [chartViewType, handleViewType] = useState('profit_loss_percents')
  const [chartDiffType, handleDiffType] = useState({
    key: 'diffInMinutes',
    value: 'Minutes',
  })

  const data = {
    labels: ['Holding Time'],
    datasets: [],
  }

  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: `Profit/Loss (${
              chartViewType === 'profit_loss_percents'
                ? '%'
                : user.get_currency.short_name
            })`,
          },
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              if (chartViewType === 'profit_loss_percents') {
                return `${value}%`
              }
              return `${longNumberToShort(value, '0.0a')} ${user.get_currency.short_name}`
            },
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: chartDiffType.value,
          },
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const renderChart = () => {
    chart.forEach((item) => {
      data.datasets.push({
        label: item.trade,
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.primaryColor,
        borderColor: colors.primaryColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors.primaryColor,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors.primaryColor,
        pointHoverBorderColor: colors.primaryHover,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [{ x: item[chartDiffType.key], y: item[chartViewType], r: 3 }],
      })
    })
    return <Bubble data={data} options={options} />
  }

  const handleTimeSettingsChange = (value) => {
    switch (value) {
      case 'diffInMinutes':
        handleDiffType({ key: 'diffInMinutes', value: 'Minutes' })
        break
      case 'diffInHours':
        handleDiffType({ key: 'diffInHours', value: 'Hours' })
        break
      case 'diffInDays':
        handleDiffType({ key: 'diffInDays', value: 'Days' })
        break
      default:
        handleDiffType({ key: 'diffInMinutes', value: 'Minutes' })
        break
    }
    return true
  }

  return (
    <Card wrapperClass="m-b-30">
      <div className="mb-4 d-flex justify-content-end align-items-center">
        <div className="mr-2">Performance:</div>
        <div className="mr-5">
          <Select
            defaultValue={chartViewType}
            onChange={(value) => handleViewType(value)}
          >
            <Select.Option value="profit_loss">
              Profit/Loss({user.get_currency && user.get_currency.short_name})
            </Select.Option>
            <Select.Option value="profit_loss_percents">Profit/Loss(%)</Select.Option>
          </Select>
        </div>
        <div className="mr-2">Time Settings:</div>
        <div className="mr-0">
          <Select
            defaultValue={chartDiffType.key}
            onChange={(value) => handleTimeSettingsChange(value)}
          >
            <Select.Option value="diffInMinutes">Minutes</Select.Option>
            <Select.Option value="diffInHours">Hours</Select.Option>
            <Select.Option value="diffInDays">Days</Select.Option>
          </Select>
        </div>
      </div>
      <div>{renderChart()}</div>
    </Card>
  )
}

export default HoldingTimeChart
