import React, { useEffect, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import { Skeleton } from 'antd'

import OverviewItem from './OverviewItem'

import axiosRequest from '../../../../../config/axios'
import axiosError from '../../../../../helpers/axios-error'

import styles from './styles.module.scss'

const StockOverviewWidget = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [sliderRef] = useKeenSlider({
    loop: true,
    breakpoints: {
      '(min-width: 10px)': {
        slides: {
          perView: 1,
          spacing: 16,
        },
      },
      '(min-width: 520px)': {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      '(min-width: 740px)': {
        slides: {
          perView: 3,
          spacing: 16,
        },
      },
      '(min-width: 1024px)': {
        slides: {
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 1400px)': {
        loop: false,
        slides: {
          perView: 5,
          spacing: 16,
        },
      },
    },
  })

  useEffect(() => {
    setLoading(true)
    axiosRequest('/api/v1/analysis/stock/widgets/overview', 'get', null, true)
      .then((res) => {
        setData(res.data.data || [])
      })
      .catch((e) => axiosError(e))
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className={styles.StocksSlider}>
          <div ref={sliderRef} className="keen-slider">
            {data.map((item) => (
              <div
                key={`${item.title}${item.instrument._id}`}
                className="keen-slider__slide"
              >
                <OverviewItem
                  title={item.title}
                  instrument={item.instrument}
                  value={item.value}
                  valuePostfix={item.valuePostfix}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default StockOverviewWidget
