import React from 'react'
import { Select } from 'antd'

import styles from './stats.module.scss'
import { Link } from 'react-router-dom'
import longNumberToShort from '../../../../../../helpers/longNumberToShort'

const TotalPerformanceStats = ({ data, user, view, setView }) => {
  return (
    <div className={styles.totalPerformanceStats}>
      <div className={styles.chartSelectWrapper}>
        <select value={view} onChange={(e) => setView(e.target.value)}>
          <option value="daily">Daily</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <h2 className={styles.primaryStat}>
        {data.totalReturn > 1000000000
          ? longNumberToShort(parseFloat(data.totalReturn).toFixed(2))
          : new Intl.NumberFormat().format(data.totalReturn)}{' '}
        {user.get_currency && user.get_currency.short_name
          ? user.get_currency.short_name
          : ''}
      </h2>
      <ul>
        <li>
          <strong>Total Trades:</strong>{' '}
          {new Intl.NumberFormat().format(data.totalTrades)}
        </li>
        <li>
          <strong>Profit Factor:</strong>{' '}
          {new Intl.NumberFormat().format(data.profitFactor)}
        </li>
        <li>
          <strong>Avg. Return per Trade:</strong>{' '}
          {new Intl.NumberFormat().format(data.avgReturnPerTrade)}{' '}
          {user.get_currency && user.get_currency.short_name
            ? user.get_currency.short_name
            : ''}
        </li>
      </ul>
      <div className={styles.link}>
        <Link to="/reports/overview">Learn More</Link>
      </div>
    </div>
  )
}

export default TotalPerformanceStats
