import React, { useState } from 'react'
import { AutoComplete, InputNumber, message, Select } from 'antd'
import axios from 'axios'

import { API_URL } from '../../../../config/app'
import { v4 as uuidv4 } from 'uuid'

const MainCard = ({ trade, updateData, data }) => {
  const [instrumentsOptions, handleInstrumentOptions] = useState([])

  const handleInstrumentsAutoComplete = (value) => {
    handleInstrumentOptions([])
    if (value.length >= 2) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
      }
      axios
        .get(
          `${API_URL}/api/v1/instruments/autocomplete?market=${
            trade.data.market ? trade.data.market.toString() : '0'
          }&q=${value}`,
          config,
        )
        .then((res) => {
          handleInstrumentOptions(res.data.data)
        })
        .catch((e) => {
          message.error(e.message || 'Something goes wrong, try again later...')
        })
    }
  }

  return (
    <>
      <h5 className="trade-form__subtitle">General Data</h5>
      <div className="trade-form__section">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label htmlFor="tradeMarket">Market</label>
              <Select
                size="large"
                className="w-100"
                value={
                  trade.data.market || trade.data.market === 0
                    ? trade.data.market.toString()
                    : null
                }
                onChange={(value) => updateData('data.market', parseInt(value, 10))}
              >
                <Select.Option value="0">Forex</Select.Option>
                <Select.Option value="1">Stock</Select.Option>
                <Select.Option value="2">Crypto</Select.Option>
                <Select.Option value="3">Futures</Select.Option>
                <Select.Option value="4">Options</Select.Option>
              </Select>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label htmlFor="tradeAccount">Account</label>
              <Select
                size="large"
                className="w-100"
                value={trade.data.account ? trade.data.account : null}
                onChange={(value) => updateData('data.account', parseInt(value, 10))}
              >
                {data.accounts[0] &&
                  data.accounts.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label htmlFor="tradeTS">Trading System</label>
              <Select
                size="large"
                className="w-100"
                value={trade.data.trading_system ? trade.data.trading_system : null}
                onChange={(value) =>
                  updateData('data.trading_system', parseInt(value, 10))
                }
              >
                {data.tradingSystems[0] &&
                  data.tradingSystems.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="form-group">
              <label htmlFor="tradeInstrument">Instrument</label>
              <AutoComplete
                size="large"
                onSearch={handleInstrumentsAutoComplete}
                onChange={(value) => updateData('data.instrument', value)}
                onBlur={(event) => updateData('data.instrument', event.target.value)}
                placeholder="Input instrument name..."
                className="w-100"
                value={trade.data.instrument ? trade.data.instrument : null}
              >
                {instrumentsOptions.map((item) => {
                  return (
                    <AutoComplete.Option value={item.name} key={uuidv4()}>
                      {item.name}
                    </AutoComplete.Option>
                  )
                })}
              </AutoComplete>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="form-group">
              <label htmlFor="tradeTimeFrame">Timeframe</label>
              <Select
                size="large"
                className="w-100"
                value={trade.data.timeframe ? trade.data.timeframe : null}
                onChange={(value) => updateData('data.timeframe', parseInt(value, 10))}
              >
                {data.timeframes[0] &&
                  data.timeframes.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="form-group">
              <label htmlFor="takeProfit">Take Profit (optional)</label>
              <InputNumber
                size="large"
                value={trade.data.take_profit ? trade.data.take_profit : null}
                className="w-100"
                onChange={(value) => updateData('data.take_profit', parseFloat(value))}
                decimalSeparator="."
                min={0}
                max={999999999999999}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="form-group">
              <label htmlFor="stopLoss">Stop Loss (optional)</label>
              <InputNumber
                size="large"
                value={trade.data.stop_loss ? trade.data.stop_loss : null}
                className="w-100"
                onChange={(value) => updateData('data.stop_loss', parseFloat(value))}
                decimalSeparator="."
                min={0}
                max={999999999999999}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="form-group">
              <label htmlFor="highestPrice">Highest Price (optional)</label>
              <InputNumber
                size="large"
                value={trade.data.highest_price ? trade.data.highest_price : null}
                className="w-100"
                onChange={(value) => updateData('data.highest_price', parseFloat(value))}
                decimalSeparator="."
                min={0}
                max={999999999999999}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <div className="form-group">
              <label htmlFor="lowestPrice">Lowest Price (optional)</label>
              <InputNumber
                size="large"
                value={trade.data.lowest_price ? trade.data.lowest_price : null}
                className="w-100"
                onChange={(value) => updateData('data.lowest_price', parseFloat(value))}
                decimalSeparator="."
                min={0}
                max={999999999999999}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainCard
