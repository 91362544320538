import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const SpinnerLoader = () => {
  return (
    <div className="mt-5 mb-5 text-center">
      <Spin indicator={<LoadingOutlined />} size="large" />
    </div>
  )
}

export default SpinnerLoader
