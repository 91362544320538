import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

import Card from '../../../card'

import axiosRequest from '../../../../../config/axios'
import axiosError from '../../../../../helpers/axios-error'
import createUrlParamsStringFromFilters from '../../../../../helpers/createUrlParamsFromFilters'

import styles from './styles.module.scss'
import longNumberToShort from '../../../../../helpers/longNumberToShort'

const upIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="text-success"
  >
    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
    <polyline points="17 6 23 6 23 12" />
  </svg>
)

const downIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="text-danger"
  >
    <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
    <polyline points="17 18 23 18 23 12" />
  </svg>
)

const PeriodsResultsWidget = ({ user, filter }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)
    axiosRequest(
      `/api/v1/widgets/period-results?${createUrlParamsStringFromFilters(filter)}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        if (res.data && res.data.success) {
          setData(res.data.data)
        }
      })
      .catch((e) => {
        axiosError(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  return (
    <Card>
      <Spin spinning={loading}>
        <div className={styles.periodWidget}>
          <table>
            <thead>
              <tr>
                <th />
                <th>Gain</th>
                <th>Profit</th>
                <th>Trades</th>
                <th>Win Rate</th>
                <th>Qty (units)</th>
              </tr>
            </thead>
            <tbody>
              {['day', 'week', 'month', 'year'].map((key) => {
                if (data[key]) {
                  return (
                    <tr key={key}>
                      <td>
                        <strong>{data[key].name}</strong>
                      </td>
                      <td>
                        <div className={styles.wrapper}>
                          <strong>
                            {parseFloat(data[key].stat.gain) !== 0.0
                              ? new Intl.NumberFormat().format(data[key].stat.gain)
                              : 0}
                            %
                          </strong>
                          {data[key].stat.gain_diff !== 0.0 && (
                            <div
                              className={
                                data[key].stat.gain_diff > 0.0
                                  ? styles.percents
                                  : styles.percentsDanger
                              }
                            >
                              <div className={styles.icon}>
                                {parseFloat(data[key].stat.gain_diff) > 0.0
                                  ? upIcon
                                  : downIcon}
                              </div>
                              <div className={styles.numbers}>
                                {new Intl.NumberFormat().format(data[key].stat.gain_diff)}
                                %
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={styles.wrapper}>
                          <strong>
                            {parseFloat(data[key].stat.profit) !== 0.0
                              ? new Intl.NumberFormat().format(data[key].stat.profit)
                              : 0}{' '}
                            {user.get_currency && user.get_currency.short_name
                              ? user.get_currency.short_name
                              : ''}
                          </strong>
                          {data[key].stat.profit_diff !== 0.0 && (
                            <div
                              className={
                                data[key].stat.profit_diff > 0.0
                                  ? styles.percents
                                  : styles.percentsDanger
                              }
                            >
                              <div className={styles.icon}>
                                {parseFloat(data[key].stat.profit_diff) > 0.0
                                  ? upIcon
                                  : downIcon}
                              </div>
                              <div className={styles.numbers}>
                                {new Intl.NumberFormat().format(
                                  data[key].stat.profit_diff,
                                )}{' '}
                                {user.get_currency && user.get_currency.short_name
                                  ? user.get_currency.short_name
                                  : ''}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={styles.wrapper}>
                          <strong>
                            {parseFloat(data[key].stat.trades) !== 0.0
                              ? new Intl.NumberFormat().format(data[key].stat.trades)
                              : 0}
                          </strong>
                          {data[key].stat.trades_diff !== 0.0 && (
                            <div
                              className={
                                data[key].stat.trades_diff > 0.0
                                  ? styles.percents
                                  : styles.percentsDanger
                              }
                            >
                              <div className={styles.icon}>
                                {parseFloat(data[key].stat.trades_diff) > 0.0
                                  ? upIcon
                                  : downIcon}
                              </div>
                              <div className={styles.numbers}>
                                {new Intl.NumberFormat().format(
                                  data[key].stat.trades_diff,
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={styles.wrapper}>
                          <strong>
                            {parseFloat(data[key].stat.win_rate) !== 0.0
                              ? new Intl.NumberFormat().format(data[key].stat.win_rate)
                              : 0}
                            %
                          </strong>
                          {data[key].stat.win_rate_diff !== 0.0 && (
                            <div
                              className={
                                data[key].stat.win_rate_diff > 0.0
                                  ? styles.percents
                                  : styles.percentsDanger
                              }
                            >
                              <div className={styles.icon}>
                                {parseFloat(data[key].stat.win_rate_diff) > 0.0
                                  ? upIcon
                                  : downIcon}
                              </div>
                              <div className={styles.numbers}>
                                {new Intl.NumberFormat().format(
                                  data[key].stat.win_rate_diff,
                                )}
                                %
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={styles.wrapper}>
                          <strong>
                            {parseFloat(data[key].stat.qty) !== 0.0
                              ? longNumberToShort(data[key].stat.qty)
                              : 0}
                          </strong>
                          {data[key].stat.qty_diff !== 0.0 && (
                            <div
                              className={
                                data[key].stat.qty_diff > 0.0
                                  ? styles.percents
                                  : styles.percentsDanger
                              }
                            >
                              <div className={styles.icon}>
                                {parseFloat(data[key].stat.qty_diff) > 0.0
                                  ? upIcon
                                  : downIcon}
                              </div>
                              <div className={styles.numbers}>
                                {longNumberToShort(data[key].stat.qty)}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
          </table>
        </div>
      </Spin>
    </Card>
  )
}

export default PeriodsResultsWidget
