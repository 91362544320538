import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, message, PageHeader, Skeleton } from 'antd'
import { API_URL } from '../../../config/app'

import GeneralSettingsForm from '../../../components/ui/settings/general-form'
import ValidateEmail from '../../../components/ui/settings/validate-email'
import Card from '../../../components/ui-2/card'
import DeleteAccount from '../../../components/ui/settings/delete-account'

const mapStateToProps = ({ user }) => ({ user })

const GeneralSettingsPage = ({ user }) => {
  const dispatch = useDispatch()
  const [dataLoading, setDataLoading] = useState(true)
  const [countries, setCountries] = useState([])
  const [currencies, setCurrencies] = useState([])

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
    }
    axios
      .get(`${API_URL}/api/v1/data/common/countries-currencies`, config)
      .then((res) => {
        setCountries(res.data.countries)
        setCurrencies(res.data.currencies)
        setDataLoading(false)
      })
      .catch(() => {
        message.error('Something goes wrong. Try again later or contact support.')
      })
  }, [])

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <Helmet title="Account Settings" />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
          <Breadcrumb.Item>Account Settings</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Account Settings" backIcon={false} />
      </div>
      <div className="animate__animated animate__fadeIn">
        <ValidateEmail user={user.user} />
        <div className="mb-4">
          <Card title="Account Details">
            {dataLoading ? (
              <>
                <Skeleton active />
                <Skeleton active />
              </>
            ) : (
              <GeneralSettingsForm
                dispatch={dispatch}
                user={user.user}
                countries={countries}
                currencies={currencies}
              />
            )}
          </Card>
        </div>
        <div className="mb-4">
          <DeleteAccount />
        </div>
      </div>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(GeneralSettingsPage))
