import React from 'react'
import { Button, Form, Input } from 'antd'

import styles from './header.module.scss'

const MarketNewsHeader = ({ title, subtitle, filters, setFilters }) => {
  const onFinish = (values) => {
    setFilters({ ...filters, ...values })
  }

  return (
    <div className={styles.marketNewsHeader}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className={styles.search}>
          <Form
            onFinish={onFinish}
            initialValues={filters}
            className="d-flex justify-content-center align-items-center"
          >
            <Form.Item name="search" className="w-100 mb-0 mr-3">
              <Input placeholder="Search..." size="large" allowClear />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button size="large" className="btn-primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default MarketNewsHeader
