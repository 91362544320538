import React from 'react'

import Card from '../../ui-2/card'

import importTradesTips from '../../../helpers/import-trades-tips'

const ImportTradesTips = ({ platform }) => {
  return <Card title="Quick tips">{importTradesTips[platform]}</Card>
}

export default ImportTradesTips
