import React from 'react'
import { Button, Drawer, Form, Input, Select, DatePicker, message } from 'antd'
import moment from 'moment'

import actions from '../../../../redux/account/actions'

class EditTransactionDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      transactionDate: moment(),
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const { transaction } = this.props
    // console.log(transaction)
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { transaction } = this.props
    if (transaction.id && prevProps.transaction.id !== transaction.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ transactionDate: moment(transaction.entry_date) })
    }
  }

  onFinish = (values) => {
    const { onEditTransactionDrawerClose, accountId, dispatch, transaction } = this.props
    const { transactionDate } = this.state
    // Message
    message.loading({ content: 'Please, wait...', key: 'update_transaction', duration: 90 })
    // Close rawer
    onEditTransactionDrawerClose()
    // Clear form
    // form.resetFields()
    // Dispatch
    dispatch({
      type: actions.TRANSACTION_UPDATE,
      payload: {
        id: transaction.id,
        account: accountId,
        type: values.type,
        value: values.value,
        date: moment(transactionDate).format('YYYY-MM-DD HH:mm'),
      },
    })
  }

  // eslint-disable-next-line class-methods-use-this
  onFinishFailed = () => {
    message.error('Error')
  }

  onDateTimeConfirm = (value) => {
    this.setState({
      transactionDate: value,
    })
  }

  render() {
    const { form, transaction, onEditTransactionDrawerClose, editTransactionDrawer } = this.props
    if (!transaction.id) {
      return null
    }
    return (
      <Drawer
        title="Edit transaction"
        onClose={onEditTransactionDrawerClose}
        visible={editTransactionDrawer}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onEditTransactionDrawerClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" form="addNewTransaction" htmlType="submit">
              Update
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          className="mb-4"
          id="addNewTransaction"
        >
          <Form.Item
            name="type"
            label="Type"
            hasFeedback
            rules={[{ required: true, message: 'Please select transaction type' }]}
            initialValue={transaction.action}
          >
            <Select>
              <Select.Option value={0}>Withdrawal</Select.Option>
              <Select.Option value={1}>Deposit</Select.Option>
              <Select.Option value={2}>Dividends</Select.Option>
              <Select.Option value={3}>Taxes</Select.Option>
              <Select.Option value={4}>Fees</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="value"
            hasFeedback
            initialValue={transaction.profit_loss}
            rules={[
              {
                required: true,
                message: 'Please, input transaction value',
              },
              {
                pattern: new RegExp('(?=.*\\d)^\\$?(([1-9]\\d{0,19}(,\\d{3})*)|0)?(\\.\\d{1,2})?$'),
                message: 'Please, input correct transaction value',
              },
            ]}
          >
            <Input size="middle" placeholder="Transaction value" />
          </Form.Item>
          <DatePicker
            className="fullwidth"
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={this.onDateTimeConfirm}
            onChange={(date) => this.onDateTimeConfirm(date)}
            defaultValue={transaction.entry_date ? moment(transaction.entry_date) : moment()}
            showToday={false}
          />
        </Form>
      </Drawer>
    )
  }
}

export default EditTransactionDrawer
