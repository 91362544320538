import React, { useEffect, useState, useContext } from 'react'
import Chart from 'react-apexcharts'

import { ThemeContext } from '../../../../../../context/ThemeProvider'

import styles from './chart.module.scss'

const TotalPerformanceChart = ({ data, view }) => {
  const { theme } = useContext(ThemeContext)
  const [chartData, setChartData] = useState({ labels: [], series: [] })

  const handleCharts = (data) => {
    const labels = []
    const series = []
    Object.keys(data).forEach(function (key) {
      labels.push(key)
      series.push(data[key].tradesProfit || 0)
    })
    setChartData({ labels, series: view === 'daily' ? series.slice(-30) : series })
  }

  useEffect(() => {
    handleCharts(data.daily)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (view) {
      switch (view) {
        case 'monthly':
          handleCharts(data.monthly)
          break
        case 'yearly':
          handleCharts(data.yearly)
          break
        case 'daily':
        default:
          handleCharts(data.daily)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  return (
    <div className={styles.totalPerformanceChart}>
      <div>
        <Chart
          series={[{ data: chartData.series }]}
          options={{
            labels: chartData.labels,
            chart: { type: 'bar', toolbar: { show: false } },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            colors: ['#007be8'],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 6,
              },
            },
            grid: {
              borderColor: theme === 'light' ? '#e4e6ef' : '#323248',
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: chartData.labels,
              labels: {
                style: {
                  fontWeight: 400,
                  colors: theme === 'light' ? '#b5b5c3' : '#676d7d',
                  fontFamily: 'Poppins, Roboto, sans-serif',
                  fontSize: '10px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontWeight: 400,
                  colors: theme === 'light' ? '#b5b5c3' : '#676d7d',
                  fontFamily: 'Poppins, Roboto, sans-serif',
                  fontSize: '10px',
                },
              },
            },
            responsive: [
              {
                breakpoint: 500,
                options: {
                  chart: {
                    height: 400,
                  },
                },
              },
            ],
          }}
          type="bar"
        />
      </div>
    </div>
  )
}

export default TotalPerformanceChart
