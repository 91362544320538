import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Empty } from 'antd'

import colors from '../../../../config/apexcharts/colors'
import longNumberToShort from '../../../../helpers/longNumberToShort'

const AdvancedStatReportChart = ({ title, data, user }) => {
  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              return `${longNumberToShort(value, '0.0a')} ${user.get_currency.short_name}`
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const chartData = {
    labels: [],
    datasets: [
      {
        label: `Profit/Loss (${user.get_currency.short_name})`,
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        hoverBackgroundColor: [],
        hoverBorderColor: [],
        data: [],
        maxBarThickness: 45,
      },
    ],
  }

  const renderChart = () => {
    if (data.length > 0) {
      data.forEach((item) => {
        chartData.labels.push(item.value_name)
        chartData.datasets[0].data.push(parseFloat(item.profit_loss).toFixed(2))
        if (parseFloat(item.profit_loss) < 0) {
          chartData.datasets[0].backgroundColor.push(colors.dangerColor)
          chartData.datasets[0].borderColor.push(colors.dangerColor)
          chartData.datasets[0].hoverBackgroundColor.push(colors.dangerColor)
          chartData.datasets[0].hoverBorderColor.push(colors.dangerColor)
        } else {
          chartData.datasets[0].backgroundColor.push(colors.successColor)
          chartData.datasets[0].borderColor.push(colors.successColor)
          chartData.datasets[0].hoverBackgroundColor.push(colors.successColor)
          chartData.datasets[0].hoverBorderColor.push(colors.successColor)
        }
      })
      return <Bar data={chartData} options={options} />
    }
    return <Empty className="pt-5" description="Not enough data" />
  }

  return (
    <div className="card card__stat m-b-30">
      <div className="card-header">
        <h3 className="card-title">{title}</h3>
      </div>
      <div className="card-content">
        <div className="m-h-300">{renderChart()}</div>
      </div>
    </div>
  )
}

export default AdvancedStatReportChart
