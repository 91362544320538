import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './style.module.scss'

import loginImage from '../../assets/svg/login.svg'

const mapStateToProps = () => ({})

const LoginLayout = ({ children }) => {
  return (
    <div className={styles.authLayout}>
      <div className={styles.authLayoutInner}>
        <div className={styles.authLayoutLogo}>
          <span>tradiry</span>
        </div>
        <div className="row">
          <div
            className={`d-none d-lg-flex p-5 col-12 col-lg-8 col-xxl-9 ${styles.authLayoutImage}`}
          >
            <img src={loginImage} alt="Tradiry" />
          </div>
          <div
            className={`d-flex align-items-center justify-content-center col-12 col-lg-4 col-xxl-3 auth-layout-content ${styles.authLayoutContent}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(LoginLayout))
