import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'

import { history } from 'index'

import axiosRequest from '../../config/axios'
import actions from './actions'

/**
 * Get trading systems list
 */
export function* LIST() {
  try {
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
    const req = yield call(
      axiosRequest,
      '/api/v1/trading-systems/list',
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { tradingSystems: req.data.trading_systems, isListLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Get single trading system
 */
export function* SINGLE({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
    const req = yield call(
      axiosRequest,
      `/api/v1/trading-systems/single/${payload.slug}`,
      'get',
      null,
      true,
    )
    yield put({
      type: actions.SET_STATE,
      payload: { tradingSystem: req.data.trading_system, isListLoading: false },
    })
  } catch (e) {
    message.error('Something goes wrong.')
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
    yield put({ type: actions.SET_STATE, payload: { isListLoading: true } })
  }
}

/**
 * Save new trading system
 */
export function* SAVE({ payload }) {
  try {
    const req = yield call(
      axiosRequest,
      '/api/v1/trading-systems/save',
      'post',
      payload,
      true,
    )
    if (req.data.success) {
      message.success({ content: 'Success', key: 'save_ts' })
      history.push('/trading-systems')
    } else {
      message.destroy()
      notification.warning({
        message: 'Trading systems limit reached',
        description:
          req.data.message || 'Something goes wrong. Please, contact customers support.',
      })
    }
  } catch (e) {
    message.error({ content: 'Something foes wrong.', key: 'save_ts' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Update trading system
 */
export function* UPDATE({ payload }) {
  try {
    yield call(
      axiosRequest,
      `/api/v1/trading-systems/update/${payload.slug}`,
      'post',
      payload,
      true,
    )
    message.success({ content: 'Success', key: 'update_ts' })
    history.push(`/trading-systems/single/${payload.slug}`)
  } catch (e) {
    message.error({ content: 'Something foes wrong.', key: 'update_ts' })
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    history.push('/')
  }
}

/**
 * Delete trading system
 */
export function* DELETE({ payload }) {
  try {
    yield call(
      axiosRequest,
      `/api/v1/trading-systems/delete/${payload.slug}`,
      'post',
      null,
      true,
    )
    message.success({ content: 'Trading system was deleted', key: 'delete_ts' })
    history.push('/trading-systems')
  } catch (e) {
    // eslint-disable-next-line no-unused-expressions
    e.response && e.response.status === 401 && window.location.reload()
    if (e.response && e.response.status === 403) {
      message.error({
        content: 'You can not delete last trading system.',
        key: 'delete_ts',
      })
    } else {
      message.error({ content: 'Something goes wrong.', key: 'delete_ts' })
      history.push('/')
    }
  }
}

/**
 * Reset accounts state
 */
export function RESET() {
  put({ type: actions.RESET })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.SINGLE, SINGLE),
    takeEvery(actions.SAVE, SAVE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
