import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import 'moment-timezone'
import styles from '../../accounts/AccountListItem/style.module.scss'

const TradingSystemListItem = ({ ts, user }) => {
  return (
    <Col span="24" md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
      <Card className={styles.accounts_list__item} title={ts.name} extra={<Link to={`/trading-systems/single/${ts.slug}`}>Details</Link>}>
        <p className={styles.accounts_list_item__info_text}>Trades: {ts.get_trades_count}</p>
        <p className={classNames('mb-0', styles.accounts_list_item__info_text)}>
          Created at: {moment(ts.created_at).tz(user.timezone).format(`${user.date_format} ${user.time_format}`)}
        </p>
      </Card>
    </Col>
  )
}

export default TradingSystemListItem
