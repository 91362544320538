import actions from './actions'

const initialState = {
  accounts: [],
  account: {},
  accountStats: {},
  transactions: [],
  isListLoading: true,
  saveAccountBtnLoading: false,
  saveTransactionBtnLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET:
      return initialState
    default:
      return state
  }
}
