// eslint-disable-next-line import/no-cycle
import { history } from '../index'

function checkIfUserHaveSubscription(user) {
  if (user && user.is_premium === 1) {
    return true
  }
  history.push('/settings/subscription')
  return false
}

export default checkIfUserHaveSubscription
