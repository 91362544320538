import React, { useState } from 'react'
import { Modal, message } from 'antd'

import actions from '../../../../redux/advanced-stats/actions'

const EditAdvancedStatModal = ({
  dispatch,
  editModalVisibility,
  handleEditStatModalVisibility,
  editAdvancedStatTarget,
}) => {
  const [statName, setStatName] = useState('')

  const handleCreateStat = () => {
    message.loading({ content: 'Please, wait...', key: 'update_stat' })
    let advancedStatName = statName
    // Check if stat name is exist
    if (advancedStatName.length === 0) {
      advancedStatName = editAdvancedStatTarget.name
    }
    // Dispatch
    dispatch({
      type: actions.UPDATE,
      payload: {
        slug: editAdvancedStatTarget.slug,
        name: advancedStatName,
      },
    })
    // Clear form
    setStatName('')
    // Close modal
    handleEditStatModalVisibility(false)
  }

  const handleAdvancedStatName = (event) => {
    setStatName(event.target.value)
  }

  const disableFormSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <Modal
      title="Edit advanced stat"
      visible={editModalVisibility}
      onOk={handleCreateStat}
      okText="Save"
      onCancel={() => handleEditStatModalVisibility(false)}
      destroyOnClose
    >
      <div className="row">
        <div className="col-12">
          <form onSubmit={disableFormSubmit}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="advancedStatName">Advanced stat name</label>
            <input
              type="text"
              name="advancedStatName"
              className="ant-input"
              required
              placeholder="Input new advanced stat name here"
              onChange={handleAdvancedStatName}
              defaultValue={editAdvancedStatTarget.name}
            />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default EditAdvancedStatModal
