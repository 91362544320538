import React from 'react'
import { Drawer } from 'antd'

import DefaultLoader from '../../system/Loaders/default'
import SingleTradeDetails from './SingleTradeDetails'
import SingleTrade from '../../ui-2/trades/single-trade'

const SingleTradeDrawer = ({
  dispatch,
  trade,
  user,
  isSingleTradeVisible,
  closeSingleTradeDrawer = (f) => f,
  buildQueryStringFromState = (f) => f,
}) => {
  if (!isSingleTradeVisible) {
    return null
  }

  return (
    <Drawer
      title={null}
      destroyOnClose
      closable
      visible={isSingleTradeVisible}
      onClose={() => closeSingleTradeDrawer()}
      className="single-trade-drawer"
    >
      {trade.isSingleTradeLoading ? (
        <DefaultLoader />
      ) : (
        <SingleTrade
          dispatch={dispatch}
          trade={trade.trade.id ? trade.trade : false}
          notes={trade.notes}
          user={user}
          closeSingleTradeDrawer={closeSingleTradeDrawer}
          buildQueryStringFromState={buildQueryStringFromState}
        />
      )}
    </Drawer>
  )
}

export default SingleTradeDrawer
