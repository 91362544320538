import React from 'react'

import GeneralTradeFormSection from './details/GeneralSection'
import TradeEntry from './details/TradeEntry'
import TradeExit from './details/TradeExit'
import OptionalInformation from './details/OptionalInformation'

const TradeBasicDetails = ({ state, tradeData, updateState = (f) => f }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-3">
        <h5 className="trade-form__subtitle">General Data</h5>
        <GeneralTradeFormSection state={state} updateState={updateState} tradeData={tradeData} />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <h5 className="trade-form__subtitle">Trade Entry</h5>
        <TradeEntry state={state} updateState={updateState} />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <h5 className="trade-form__subtitle">Trade Exit</h5>
        <TradeExit state={state} updateState={updateState} />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <h5 className="trade-form__subtitle">Optional Information</h5>
        <OptionalInformation state={state} updateState={updateState} />
      </div>
    </div>
  )
}

export default TradeBasicDetails
