export const DATE_FORMATS = [
  { format: 'DD.MM.YYYY', name: 'DD.MM.YYYY' },
  { format: 'DD-MM-YYYY', name: 'DD-MM-YYYY' },
  { format: 'MM-DD-YYYY', name: 'MM-DD-YYYY' },
  { format: 'YYYY-MM-DD', name: 'YYYY-MM-DD' },
]

export const TIME_FORMATS = [
  { format: 'HH:mm', name: '24-hours' },
  { format: 'h:mm a', name: '12-hours' },
]
