import React from 'react'

const HourlyReportTable = ({ data, user }) => {
  return (
    <div className="card card__stat m-b-30">
      <div className="card-content card-content__no-title">
        <div className="table-responsive tr-table-responsive">
          <table className="table tr-table table__xs dashboard-main-stat-table">
            <thead>
              <tr>
                <th> </th>
                <th>Total trades</th>
                <th>Win rate</th>
                <th>Results</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((index) => {
                if (data[index].trades !== 0) {
                  return (
                    <tr key={data[index].name}>
                      <td>
                        <strong>{data[index].name}</strong>
                      </td>
                      <td>{data[index].trades}</td>
                      <td>
                        <p className="mb-1">{parseFloat((data[index].win * 100) / data[index].trades).toFixed(0)}%</p>
                        {data[index].loss !== 0 && (
                          <p className="mb-0">
                            <small>
                              Won: {data[index].win} | Loss: {data[index].loss}
                            </small>
                          </p>
                        )}
                      </td>
                      <td>
                        {data[index].earnMoney !== 0.0 && (
                          <p className="mb-1">
                            <strong>Profit:</strong> {data[index].earnMoney}{' '}
                            {user.get_currency && user.get_currency.short_name}{' '}
                            <small>({data[index].earnPercents}%)</small>
                          </p>
                        )}
                        {data[index].lossMoney !== 0.0 && (
                          <p className="mb-0">
                            <strong>Loss:</strong> {data[index].lossMoney}{' '}
                            {user.get_currency && user.get_currency.short_name}{' '}
                            <small>({data[index].lossPercents}%)</small>
                          </p>
                        )}
                      </td>
                      <td>
                        <table className="table tr-table table__xs dashboard-main-stat-table table__fixed-cols-3">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Long</th>
                              <th>Short</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Trades</td>
                              <td>{data[index].action.buy}</td>
                              <td>{data[index].action.sell}</td>
                            </tr>
                            <tr>
                              <td>Won</td>
                              <td>
                                {data[index].action.buy !== 0 ? (
                                  <>
                                    {data[index].action.buyWinCount}{' '}
                                    <small>
                                      (
                                      {parseFloat(
                                        (data[index].action.buyWinCount * 100) / data[index].action.buy,
                                      ).toFixed()}
                                      %)
                                    </small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {data[index].action.sell !== 0 ? (
                                  <>
                                    {data[index].action.sellWinCount}{' '}
                                    <small>
                                      (
                                      {parseFloat(
                                        (data[index].action.sellWinCount * 100) / data[index].action.sell,
                                      ).toFixed()}
                                      %)
                                    </small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Profit</td>
                              <td>
                                {data[index].action.buyWin !== 0.0 ? (
                                  <>
                                    {parseFloat(data[index].action.buyWin).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>({parseFloat(data[index].action.buyWinPercents).toFixed(0)}%)</small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {data[index].action.sellWin !== 0.0 ? (
                                  <>
                                    {parseFloat(data[index].action.sellWin).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>({parseFloat(data[index].action.sellWinPercents).toFixed(0)}%)</small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Loss</td>
                              <td>
                                {data[index].action.buyLoss !== 0.0 ? (
                                  <>
                                    {parseFloat(data[index].action.buyLoss).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>({parseFloat(data[index].action.buyLossPercents).toFixed(0)}%)</small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {data[index].action.sellLoss !== 0.0 ? (
                                  <>
                                    {parseFloat(data[index].action.sellLoss).toFixed(2)}{' '}
                                    {user.get_currency && user.get_currency.short_name}{' '}
                                    <small>({parseFloat(data[index].action.sellLossPercents).toFixed(0)}%)</small>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default HourlyReportTable
