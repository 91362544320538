import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import { Button, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { TbCheck, TbProgress } from 'react-icons/tb'

const TradesImportList = ({ imports, user, showImportedTrades, deleteImport }) => {
  console.log(imports)

  return (
    <>
      <div className="row">
        {imports.map((item) => {
          return (
            <div className="col-12 mb-4" key={item.id}>
              <div className="card card__stat">
                <div className="card-content card-content__no-title">
                  <div className="card-body">
                    <div className="d-flex justify-content-start align-items-center">
                      {item.is_imported ? (
                        <>
                          <div className="mr-3">
                            Uploaded at{' '}
                            {moment(item.created_at).format(
                              `${user.date_format} ${user.time_format}`,
                            )}
                          </div>
                          <div className="mr-3 success-color">
                            <TbCheck /> Completed
                          </div>
                        </>
                      ) : (
                        <div className="mr-3 warning-color">
                          <TbProgress /> In Progress
                        </div>
                      )}
                      {item.get_trades_count > 0 && (
                        <div className="mr-3">Trades: {item.get_trades_count}</div>
                      )}
                      <div className="ml-auto">
                        <Button
                          type="primary"
                          className="mr-4"
                          onClick={() => showImportedTrades(item.id)}
                        >
                          Show trades
                        </Button>
                        <Popconfirm
                          title="This action will remove all imported trades. Are you sure?"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          placement="bottomRight"
                          okText="Yes, delete"
                          cancelText="Cancel"
                          okType="danger"
                          onConfirm={() => deleteImport(item.code)}
                        >
                          <Button type="danger">Delete</Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TradesImportList
