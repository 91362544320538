import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader } from 'antd'

import MaeMfeReportComponent from '../../../components/ui/reports/mae-mfe'
import DefaultLoader from '../../../components/system/Loaders/default'

import actions from '../../../redux/stat/actions'
import createUrlParamsStringFromFilters from '../../../helpers/createUrlParamsFromFilters'
import reloadPageWithUrlParams from '../../../helpers/reloadPageWithUrlParams'

const mapStateToProps = ({ stat, filter, user }) => ({ stat, filter, user })

class MaeMfaReportPage extends React.Component {
  componentDidMount() {
    const { dispatch, filter } = this.props
    dispatch({
      type: actions.MAE_MFE_REPORT,
      payload: {
        query: createUrlParamsStringFromFilters(filter),
      },
    })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filter, dispatch } = this.props
    if (filter !== prevProps.filter) {
      reloadPageWithUrlParams(
        dispatch,
        actions.MAE_MFE_REPORT,
        createUrlParamsStringFromFilters(filter),
      )
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  render() {
    const pageTitle = 'MAE/MFE Report'
    const { stat, user } = this.props

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/overview">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          {!stat.maeMfeReport.data || stat.isLoading ? (
            <DefaultLoader />
          ) : (
            <>
              {stat.maeMfeReport.data.error &&
              stat.maeMfeReport.data.error === 'no-content' ? (
                <div className="row">
                  <div className="col-12 col-md-6 offset-md-3">
                    <div className="mt-5 mb-5 text-center">
                      <h1>No trades found</h1>
                      <p>
                        Add{' '}
                        <strong>
                          <u>Highest and Lowest Prices</u>
                        </strong>{' '}
                        to your trade to calculate MAE and MFE
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <MaeMfeReportComponent data={stat.maeMfeReport.data} user={user.user} />
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(MaeMfaReportPage))
