/* eslint jsx-a11y/label-has-associated-control: 0 */
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { Select, AutoComplete, message } from 'antd'

import { API_URL } from '../../../../../config/app'

const GeneralTradeFormSection = ({ state, tradeData, updateState = (f) => f }) => {
  const [instrumentsOptions, handleInstrumentOptions] = useState([])

  const handleInstrumentsAutoComplete = (value) => {
    handleInstrumentOptions([])
    if (value.length >= 2) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('apiToken')}` },
      }
      axios
        .get(
          `${API_URL}/api/v1/instruments/autocomplete?market=${state.tradeMarket}&q=${value}`,
          config,
        )
        .then((res) => {
          handleInstrumentOptions(res.data.data)
        })
        .catch((e) => {
          message.error(e.message || 'Something goes wrong, try again later...')
        })
    }
  }

  return (
    <div className="trade-form__section">
      <div className="form-group">
        <label htmlFor="isTradeClosed">Is Trade Closed?</label>
        <Select
          className="w-100"
          defaultValue={state.isTradeClosed}
          onChange={(value) => updateState('isTradeClosed', parseInt(value, 10))}
        >
          <Select.Option value={1}>Yes</Select.Option>
          <Select.Option value={0}>No</Select.Option>
        </Select>
      </div>
      <div className="form-group">
        <label htmlFor="tradeMarket">Market</label>
        <Select
          className="w-100"
          defaultValue={state.tradeMarket.toString()}
          onChange={(value) => updateState('tradeMarket', parseInt(value, 10))}
        >
          <Select.Option value="0">Forex</Select.Option>
          <Select.Option value="1">Stock</Select.Option>
          <Select.Option value="2">Crypto</Select.Option>
          <Select.Option value="3">Futures</Select.Option>
          <Select.Option value="4">Options</Select.Option>
        </Select>
      </div>
      <div className="form-group">
        <label htmlFor="tradeAccount">Account</label>
        <Select
          className="w-100"
          value={state.tradeAccount}
          onChange={(value) => updateState('tradeAccount', parseInt(value, 10))}
        >
          {tradeData.accounts[0] &&
            tradeData.accounts.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
      <div className="form-group">
        <label htmlFor="tradeTS">Trading System</label>
        <Select
          className="w-100"
          value={state.tradeTS}
          onChange={(value) => updateState('tradeTS', parseInt(value, 10))}
        >
          {tradeData.tradingSystems[0] &&
            tradeData.tradingSystems.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
      <div className="form-group">
        <label htmlFor="tradeInstrument">Instrument</label>
        <AutoComplete
          onSearch={handleInstrumentsAutoComplete}
          onChange={(value) => updateState('tradeInstrument', value)}
          onBlur={(event) => updateState('tradeInstrument', event.target.value)}
          placeholder="Input instrument name..."
          className="w-100"
          defaultValue={state.tradeInstrument}
        >
          {instrumentsOptions.map((item) => {
            return (
              <AutoComplete.Option value={item.name} key={uuidv4()}>
                {item.name}
              </AutoComplete.Option>
            )
          })}
        </AutoComplete>
      </div>
      <div className="form-group">
        <label htmlFor="tradeOption">Action</label>
        <Select
          className="w-100"
          defaultValue={state.tradeOption.toString()}
          onChange={(value) => updateState('tradeOption', parseInt(value, 10))}
        >
          <Select.Option value="0">Sell</Select.Option>
          <Select.Option value="1">Buy</Select.Option>
        </Select>
      </div>
      <div className="form-group">
        <label htmlFor="tradeTimeFrame">Time frame</label>
        <Select
          className="w-100"
          defaultValue={state.tradeTimeFrame}
          onChange={(value) => updateState('tradeTimeFrame', parseInt(value, 10))}
        >
          {tradeData.timeframes[0] &&
            tradeData.timeframes.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
    </div>
  )
}

export default GeneralTradeFormSection
