import React, { useState } from 'react'
import moment from 'moment'

import SubscribeButton from './SubscribeButton'
import CancelSubscriptionModal from '../../../ui-2/subscriptions/CancelSubscriptionModal'

// eslint-disable-next-line no-unused-vars
const SubscriptionDetails = ({ user, subscription }) => {
  const [cancelSubscription, setCancelSubscription] = useState(false)

  return (
    <>
      <p>
        Your current plan: <strong>Premium</strong>
      </p>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
              <th>Status</th>
              <th>Last payment</th>
              <th>
                {subscription.state === 'deleted'
                  ? 'Premium active until'
                  : 'Next payment'}
              </th>
              <th>Management</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Tradiry Premium</td>
              <td style={{ textTransform: 'capitalize' }}>{subscription.status}</td>
              <td>
                {subscription.lastPayment &&
                  moment(subscription.lastPayment).format('dddd, MMMM Do YYYY')}
              </td>
              <td>
                {subscription.status !== 'Active' ? (
                  <>-</>
                ) : (
                  <>
                    {subscription.nextPayment &&
                      moment(subscription.nextPayment).format('dddd, MMMM Do YYYY')}
                  </>
                )}
              </td>
              <td>
                {subscription.status === 'Active' ? (
                  <a
                    href="/"
                    className="btn"
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={(e) => {
                      e.preventDefault()
                      setCancelSubscription(true)
                    }}
                  >
                    Cancel
                  </a>
                ) : (
                  <SubscribeButton
                    user={user}
                    btnText="Subscribe"
                    btnSize="small"
                    fullWidth=""
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <CancelSubscriptionModal
        visible={cancelSubscription}
        setVisibility={setCancelSubscription}
      />
    </>
  )
}

export default SubscriptionDetails
