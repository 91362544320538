/* eslint react/no-unused-state:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react'
import axios from 'axios'
import ym from 'react-yandex-metrika'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Drawer, Button, Skeleton, message } from 'antd'

import actions from '../../../../redux/filter/actions'
import { API_URL } from '../../../../config/app'

import FiltersContent from './FiltersContent'

const initialState = {
  loading: true,
  data: [],
  filters: [],
}

const mapStateToProps = ({ filter }) => ({
  filter,
})

class FiltersDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  getFormData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('apiToken')}`,
      },
    }
    axios
      .get(`${API_URL}/api/v1/data/common/trade-form-data`, config)
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data, loading: false })
        } else {
          message.error({
            content: 'Something goes wrong. Reload the page and try again.',
          })
        }
      })
      .catch(() => {
        message.error({ content: 'Something goes wrong. Reload the page and try again.' })
      })
  }

  onChangeVisibility = (visible) => {
    if (visible) {
      const { filter } = this.props
      this.getFormData()
      this.setState({ filters: filter })
    } else {
      this.setState(initialState)
    }
  }

  updateFiltersState = (target, value) => {
    const newFiltersState = this.state
    newFiltersState.filters[target] = value
    this.setState(newFiltersState)
  }

  updateFilterStateDateRange = (from, to) => {
    const newFiltersState = this.state
    newFiltersState.filters.from = from
    newFiltersState.filters.to = to
    this.setState(newFiltersState)
  }

  applyFiltersHandler = () => {
    const { dispatch, handleDrawerVisibility } = this.props
    const { filters } = this.state
    filters.isEnabled = true
    handleDrawerVisibility(false)
    ym('reachGoal', 'userUseFilters')
    dispatch({
      type: actions.APPLY,
      payload: filters,
    })
  }

  resetFiltersHandler = () => {
    const { dispatch, handleDrawerVisibility } = this.props
    handleDrawerVisibility(false)
    dispatch({
      type: actions.RESET,
    })
  }

  render() {
    const { isVisible, handleDrawerVisibility, filter } = this.props
    const { loading, data } = this.state
    return (
      <Drawer
        placement="top"
        title="Filters"
        closable
        maskClosable
        onClose={() => handleDrawerVisibility(false)}
        visible={isVisible}
        afterVisibleChange={this.onChangeVisibility}
        height={512}
        getContainer={false}
        style={{ position: 'absolute' }}
        destroyOnClose
        footer={
          loading ? null : (
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.resetFiltersHandler} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button onClick={this.applyFiltersHandler} type="primary">
                Apply
              </Button>
            </div>
          )
        }
      >
        {loading ? (
          <>
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <FiltersContent
            filter={filter}
            data={data}
            updateState={this.updateFiltersState}
            updateDateRange={this.updateFilterStateDateRange}
          />
        )}
      </Drawer>
    )
  }
}

export default withRouter(connect(mapStateToProps)(FiltersDrawer))
