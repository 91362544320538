import React from 'react'

import styles from './styles.module.scss'

const DefaultLoader = () => {
  return (
    <div className="mt-3">
      <div className={styles.DefaultLoader}>
        <div className={styles.Loader}>
          <svg
            width="604"
            height="376"
            viewBox="0 0 604 376"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M206.106 19.9734L206.079 10H196.106H20H10V20V57.5319V67.5319H20H149.455V356V366H159.455H197H207.027L207 355.973L206.106 19.9734Z"
              fill="#007BE8"
              stroke="#007BE8"
              strokeWidth="20"
              className={styles.elem1}
            />
            <path
              d="M280 10H270V20V55.7447V65.7447H280H378.287C394.543 65.7447 404.051 68.6138 409.555 73.3001C414.669 77.6541 418.324 85.3504 418.324 100.426V127.234C418.324 141.364 415.368 148.809 410.63 153.141C405.636 157.709 396.312 161.021 378.287 161.021H378.196L378.105 161.023L279.818 162.81L270 162.989V172.809V202.298V206.301L272.762 209.198L419.299 362.9L422.254 366H426.537H473H496.081L480.296 349.161L357.027 217.66H364.884C402.772 217.66 430.305 212.179 448.044 196.805C466.264 181.015 471.672 157.084 471.384 127.186V93.2766C471.384 65.2352 463.976 43.551 447.073 29.2358C430.543 15.2372 406.876 10 378.287 10H280Z"
              fill="#007BE8"
              stroke="#007BE8"
              strokeWidth="20"
              className={styles.elem2}
            />
            <circle
              cx="581"
              cy="352"
              r="23"
              fill="#007BE8"
              className={styles.elem3}
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default DefaultLoader
