import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import styles from './trade-screenshots.module.scss'

const TradeScreenshots = ({ trade }) => {
  const [currentImageIndex, handleCurrentImageIndex] = useState(0)
  const [isLightBoxOpen, handleLightBoxVisibility] = useState(false)

  const openLightBoxOnSelectedImage = (index) => {
    handleCurrentImageIndex(index)
    handleLightBoxVisibility(true)
  }

  return (
    <div className={styles.tradeScreenshots}>
      <div className="row">
        {trade.get_images.map((image, index) => (
          <div className="col-6 col-md-4 col-lg-3" key={`trade-image_${image.id}`}>
            <div className={styles.screenshot}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                src={image.path}
                className="img-fluid"
                alt={image.id}
                onClick={() => openLightBoxOnSelectedImage(index)}
              />
            </div>
          </div>
        ))}
      </div>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={trade.get_images[currentImageIndex].path}
          onCloseRequest={() => handleLightBoxVisibility(false)}
        />
      )}
    </div>
  )
}

export default TradeScreenshots
