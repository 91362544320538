import React from 'react'
import moment from 'moment'
import { Select, DatePicker } from 'antd'

const filterItemClass = 'col-12 col-md-4 col-lg-3 form-group'

const FiltersContent = ({
  filter,
  data,
  updateState = (f) => f,
  updateDateRange = (f) => f,
}) => {
  const handleDateRange = (dates, dateStrings) => {
    updateDateRange(dateStrings[0], dateStrings[1])
  }

  return (
    <div className="row global-filters">
      <div className={filterItemClass}>
        <p className="mb-1">Market</p>
        <Select
          mode="multiple"
          className="w-100"
          defaultValue={filter.tradeMarket}
          onChange={(value) => updateState('tradeMarket', value)}
        >
          <Select.Option value={0}>Forex</Select.Option>
          <Select.Option value={1}>Stock</Select.Option>
          <Select.Option value={2}>Crypto</Select.Option>
          <Select.Option value={3}>Futures</Select.Option>
          <Select.Option value={4}>Options</Select.Option>
        </Select>
      </div>
      <div className={filterItemClass}>
        <p className="mb-1">Action</p>
        <Select
          mode="multiple"
          className="w-100"
          defaultValue={filter.tradeAction}
          onChange={(value) => updateState('tradeAction', value)}
        >
          <Select.Option value={0}>Sell</Select.Option>
          <Select.Option value={1}>Buy</Select.Option>
        </Select>
      </div>
      <div className={filterItemClass}>
        <p className="mb-1">Account</p>
        <Select
          mode="multiple"
          className="w-100"
          defaultValue={filter.tradeAccount}
          onChange={(value) => updateState('tradeAccount', value)}
        >
          {data.accounts &&
            data.accounts.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
      <div className={filterItemClass}>
        <p className="mb-1">Instrument</p>
        <Select
          mode="multiple"
          className="w-100"
          optionFilterProp="children"
          defaultValue={filter.tradeInstrument}
          onChange={(value) => updateState('tradeInstrument', value)}
        >
          {data.instruments &&
            data.instruments.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
      <div className={filterItemClass}>
        <p className="mb-1">Trading System</p>
        <Select
          mode="multiple"
          className="w-100"
          defaultValue={filter.tradeTS}
          onChange={(value) => updateState('tradeTS', value)}
        >
          {data.tradingSystems &&
            data.tradingSystems.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
      <div className={filterItemClass}>
        <p className="mb-1">Time Frame</p>
        <Select
          mode="multiple"
          className="w-100"
          defaultValue={filter.tradeTimeFrame}
          onChange={(value) => updateState('tradeTimeFrame', value)}
        >
          {data.timeframes &&
            data.timeframes.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
        </Select>
      </div>
      <div className={filterItemClass}>
        <p className="mb-1">Date Range</p>
        <DatePicker.RangePicker
          ranges={{
            'This Week': [moment().startOf('week'), moment()],
            'This Month': [moment().startOf('month'), moment()],
            'This Year': [moment().startOf('year'), moment()],
            'Last 30 days': [moment().subtract(30, 'days'), moment()],
            'Last 90 days': [moment().subtract(90, 'days'), moment()],
            'Last 180 days': [moment().subtract(180, 'days'), moment()],
          }}
          format="YYYY-MM-DD"
          className="w-100"
          defaultValue={filter.from !== '' && [moment(filter.from), moment(filter.to)]}
          onChange={handleDateRange}
        />
      </div>
      {data.advancedStats && data.advancedStats.length > 0 ? (
        <>
          {data.advancedStats.map((stat) => {
            if (stat.get_values && stat.get_values.length > 0) {
              return (
                <div key={stat.id} className={filterItemClass}>
                  <label htmlFor={`advancedStat_${stat.id}`}>{stat.name}</label>
                  <Select
                    mode="multiple"
                    id={`advancedStat_${stat.id}`}
                    className="w-100"
                    defaultValue={
                      filter[`advancedStat_${stat.id}`] &&
                      filter[`advancedStat_${stat.id}`]
                    }
                    onChange={(value) => updateState(`advancedStat_${stat.id}`, value)}
                  >
                    {stat.get_values.map((value) => {
                      return (
                        <Select.Option value={value.id} key={value.id}>
                          {value.value}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </div>
              )
            }
            return null
          })}
        </>
      ) : null}
    </div>
  )
}

export default FiltersContent
