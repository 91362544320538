/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb, message, PageHeader, Skeleton } from 'antd'

import axiosRequest from '../../../config/axios'
import axiosError from '../../../helpers/axios-error'
import SimulatorChart from '../../../components/pages/tools/simulator/SimulatorChart'
import SimulatorFilters from '../../../components/pages/tools/simulator/SimulatorFilters'
import convertObjToUrlParams from '../../../helpers/convert-obj-to-url-params'

const mapStateToProps = ({ user }) => ({ user })

const SimulatorPage = ({ user }) => {
  const pageTitle = 'Simulator'
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [filters, setFilters] = useState({
    months: [],
    weekdays: [],
    hours: [],
    instruments: [],
  })
  const [filtersData, setFiltersData] = useState({})

  const getData = (insertFilters = false, useQueryString = false) => {
    let queryString = ''
    if (useQueryString) {
      queryString = convertObjToUrlParams(filters)
    }
    axiosRequest(`/api/v1/tools/simulator${queryString}`, 'get', null, true)
      .then((res) => {
        setLoading(false)
        setData(res.data.data)
        if (insertFilters) {
          setFiltersData({
            months: res.data.data.filters.months,
            weekdays: res.data.data.filters.weekdays,
            hours: res.data.data.filters.hours,
            instruments: res.data.data.instruments,
          })
          setFilters({
            months: _.map(res.data.data.filters.months, 'value'),
            weekdays: _.map(res.data.data.filters.weekdays, 'value'),
            hours: _.map(res.data.data.filters.hours, 'value'),
            instruments: _.map(res.data.data.instruments, 'value'),
          })
        }
      })
      .catch((e) => {
        axiosError(e)
      })
      .finally(() => {
        message.destroy()
      })
  }

  useEffect(() => {
    getData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateFilters = () => {
    message.loading({ content: 'Loading...', key: 'filtersUpdate' })
    getData(false, true)
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <div className="animate__animated animate__fadeIn">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Tools</Breadcrumb.Item>
          <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title={pageTitle} backIcon={false} />
        <p>
          The simulator allows you to see how your trading would have changed if you had
          not traded certain instruments or for a certain period of time.
        </p>
      </div>
      <div className="animate__animated animate__fadeIn">
        {loading || !data ? (
          <Skeleton active />
        ) : (
          <div className="row">
            <div className="col-12 col-lg-8">
              <SimulatorChart data={data} user={user.user} />
            </div>
            <div className="col-12 col-lg-4">
              <SimulatorFilters
                filtersData={filtersData}
                filters={filters}
                setFilters={updateFilters}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(SimulatorPage))
