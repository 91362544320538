import React from 'react'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import Card from '../../ui-2/card'

import dashboardImage from '../../../assets/svg/dashboard.svg'

const NotEnoughData = () => {
  return (
    <Card>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <img src={dashboardImage} alt="No trades found" />
        </div>
        <div className="col-12 col-md-6">
          <div className="mt-5 mb-5 text-center">
            <h1>No trades found</h1>
            <p>Stats will appear as soon as you add your first trade</p>
            <div>
              <p className="mb-1">
                <strong>How to start working with Tradiry:</strong>
              </p>
              <ul className="text-left">
                <li className="mb-1">
                  Manage your accounts, add deposits/withdrawals for correct calculations.{' '}
                  <br />
                  (To add account transaction go to{' '}
                  <Link to="/accounts">
                    <strong>Accounts</strong>
                  </Link>{' '}
                  &gt; choose account and click <strong>Details</strong> &gt; Click{' '}
                  <strong>Add transaction</strong> button and fill the form)
                </li>
                <li className="mb-1">
                  Change trading system name, add new one or put some information.{' '}
                  <Link to="/trading-systems">Click here</Link>
                </li>
                <li className="mb-1">
                  Add advanced stats to get more information from your trades.{' '}
                  <Link to="/advanced-stats">Click here</Link>
                </li>
                <li className="mb-1">
                  Start adding your trades <Link to="/create/trade">manually</Link> or use{' '}
                  <Link to="/settings/import-trades">trades importer</Link>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <Link to="/trades/form/create" className="ant-btn ant-btn-primary">
                <PlusOutlined /> Add new trade
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default NotEnoughData
