const actions = {
  SET_STATE: 'trade/SET_STATE',
  LIST: 'trade/LIST',
  GET_DATA: 'trade/GET_DATA',
  GET_TRADE_LIST_DATA: 'trade/GET_TRADE_LIST_DATA',
  SINGLE: 'trade/SINGLE',
  SAVE: 'trade/SAVE',
  UPDATE: 'trade/UPDATE',
  DELETE: 'trade/DELETE',
  SAVE_NOTE: 'trade/notes/SAVE',
  UPDATE_NOTE: 'trade/notes/UPDATE',
  DELETE_NOTE: 'trade/notes/DELETE',
  MULTIPLE_DELETE: 'trade/MULTIPLE_DELETE',
  ENABLE_SHARE_TRADE: 'trade/ENABLE_SHARE_TRADE',
  DISABLE_SHARE_TRADE: 'trade/DISABLE_SHARE_TRADE',

  RESET: 'trade/RESET',
  RESET_SINGLE_TRADE: 'trade/RESET_SINGLE_TRADE',
}

export default actions
