import React, { useState, useEffect } from 'react'
import ym from 'react-yandex-metrika'
import { Link } from 'react-router-dom'
import { TbSearch, TbMenu2 } from 'react-icons/tb'

import styles from './style.module.scss'

import UserMenu from './UserMenu'
import FiltersDrawer from './Filters'
import SearchModal from '../../ui-2/layouts/header/search/SearchModal'

const Header = ({ filter, handleMobileMenu }) => {
  const [layout, setLayout] = useState('light')
  const [searchModal, setSearchModal] = useState(false)
  const [filterDrawer, handleFilterDrawerVisibility] = useState(false)

  useEffect(() => {
    const getLayout = localStorage.getItem('trLayout')
    let newLayout = 'light'
    if (getLayout) {
      newLayout = getLayout
    }
    setLayout(newLayout)
    localStorage.setItem('trLayout', newLayout)
    document.body.classList.remove('light-theme')
    document.body.classList.remove('dark-theme')
    document.body.classList.add(`${newLayout}-theme`)
    if (newLayout === 'dark') {
      document.getElementsByTagName('meta')['theme-color'].content = '#161d31'
    } else {
      document.getElementsByTagName('meta')['theme-color'].content = '#007be8'
    }
    document.body.setAttribute('data-theme', newLayout)
  }, [layout])

  const handleSwitchTheme = () => {
    let newLayout = 'light'
    if (layout === 'light') {
      newLayout = 'dark'
    }
    setLayout(newLayout)
    localStorage.setItem('trLayout', newLayout)
    if (newLayout === 'dark') {
      ym('reachGoal', 'darkModeUsed')
    }
    window.location.reload()
  }

  return (
    <div className={`tradiry-header ${styles.appHeader}`}>
      <div className={styles.appHeaderContainer}>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.headerContent}`}
        >
          <div className={styles.headerLeftMenu}>
            <ul>
              <li>
                <Link
                  to="/"
                  onClick={(e) => {
                    e.preventDefault()
                    handleMobileMenu(e)
                  }}
                >
                  <TbMenu2 />
                </Link>
              </li>
              <li className="d-none">
                <Link
                  to="/"
                  onClick={(e) => {
                    e.preventDefault()
                    setSearchModal(true)
                  }}
                  className={styles.search}
                >
                  <div className={styles.icon}>
                    <TbSearch />
                  </div>
                  <div className={styles.searchPlaceholder}>
                    Search company or stock symbol...
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <UserMenu
            filter={filter}
            handleSwitchTheme={handleSwitchTheme}
            handleFilterDrawerVisibility={handleFilterDrawerVisibility}
          />
        </div>
      </div>
      <FiltersDrawer
        isVisible={filterDrawer}
        handleDrawerVisibility={handleFilterDrawerVisibility}
      />
      <SearchModal visible={searchModal} onClose={setSearchModal} />
    </div>
  )
}

export default Header
