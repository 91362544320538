import React from 'react'
import { Link } from 'react-router-dom'

import longNumberToShort from '../../../../../helpers/convertLongNumberToShort'

import styles from './styles.module.scss'

const ListItem = ({ data }) => {
  return (
    <Link
      to={`/analysis/stocks/symbol/${data.slug}`}
      className={styles.Item}
      title={data.name}
    >
      <div className="row">
        <div className="col-5 col-md-4 col-xl-4">
          <div className={styles.Instrument}>
            {data.image && (
              <div className={styles.Instrument__Image}>
                <div className={styles.Instrument__Image__Wrapper}>
                  <img src={data.image} fill alt={data.name} />
                </div>
              </div>
            )}
            <div className={styles.Instrument__Name}>
              <span>{data.name}</span> {data.symbol}
            </div>
          </div>
        </div>
        <div className="col-2 col-xl-1" data-value="true">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            data.quote?.price || 0,
          )}
        </div>
        <div
          className="col-2 col-xl-1 d-none d-md-flex d-xl-flex"
          data-value="true"
          data-trend={data.quote && data.quote.changesPercentage >= 0 ? 'up' : 'down'}
        >
          {new Intl.NumberFormat().format(data.quote?.changesPercentage || 0)}%
        </div>
        <div
          className="col-2 col-xl-1 d-none d-xl-flex"
          data-value="true"
          data-trend={data.quote && data.quote.change >= 0 ? 'up' : 'down'}
        >
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            data.quote?.change || 0,
          )}
        </div>
        <div className="col-2 col-xl-1" data-value="true">
          {longNumberToShort(data.quote?.volume || 0)}
        </div>
        <div className="col-2 col-xl-1" data-value="true">
          {longNumberToShort(data.quote?.marketCap || 0)}
        </div>
        <div className="col-2 col-xl-1 d-none d-xl-flex" data-value="true">
          {new Intl.NumberFormat().format(data.quote?.pe || 0)}
        </div>
        <div className="col-2 col-xl-1 d-none d-xl-flex" data-value="true">
          ${longNumberToShort(data.quote?.eps || 0)}
        </div>
        <div className="col-2 col-xl-1 d-none d-xl-flex" data-value="true">
          {longNumberToShort(
            data.stockInfo && data.stockInfo.employees
              ? parseInt(data.stockInfo.employees, 10)
              : 0,
          )}
        </div>
      </div>
    </Link>
  )
}

export default ListItem
