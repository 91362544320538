import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Button, Form, Input, message } from 'antd'

import Card from '../../../components/ui-2/card'

import actions from '../../../redux/user/actions'

const mapStateToProps = ({ user }) => ({ user })

class PasswordSettingsPage extends React.Component {
  constructor(props) {
    super(props)

    this.onFinish = this.onFinish.bind(this)
  }

  onFinish(values) {
    const { dispatch } = this.props
    // console.log(values)
    if (values.new_password !== values.confirm_password) {
      message.error({
        content: 'Passwords must be at least six characters and match the confirmation.',
        key: 'change_password',
      })
      return false
    }
    if (values.new_password.length < 6) {
      message.error({
        content: 'Passwords must be at least six characters and match the confirmation.',
        key: 'change_password',
      })
      return false
    }
    dispatch({
      type: actions.CHANGE_PASSWORD,
      payload: values,
    })
    return true
  }

  // eslint-disable-next-line class-methods-use-this
  onFinishFailed() {
    message.error({ content: 'Something goes wrong...', key: 'change_password' })
  }

  render() {
    const pageTitle = 'Change Password'
    const { user } = this.props

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader title={pageTitle} backIcon={false} />
        </div>
        <div className="animate__animated animate__fadeIn">
          <div className="row mt-3 mb-5">
            <div className="col-12 col-md-8 col-lg-4 offset-md-2 offset-lg-4">
              <Card>
                <Form
                  layout="vertical"
                  hideRequiredMark
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                >
                  <Form.Item
                    name="current_password"
                    label="Current Password"
                    hasFeedback
                    rules={[
                      { required: true, message: 'Please input your current password' },
                    ]}
                  >
                    <Input
                      size="large"
                      type="password"
                      placeholder="Input current password"
                    />
                  </Form.Item>
                  <Form.Item
                    name="new_password"
                    label="New Password"
                    hasFeedback
                    rules={[{ required: true, message: 'Please input new password' }]}
                  >
                    <Input
                      size="large"
                      type="password"
                      placeholder="Input new password"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    label="Confirm New Password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please input new password confirmation',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="password"
                      placeholder="Confirm new password"
                    />
                  </Form.Item>
                  <div className="form-group">
                    <Button
                      type="primary"
                      size="large"
                      className="text-center w-100 fw-600"
                      htmlType="submit"
                      loading={user.loading}
                    >
                      Change Password
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(PasswordSettingsPage))
