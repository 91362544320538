import React, { useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import { Link } from 'react-router-dom'

import SliderItem from './SliderItem'

import styles from './news-slider.module.scss'

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : ''

  return (
    <svg
      width="24"
      height="24"
      onClick={props.onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld} ${
        props.className
      }`}
    >
      {props.left ? (
        <path
          d="M17.6 4L9.6 12L17.6 20H13.6L6.3 12.7C5.9 12.3 5.9 11.7 6.3 11.3L13.6 4H17.6Z"
          fill="black"
        />
      ) : (
        <path
          d="M6 4L14 12L6 20H10L17.3 12.7C17.7 12.3 17.7 11.7 17.3 11.3L10 4H6Z"
          fill="black"
        />
      )}
    </svg>
  )
}

const NewsSlider = ({ news, title, subtitle }) => {
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    breakpoints: {
      '(min-width: 10px)': {
        slides: {
          perView: 1,
          spacing: 16,
        },
      },
      '(min-width: 520px)': {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      '(min-width: 740px)': {
        slides: {
          perView: 2,
          spacing: 24,
        },
      },
      '(min-width: 1200px)': {
        slides: {
          perView: 3,
          spacing: 24,
        },
      },
      '(min-width: 1600px)': {
        slides: {
          perView: 4,
          spacing: 24,
        },
      },
    },
  })

  return (
    <div className={styles.NewsList}>
      <div>
        <div className={styles.NewsList__TitleWrapper}>
          {title && <h2 className={styles.Title}>{title}</h2>}
          {subtitle && <p>{subtitle}</p>}
          <Link to="/analysis/stocks/all-news">
            See All{' '}
            <svg
              width="7"
              height="9"
              viewBox="0 0 7 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.52344 0L0.46875 1.05469L3.87891 4.5L0.46875 7.94531L1.52344 9L6.02344 4.5L1.52344 0Z"
                fill="black"
              ></path>
            </svg>
          </Link>
        </div>
        <div className={styles.News}>
          <div className={styles.News__Content}>
            {loaded && instanceRef && instanceRef.current ? (
              <>
                <Arrow
                  left
                  onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
                  disabled={currentSlide === 0}
                  className={styles.Arrow}
                />

                <Arrow
                  className={styles.ArrowRight}
                  onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
                  disabled={
                    currentSlide === instanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            ) : null}
            <div ref={sliderRef} className="keen-slider">
              {news.map((item) => (
                <div
                  key={`${item.url}${item.publishedDate}`}
                  className="keen-slider__slide"
                >
                  <SliderItem item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsSlider
