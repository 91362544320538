import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, Button, PageHeader, Card, Popconfirm, message } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

import actions from '../../../redux/trading-system/actions'

import DefaultLoader from '../../../components/system/Loaders/default'

const mapStateToProps = ({ user, tradingSystem }) => ({
  user,
  tradingSystem,
})

class SingleTradingSystemPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deletePopConfirm: false,
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch({
      type: actions.SINGLE,
      payload: {
        slug: match.params.slug,
      },
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  handlePopConfirmVisibility = () => {
    const { deletePopConfirm } = this.state
    if (!deletePopConfirm) {
      this.setState({ deletePopConfirm: true })
    } else {
      this.setState({ deletePopConfirm: false })
    }
  }

  handleTSDelete = () => {
    message.loading({ content: 'Please, wait...', key: 'delete_ts' })
    const { dispatch, match } = this.props
    dispatch({
      type: actions.DELETE,
      payload: {
        slug: match.params.slug,
      },
    })
  }

  render() {
    const { tradingSystem } = this.props
    const { deletePopConfirm } = this.state

    if (tradingSystem.isListLoading) {
      return <DefaultLoader />
    }

    if (!tradingSystem.tradingSystem.id) {
      return null
    }

    const ts = tradingSystem.tradingSystem
    const pageTitle = ts.name

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/trading-systems">Trading Systems</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Link to={`/trading-systems/edit/${ts.slug}`} className="ant-btn ant-btn-success" key="1">
                <EditOutlined />
                <span className="ml-2">Edit</span>
              </Link>,
              <Button type="danger" key="2">
                <Popconfirm
                  title="Do you really want to delete this trading system? All related trades will be deleted."
                  placement="bottomRight"
                  visible={deletePopConfirm}
                  onVisibleChange={this.handlePopConfirmVisibility}
                  okText="Yes"
                  okType="danger"
                  onConfirm={this.handleTSDelete}
                >
                  <DeleteOutlined />
                  <span className="ml-2">Delete</span>
                </Popconfirm>
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__slideInUp">
          <Card className="mb-4">
            <div className="type__wrapper" dangerouslySetInnerHTML={{ __html: ts.get_latest_revision.body }} />
          </Card>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(SingleTradingSystemPage))
