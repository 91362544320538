import React, { useEffect, useState } from 'react'
import { Input, Modal, Skeleton } from 'antd'
import { TbSearch } from 'react-icons/tb'

import axiosRequest from '../../../../../config/axios'
import axiosError from '../../../../../helpers/axios-error'

const SearchModal = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [results, setResults] = useState([])

  useEffect(() => {
    if (search && search.length >= 1) {
      setLoading(true)
      axiosRequest(
        `/api/v1/analysis/stock/autocomplete-search?q=${search}`,
        'get',
        null,
        true,
      )
        .then((res) => {
          console.log(res.data.results)
          setResults(res.data.results)
        })
        .catch((e) => axiosError(e))
        .finally(() => setLoading(false))
    }
  }, [search])

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setLoading(false)
        setSearch('')
        setResults([])
        onClose(false)
      }}
      centered
      width={600}
      title={
        <>
          <Input
            value={search}
            size="large"
            placeholder="Search company or stock..."
            className="borderless box-fluid stock-search px-0"
            prefix={<TbSearch />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
        </>
      }
      footer={false}
      destroyOnClose
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>{results && results.length > 0 ? 'Found' : 'NotFound'}</>
      )}
    </Modal>
  )
}

export default SearchModal
