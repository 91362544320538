import React from 'react'
import ym from 'react-yandex-metrika'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { Breadcrumb, PageHeader, Tabs, Card, Button, message, notification } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

import actions from '../../../redux/trade/actions'
import tradeFormValidator from '../../../components/ui/trades/helpers/tradeFormValidator'

import DefaultLoader from '../../../components/system/Loaders/default'
import TradeBasicDetails from '../../../components/ui/trades/form/TradeBasicDetailsForm'
import TradeAdvancedForm from '../../../components/ui/trades/form/TradeAdvancedForm'

const mapStateToProps = ({ trade }) => ({ trade })

class CreateTradePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      regEx: /^-?\d*\.?(?:\d{1,6})?$/,
      tradeMarket: 0, // 0 - Forex, 1 - Stock, 2 - Crypto, 3 - Futures, 4 - Options
      tradeAccount: '',
      tradeTS: '',
      tradeTimeFrame: 1,
      tradeInstrument: '',
      tradeOption: 0, // 0 - Sell, 1 - Buy
      entryDate: moment(),
      entryPrice: 0,
      tradeQty: 1,
      takeProfit: 0,
      stopLoss: 0,
      exitDate: moment(),
      exitPrice: 0,
      profitLoss: 0,
      fees: 0,
      highestPrice: 0,
      lowestPrice: 0,
      isHitTP: 0,
      advancedStats: [],
      uploadedImages: [],
      tradeDescription: '',
      isTradeClosed: 1,
    }

    this.updateState = this.updateState.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.GET_DATA,
    })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { trade } = this.props
    if (trade.isListLoading === false && trade.data !== prevProps.trade.data) {
      const tradeData = trade.data
      // console.log(tradeData)
      if (tradeData.accounts[0] && tradeData.accounts[0].id) {
        // eslint-disable-next-line
        this.setState({ tradeAccount: tradeData.accounts[0].id })
      }
      if (tradeData.tradingSystems[0] && tradeData.tradingSystems[0].id) {
        // eslint-disable-next-line
        this.setState({ tradeTS: tradeData.tradingSystems[0].id })
      }
      if (tradeData.timeframes[0] && tradeData.timeframes[0].id) {
        // eslint-disable-next-line
        this.setState({ tradeTimeFrame: tradeData.timeframes[0].id })
      }
      const advancedStats = []
      if (tradeData.advancedStats[0]) {
        tradeData.advancedStats.forEach((item) => {
          const advancedStatsValues = []
          if (item.get_values[0]) {
            item.get_values.forEach((value) => {
              advancedStatsValues.push(value)
            })
          }
          advancedStats.push({
            id: item.id,
            name: item.name,
            selected: '',
            values: advancedStatsValues,
          })
        })
        // eslint-disable-next-line
        this.setState({ advancedStats: advancedStats })
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  updateState = (target, value) => {
    this.setState({ [target]: value })
  }

  submitFormHandler = (event) => {
    event.preventDefault()
    const validate = tradeFormValidator(this.state)
    if (validate === true) {
      message.loading({ content: 'Loading...', key: 'save_trade' })
      // Create trade JSON
      const { state } = this
      const { dispatch } = this.props
      let { highestPrice } = state
      let { lowestPrice } = state
      const advancedStatsArray = []
      if (highestPrice === '') {
        highestPrice = 0
      }
      if (lowestPrice === '') {
        lowestPrice = 0
      }
      // Create advanced stats array
      if (state.advancedStats[0]) {
        state.advancedStats.forEach((item) => {
          if (item.selected && item.selected !== '') {
            advancedStatsArray.push({ stat: item.id, value: item.selected })
          }
        })
      }
      const tradeJSON = {
        tradeMarket: state.tradeMarket,
        tradeAccount: state.tradeAccount,
        tradeTS: state.tradeTS,
        tradeInstrument: state.tradeInstrument,
        tradeOption: state.tradeOption,
        tradeTimeFrame: state.tradeTimeFrame,
        entryDate: state.entryDate.format('YYYY-MM-DD HH:mm'),
        entryPrice: state.entryPrice,
        tradeQty: state.tradeQty,
        takeProfit: state.takeProfit || 0,
        stopLoss: state.stopLoss || 0,
        exitDate: state.exitDate.format('YYYY-MM-DD HH:mm'),
        exitPrice: state.exitPrice,
        profitLoss: state.profitLoss,
        fees: state.fees || 0,
        highestPrice,
        lowestPrice,
        isHitTP: state.isHitTP,
        advancedStats: advancedStatsArray,
        uploadedImages: state.uploadedImages,
        tradeDescription: state.tradeDescription,
        isTradeClosed: state.isTradeClosed === 0,
      }
      dispatch({
        type: actions.SAVE,
        payload: tradeJSON,
      })
      ym('reachGoal', 'userCreateTrade')
    } else {
      notification.error({ message: 'Error', description: validate })
    }
  }

  render() {
    const pageTitle = 'Create new trade'
    const { trade } = this.props

    if (trade.isListLoading) {
      return <DefaultLoader />
    }

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Button key="1" type="primary" htmlType="submit" form="tradeMainForm">
                <SaveOutlined /> Save
              </Button>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__slideInUp">
          <Card className="tr-trade-form mb-5">
            <form id="tradeMainForm" onSubmit={this.submitFormHandler}>
              <Tabs defaultActiveKey="1" size="large" className="tr-tabs" animated>
                <Tabs.TabPane key="1" tab="Details">
                  <TradeBasicDetails
                    state={this.state}
                    updateState={this.updateState}
                    tradeData={trade.data}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Advanced">
                  <TradeAdvancedForm state={this.state} updateState={this.updateState} />
                </Tabs.TabPane>
              </Tabs>
            </form>
          </Card>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(CreateTradePage))
