import React, { useState } from 'react'
import { Select } from 'antd'
import { Bar } from 'react-chartjs-2'

import colors from '../../../../config/apexcharts/colors'
import longNumberToShort from '../../../../helpers/longNumberToShort'

const InstrumentsReportChart = ({ data, user }) => {
  const [chartView, handleChartView] = useState('percents')

  const options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
            // eslint-disable-next-line no-unused-vars
            callback(value, index, values) {
              if (chartView === 'money') {
                return `${longNumberToShort(value, '0.0a')} ${user.get_currency.short_name}`
              }
              return `${longNumberToShort(value, '0.0a')}%`
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: colors.gridLinesColor,
            zeroLineColor: colors.gridLinesColor,
          },
          ticks: {
            fontColor: colors.labelColor,
          },
        },
      ],
    },
  }

  const chartData = {
    labels: [],
    datasets: [
      {
        label: 'Result',
        type: 'bar',
        data: [],
        fill: false,
        borderWidth: 2,
        borderColor: [],
        backgroundColor: [],
        pointBorderColor: [],
        pointBackgroundColor: [],
        pointHoverBackgroundColor: [],
        pointHoverBorderColor: [],
        maxBarThickness: 45,
      },
    ],
  }

  const renderChart = () => {
    data.forEach((value) => {
      chartData.labels.push(value.instrument)
      if (chartView === 'money') {
        chartData.datasets[0].data.push(parseFloat(value.data.returnMoney).toFixed(2))
      } else {
        chartData.datasets[0].data.push(parseFloat(value.data.returnPercents).toFixed(2))
      }
      if (parseFloat(value.data.returnMoney) < 0) {
        chartData.datasets[0].borderColor.push(colors.dangerColor)
        chartData.datasets[0].backgroundColor.push(colors.dangerColor)
        chartData.datasets[0].pointBorderColor.push(colors.dangerColor)
        chartData.datasets[0].pointBackgroundColor.push(colors.dangerColor)
        chartData.datasets[0].pointHoverBackgroundColor.push(colors.dangerColor)
        chartData.datasets[0].pointHoverBorderColor.push(colors.dangerColor)
      } else {
        chartData.datasets[0].borderColor.push(colors.successColor)
        chartData.datasets[0].backgroundColor.push(colors.successColor)
        chartData.datasets[0].pointBorderColor.push(colors.successColor)
        chartData.datasets[0].pointBackgroundColor.push(colors.successColor)
        chartData.datasets[0].pointHoverBackgroundColor.push(colors.successColor)
        chartData.datasets[0].pointHoverBorderColor.push(colors.successColor)
      }
    })
    return <Bar options={options} data={chartData} />
  }

  return (
    <div className="card card__stat m-b-30">
      <div className="card-content card-content__no-title">
        <div className="card-body">
          <div className="mb-4 d-flex justify-content-end align-items-center">
            <div className="mr-2">Performance: </div>
            <div>
              <Select defaultValue={chartView} onChange={(value) => handleChartView(value)}>
                <Select.Option value="money">
                  Profit/Loss({user.get_currency && user.get_currency.short_name})
                </Select.Option>
                <Select.Option value="percents">Profit/Loss(%)</Select.Option>
              </Select>
            </div>
          </div>
          <div className="m-h-500">{renderChart()}</div>
        </div>
      </div>
    </div>
  )
}

export default InstrumentsReportChart
