import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Breadcrumb, PageHeader, Popconfirm, Button, message } from 'antd'
import { PlusOutlined, QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons'

import Card from '../../../components/ui-2/card'

// eslint-disable-next-line import/no-cycle
import { history } from '../../../index'

import actions from '../../../redux/trade/actions'

import createUrlParamsStringFromFilters from '../../../helpers/createUrlParamsFromFilters'

import DefaultLoader from '../../../components/system/Loaders/default'
import TradesListTable from '../../../components/ui/trades/TradesListTable'
import TradesListPagination from '../../../components/ui/trades/TradesListPagination'

const mapStateToProps = ({ trade, user, filter }) => ({ trade, user, filter })

const initialState = {
  filters: {
    orderBy: 'exit_date',
    orderType: 'DESC',
    page: 1,
  },
  tradesChecked: [],
}

class TradesListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    const { dispatch, filter } = this.props
    const query = createUrlParamsStringFromFilters(filter)
    dispatch({
      type: actions.LIST,
      payload: {
        query: `${this.buildQueryStringFromState()}${query}`,
      },
    })
    dispatch({
      type: actions.GET_TRADE_LIST_DATA,
    })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { state } = this
    const { dispatch, filter } = this.props
    if (state.filters !== prevState.filters) {
      const query = createUrlParamsStringFromFilters(filter)
      dispatch({
        type: actions.LIST,
        payload: {
          query: `${this.buildQueryStringFromState()}${query}`,
        },
      })
    }
    if (filter !== prevProps.filter) {
      const query = createUrlParamsStringFromFilters(filter)
      dispatch({
        type: actions.LIST,
        payload: {
          query: `${this.buildQueryStringFromState()}${query}`,
        },
      })
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: actions.RESET,
    })
  }

  buildQueryStringFromState = () => {
    const { state } = this
    let string = '?'
    // Order by and type
    Object.keys(state.filters).forEach((key) => {
      if (
        !['page', 'orderBy', 'orderType', 'tradesPerPage', 'from', 'to'].includes(key)
      ) {
        state.filters[key].forEach((value) => {
          string += `&${key}[]=${value}`
        })
      } else {
        string += state.filters[key] === '' ? '' : `&${key}=${state.filters[key]}`
      }
    })
    return string
  }

  updateState = (target, value, isFilters = true, resetOrderType = false) => {
    if (isFilters) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const { state } = this
      if (resetOrderType) {
        this.setState({
          filters: { ...state.filters, [target]: value, orderType: 'DESC' },
        })
      } else {
        this.setState({ filters: { ...state.filters, [target]: value } })
      }
    } else {
      this.setState({ [target]: value })
    }
  }

  handleMultipleDelete = () => {
    const { dispatch } = this.props
    const { tradesChecked } = this.state
    message.loading({
      content: 'Please, wait...',
      duration: 300,
      key: 'multiple_delete_trade',
    })
    dispatch({
      type: actions.MULTIPLE_DELETE,
      payload: {
        query: this.buildQueryStringFromState(),
        ids: tradesChecked,
      },
    })
    this.setState({ tradesChecked: [] })
  }

  render() {
    const pageTitle = 'Trades'
    const { dispatch, trade, user } = this.props
    const { tradesChecked, filters } = this.state

    return (
      <>
        <div className="animate__animated animate__fadeIn">
          <Helmet title={pageTitle} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={pageTitle}
            backIcon={false}
            extra={[
              <Link to="/trades/form/create" key="1" className="ant-btn ant-btn-primary">
                <PlusOutlined />
                <span className="ml-2">Add new trade</span>
              </Link>,
              <Popconfirm
                key="3"
                title="Are you sure you want to delete selected trades?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                okType="danger"
                okText="Delete"
                onConfirm={this.handleMultipleDelete}
                cancelText="Cancel"
                placement="bottomRight"
              >
                <Button type="danger" className={tradesChecked.length === 0 && 'd-none'}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>,
            ]}
          />
        </div>
        <div className="animate__animated animate__faster animate__slideInUp">
          <Card wrapperClass="mb-5">
            {trade.isListLoading ? (
              <DefaultLoader />
            ) : (
              <div className="animate__animated animate__fadeIn">
                <TradesListTable
                  dispatch={dispatch}
                  state={filters}
                  trades={
                    trade.trades.data && trade.trades.data[0] ? trade.trades.data : []
                  }
                  trade={trade}
                  user={user.user}
                  updateState={this.updateState}
                  tradesChecked={tradesChecked}
                  history={history}
                  buildQueryStringFromState={this.buildQueryStringFromState}
                />
                {trade.trades.data && (
                  <TradesListPagination
                    currentPage={trade.trades.current_page}
                    totalPages={trade.trades.last_page}
                    updateState={this.updateState}
                  />
                )}
              </div>
            )}
          </Card>
        </div>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps)(TradesListPage))
