import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from './user/reducer'
import account from './account/reducer'
import tradingSystem from './trading-system/reducer'
import advancedStat from './advanced-stats/reducer'
import trade from './trade/reducer'
import stat from './stat/reducer'
import filter from './filter/reducer'
import instrument from './instrument/reducer'
import tag from './tag/reducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    account,
    tradingSystem,
    advancedStat,
    trade,
    stat,
    filter,
    instrument,
    tag,
  })

export default createRootReducer
