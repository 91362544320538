import React from 'react'
import { Layout } from 'antd'
import moment from 'moment'
import styles from './style.module.scss'

import { APP_VERSION } from '../../../config/global'

const Footer = () => {
  return (
    <Layout.Footer className={styles.tr_footer}>
      <div className={styles.footer__copyrights}>
        Copyright © {moment().format('YYYY')} Tradiry | {APP_VERSION}
      </div>
      <div className={styles.footer__menu}>
        <ul>
          <li>
            <a href="https://tradiry.com/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
          </li>
          <li>
            <a
              href="https://tradiry.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://tradiry.com/faq" target="_blank" rel="noopener noreferrer">
              FAQ
            </a>
          </li>
          <li>
            <a
              href="https://tradiry.com/changelog"
              target="_blank"
              rel="noopener noreferrer"
            >
              Changelog
            </a>
          </li>
          <li>
            <a
              href="https://t.me/TradiryTradingJournal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.footer__version}>{APP_VERSION}</div>
    </Layout.Footer>
  )
}

export default Footer
