/* eslint jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { InputNumber, DatePicker } from 'antd'

const TradeEntry = ({ state, updateState = (f) => f }) => {
  return (
    <div className="trade-form__section">
      <div className="form-group">
        <label htmlFor="entryDate">Entry Date</label>
        <DatePicker
          defaultValue={state.entryDate}
          className="w-100"
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onOk={(value) => updateState('entryDate', value)}
          onChange={(date) => updateState('entryDate', date)}
          showNow={false}
        />
      </div>
      <div className="form-group">
        <label htmlFor="tradeQty">Quantity</label>
        <InputNumber
          defaultValue={state.tradeQty}
          className="w-100"
          // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => updateState('tradeQty', parseFloat(value))}
          min={0}
          max={999999999999999}
        />
      </div>
      <div className="form-group">
        <label htmlFor="entryPrice">Entry Price</label>
        <InputNumber
          defaultValue={state.entryPrice}
          className="w-100"
          onChange={(value) => updateState('entryPrice', parseFloat(value))}
          decimalSeparator="."
          min={0}
          max={999999999999999}
        />
      </div>
      <div className="form-group">
        <label htmlFor="takeProfit">Take Profit (optional)</label>
        <InputNumber
          defaultValue={state.takeProfit}
          className="w-100"
          onChange={(value) => updateState('takeProfit', parseFloat(value))}
          decimalSeparator="."
          min={0}
          max={999999999999999}
        />
      </div>
      <div className="form-group">
        <label htmlFor="stopLoss">Stop Loss (optional)</label>
        <InputNumber
          defaultValue={state.stopLoss}
          className="w-100"
          onChange={(value) => updateState('stopLoss', parseFloat(value))}
          decimalSeparator="."
          min={0}
          max={999999999999999}
        />
      </div>
    </div>
  )
}

export default TradeEntry
