import React from 'react'

import longNumberToShort from '../../../helpers/longNumberToShort'

const AccountStats = ({ account, stats, user }) => {
  return (
    <>
      <div className="pt-0 mb-5">
        <table className="table table__v2 table__xs">
          <tbody>
            <tr>
              <td className="muted-color fw-300">Balance</td>
              <td>
                {parseFloat(account.balance).toFixed(2)} {user.get_currency.short_name}
              </td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Trades</td>
              <td>{stats.totalTrades}</td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Total Performance</td>
              <td className={stats.totalReturn >= 0.0 ? 'success-color' : 'danger-color'}>
                {longNumberToShort(stats.totalReturn, '0.00a')}{' '}
                {user.get_currency.short_name}{' '}
                {`(${parseFloat(stats.totalReturnPercents).toFixed(1)}%)`}
              </td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Winners</td>
              <td>
                {stats.winTrades}{' '}
                <span
                  className={stats.winReturn > 0.0 ? 'success-color' : 'default-color'}
                >
                  {`(+${longNumberToShort(stats.winReturn, '0.00a')} ${
                    user.get_currency.short_name
                  })`}
                </span>
              </td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Losers</td>
              <td>
                {stats.lossTrades}{' '}
                <span
                  className={stats.lossReturn < 0.0 ? 'success-color' : 'default-color'}
                >
                  {`(${longNumberToShort(stats.lossReturn, '0.00a')} ${
                    user.get_currency.short_name
                  })`}
                </span>
              </td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Profit Factor</td>
              <td>{parseFloat(stats.profitFactor).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Avg. Return Per Trade</td>
              <td>
                {longNumberToShort(stats.avgReturnPerTrade, '0.00a')}{' '}
                {user.get_currency.short_name}
              </td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Avg. Win Per Trade</td>
              <td>
                {longNumberToShort(stats.avgWinPerTrade, '0.00a')}{' '}
                {user.get_currency.short_name}
              </td>
            </tr>
            <tr>
              <td className="muted-color fw-300">Avg. Loss Per Trade</td>
              <td>
                {longNumberToShort(stats.avgLossPerTrade, '0.00a')}{' '}
                {user.get_currency.short_name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AccountStats
