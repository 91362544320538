/* eslint no-lonely-if:0 */
export default function createUrlParamsStringFromFilters(filters) {
  // console.log(Object.keys(filters))
  let query = ''
  Object.keys(filters).forEach((key) => {
    if (!['from', 'to', 'getBy', 'isEnabled', 'importId'].includes(key)) {
      // console.log(filters[key])
      filters[key].forEach((value) => {
        query += `&${key}[]=${value}`
      })
    } else {
      if (filters[key] !== '') {
        query += `&${key}=${filters[key]}`
      }
    }
  })
  return query
}
