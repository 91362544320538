import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import className from 'classnames'
import styles from './style.module.scss'

class PageNotFound extends React.Component {
  render() {
    return (
      <>
        <Helmet title="Page Not Found" />
        <div className="animate__animated animate__fadeIn">
          <div>
            <div className="container pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-32">
              <div className={className('font-weight-bold mb-3', styles.error_title)}>Page not found</div>
              <div className={className('text-gray-6 font-size-24', styles.error_message)}>
                This page is deprecated, deleted, or does not exist at all
              </div>
              <div className={className('font-weight-bold mb-3', styles.error_title, styles.error_title_x2)}>404 —</div>
              <Link className="btn btn-primary width-100" to="/dashboard">
                Go to the Dashboard
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PageNotFound
